import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import PropsRoute from "../components/utils/PropsRoute";
import PatientContainer from "../containers/PatientContainer";
import PatientDetailContainer from "../containers/PatientDetailContainer";
import NewPatientContainer from "../containers/NewPatientContainer";

class PatientsRoutes extends React.Component {
  render () {
    const {user} = this.props;
    return (
      <Switch>
        <PropsRoute path='/patients/new' user={user} exact component={NewPatientContainer}/>
        <PropsRoute path='/patients/:patientId' user={user} component={PatientDetailContainer}/>
        <PropsRoute path='/patients' user={user} component={PatientContainer}/>
      </Switch>
    )
  }
}

export default withRouter(PatientsRoutes);