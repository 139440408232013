import React from 'react';
import {ApiService,ENDPOINTS} from "../../services/ApiService";
import { toastBanner } from "../../services/utilityService";
import Modal from '../boxes/Modal';
import AddDiagnosesForm from "../forms/AddDiagnosesForm";
import LoadingScreen from "../LoadingScreen";
import FilterableSelect from "../inputs/FilterableSelect";

const DiagnosisModal = (props) => (
  <Modal title="Add Diagnoses" onClose={props.onClose}>
    <React.Fragment>
      <AddDiagnosisComponent {...props}/>
    </React.Fragment>
  </Modal>
);

class AddDiagnosisComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading:false,
      diagnoses: [],
      diagnosesCategories: [],
      selectedDiagnoses:[],
      mode: AddDiagnosisComponent.MODES.search
    }
  }

  componentDidMount(){
    this.setLoading(true);
    this.getDiagnoses();
    this.getDiagnosesCategories();
  }

  getDiagnoses = () => {
    this.service.get(ENDPOINTS.utilities.diagnoses)
      .then(response => {
        this.setState({
          isLoading: false,
          diagnoses: response.data
        })
      })
      .catch((error) => {
        toastBanner(error.message);
      });
  }

  getDiagnosesCategories = () => {
    this.service.get(ENDPOINTS.utilities.diagnosesCategories)
      .then((response) => {
        this.setState({
          diagnosesCategories: response.data
        });
      })
      .catch((error) => {
        toastBanner(error.message);
      });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );

  onDiagnosesCreated = (diagnosis) => {
    this.setState({
      diagnoses: [...this.state.diagnoses, diagnosis],
      selectedDiagnoses: [...this.state.selectedDiagnoses, diagnosis],
      mode: AddDiagnosisComponent.MODES.search
    })
  }

  removeDiagnosis = (id) => {
    const {selectedDiagnoses} = this.state;
    const index = selectedDiagnoses.findIndex(a => a.id === id);
    selectedDiagnoses.splice(index,1);
    this.setState({
      selectedDiagnoses
    })
  };

  setMode = (mode) => {
    this.setState({
      mode
    });
  }

  onSaveDiagnoses = () => {
    const { onDiagnosesAdded, encounterId } = this.props;

    this.setLoading(true);

    const requestData = this.state.selectedDiagnoses.map(diagnosis => ({id: diagnosis.id}));

    this.service.post(ENDPOINTS.encounters.diagnoses(encounterId), {diagnoses: requestData})
    .then(() => {
      this.setState({
        isLoading: false,
        diagnoses: []
      }, () => {
        if(onDiagnosesAdded){
          onDiagnosesAdded(this.state.selectedDiagnoses);
        }
      })
    })
    .catch((error) => {
      toastBanner(error.message);
    });
  };

  render(){
    const {isLoading, selectedDiagnoses, diagnoses, diagnosesCategories, mode} = this.state;

    if(isLoading) return this.renderLoading();

    return (
      <div style={{display:"flex",flexDirection:"column"}}>
        {
          mode === AddDiagnosisComponent.MODES.add ?
            (
              <AddDiagnosesForm
                userToken={this.props.userToken}
                diagnosesCategories={diagnosesCategories}
                onDiagnosesCreated={this.onDiagnosesCreated}
                onClose={() => this.setMode(AddDiagnosisComponent.MODES.search)}
              />
            )
            :
            <React.Fragment>
              <p className="dashboard-card__body-text" style={{marginBottom: "1rem"}}>
                Select one or more diagnoses from the suggestions to add to the encounter
              </p>

              <div style={{display: "flex", flexDirection:"column", justifyContent:"center"}}>
                <FilterableSelect
                  items={diagnoses}
                  className="search-section filterable-select"
                  filterBy={['name']}
                  placeholder="&#xf002; Enter Diagnosis name..."
                  clearOnSelect
                  input={{
                    className: "dashboard-form__input",
                    value: '',
                    onChange: (selectedItem) => {
                      this.onDiagnosesCreated(selectedItem);
                    }
                  }}/>
                <p style={{margin:"1rem", fontSize:"1.5rem"}}>OR</p>
                <button className="dashboard__secondary-btn" onClick={() => this.setMode(AddDiagnosisComponent.MODES.add)}>
                  Add New Diagnoses
                </button>

                {selectedDiagnoses.length > 0 &&
                  <p className="dashboard-card__body-text" style={{marginTop: "2rem"}}>Added Diagnoses: </p>
                }
                <div style={{display:"flex"}}>
                  {selectedDiagnoses.map(diagnosis => (
                    <div key={diagnosis.id} className="overview-item__tag">
                      <span className="overview-item__small-text">{diagnosis.name}</span>
                      {`    `}
                      <span
                        className="overview-item__small-text"
                        style={{cursor:"pointer", margin:"0.5rem"}}
                        onClick={() => {
                          this.removeDiagnosis(diagnosis.id);
                        }}>x</span>
                    </div>
                  ))}
                </div>

                <button
                  className="dashboard__primary-btn dashboard-form__btn"
                  type="submit" style={{marginTop:"3rem"}}
                  onClick={this.onSaveDiagnoses}
                  disabled={selectedDiagnoses.length === 0}>
                  Add {selectedDiagnoses.length} {selectedDiagnoses.length > 1 ? 'Diagnoses' : 'Diagnosis'}
                </button>
              </div>
            </React.Fragment>
        }
      </div>
    )
  }
}

AddDiagnosisComponent.MODES = {
  search: "search",
  add: "add"
};

export default DiagnosisModal;