import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import { Link } from 'react-router-dom'
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import { toastBanner, INVOICE_STATUS, PERMISSIONS, STAGEDATA } from "../../services/utilityService";
import LoadingScreen from "../LoadingScreen";
import Modal from "../boxes/Modal";
import {withUser} from "../utils/AuthHOC";
import RestrictedComponent from "../utils/RestrictedComponent";

const StageDetailsModal = ({title, stage, onClose, user, onStageUpdated}) => (
  <Modal title="Patient Info" onClose={onClose}>
    <StageDetailsComponent stage={stage} user={user} onStageUpdated={onStageUpdated}/>
  </Modal>
);

class StageDetailsComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      patientDetails:null
    }
  }

  componentDidMount(){
    const { stage: { patient } } = this.props;
    if(patient.has_patient_profile){
      this.setLoading(true);
      this.service.get(`${ENDPOINTS.patients.base}/${patient.id}`)
        .then(response => {
          this.setState({
            isLoading:false,
            patientDetails:response.data
          })
        })
        .catch((error) => {
          toastBanner(error.message || "An error occured!");
        });
    }
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  updateStage = (redirectUrl, showNextStageModal) => {
    const { stage: { encounter, current_stage_id }, onStageUpdated } = this.props;
    this.setLoading(true);
    const updateData = {
      current_stage_id: current_stage_id === STAGEDATA.stages.yetToArrive ? STAGEDATA.stages.frontDesk : current_stage_id,
      encounter_id: encounter.id,
      status_id: STAGEDATA.status.active
    };

    const stage = Immutable.asMutable(this.props.stage);

    this.service.post(ENDPOINTS.stages.base, updateData)
      .then(() => {
        stage.current_stage_id = updateData.current_stage_id;
        stage.status_id = updateData.status_id;
        onStageUpdated({
          stage,
          redirectUrl,
          showNextStageModal
        });
      })
  };

  render(){
    const { stage: {
      patient,
      invoices,
      encounter,
      appointment_time,
      current_stage_id,
      care_category,
      status,
      created_by }, user} = this.props;

    const { isLoading, patientDetails } = this.state;

    const noPayment = invoices.filter(invoice => Number(invoice.payment_status) === INVOICE_STATUS.noPayment);

    return (
      <div className="stage-details-modal">
        <h2 className="dashboard-card__title-text">
          {patient.name}
          {patient.has_patient_profile &&
            <Link to={`/patients/${patient.id}`} className="dashboard__secondary-btn-link no-print">
              <i className="fa fa-external-link-alt"/>&nbsp;View Patient
            </Link>
          }
        </h2>
        {
          noPayment.length > 0 &&
            <button className="dashboard__primary-btn-red pending-payment__badge">Pending Payment</button>
        }
        {
          !patient.has_patient_profile &&
          <p className="dashboard-card__body-text">{patient.phone_number}</p>
        }
        {/*<p className="dashboard-card__body-text">{STAGEDATA.getAppointmentType(appointmentType)}</p>*/}
        <p className="dashboard-card__body-text">{moment(appointment_time).format('dddd, MMMM Do YYYY, h:mm a')}</p>
        {patient.name !== created_by && <p className="dashboard-card__body-text">Booked by: {created_by}</p>}

        <p className="dashboard-card__body-text">Appointment Medium: {encounter && encounter.appointment_medium ? encounter.appointment_medium : "N/A"}</p>
        <p className="dashboard-card__body-text">Care Category: {care_category && care_category.name ? care_category.name : "N/A"}</p>
        <p className="dashboard-card__body-text">Reason for Booking the Appointment: {encounter && encounter.reason ? encounter.reason : "N/A"}</p>

        {isLoading ? (<div className="stage__details" style={{position:"relative", height:"10rem"}}><LoadingScreen /></div>) : (
          <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            {patient.has_patient_profile ? (
              <React.Fragment>
                <PatientDetails details={patientDetails}/>

                <ButtonLayout
                  updateStage={this.updateStage}
                  currentStageId={current_stage_id}
                  encounterId={encounter.id}
                  patient={patient}
                  status={status}
                />
              </React.Fragment>
            ) : (
              <div className="details__contact-details">
                <RestrictedComponent
                  condition={user.permissions.indexOf(PERMISSIONS.patient.create_patient) > -1}>
                  <Link to={`/patients/new?p_id=${patient.id}`}>
                    <button className="dashboard__secondary-btn">Register Patient</button>
                  </Link>
                </RestrictedComponent>
              </div>
            )}
          </div>
        )}

        <RestrictedComponent
          condition={user.permissions.indexOf(PERMISSIONS.encounter.update_encounter) > -1}>
          <div className="patient-card__app-btn-wrap">
            <p className="dashboard-card__title-text">Appointment</p>
            <div>
              <button className="dashboard__secondary-btn">
                <Link to={`/appointments?p_id=${patient.id}&e_id=${encounter.id}&d=${appointment_time}`}>
                  Reschedule
                </Link>
              </button>
            </div>
          </div>
        </RestrictedComponent>
      </div>
    )
  }
}

const PatientDetails = ({details}) => (
  details ? (
    <div className="details__contact-details">
      <span className="profile-item-wrapper">
        <i className="fas fa-id-badge"/>
        <p className="profile-item__text">
          {details.rfc_id}
        </p>
      </span>
      <span className="profile-item-wrapper">
        <i className="fas fa-phone"/>
        <p className="profile-item__text">
          {details.phone_number}
        </p>
      </span>
        <span className="profile-item-wrapper">
        <i className="fas fa-home"/>
        <p className="profile-item__text">
          {details.address}
        </p>
      </span>
        <span className="profile-item-wrapper">
        <i className="fas fa-envelope"/>
        <span className="lowercase">
        <p className="profile-item__text">
          {details.email_address}
        </p>
        </span>
      </span>
    </div>
  ) : (
    null
  )
);

const ButtonLayout = withUser(({user, currentStageId, patient, encounterId, status, updateStage}) => (
  <div className="patient-card__btn-wrap">
    {(currentStageId !== STAGEDATA.stages.yetToArrive) &&
      <button
        className="dashboard__secondary-btn"
        onClick={() => updateStage(null, true)}
        style={{width:"100%", marginBottom:"1rem"}}>
        Update Stage
      </button>
    }
    {(currentStageId === STAGEDATA.stages.yetToArrive) &&
      <button
        className="dashboard__secondary-btn"
        disabled={!(currentStageId === STAGEDATA.stages.yetToArrive)}
        onClick={() => updateStage(null, true)}
        style={{width:"100%", marginBottom:"1rem"}}>
        Check-in
      </button>
    }
    {!hideButton(STAGEDATA.stages.triage, currentStageId, status) &&
    <RestrictedComponent
      displayErrorMessage={false}
      condition={user.permissions.indexOf(PERMISSIONS.encounter.encounter_vital.create_encounter_vital) > -1}>

      <button
        className="dashboard__secondary-btn"
        disabled={hideButton(STAGEDATA.stages.triage, currentStageId, status)}
        onClick={() => updateStage(`/patients/${patient.id}/vitals/new?e_id=${encounterId}`, false)}
        style={{width: "100%", marginBottom:"1rem"}}>
        Record Vitals
      </button>

    </RestrictedComponent>
    }
    {!hideButton(STAGEDATA.stages.physician, currentStageId, status) &&
    <RestrictedComponent
      displayErrorMessage={false}
      condition={user.permissions.indexOf(PERMISSIONS.encounter.encounter_note.create_encounter_note) > -1}>
      <button
        className="dashboard__secondary-btn"
        disabled={hideButton(STAGEDATA.stages.physician, currentStageId, status)}
        onClick={() => updateStage(`/patients/${patient.id}/encounter-notes/new?e_id=${encounterId}`, false)}
        style={{width:"100%", marginBottom:"1rem"}}>
        Start Consultation
      </button>
    </RestrictedComponent>
    }
    {!hideButton(STAGEDATA.stages.pharmacist, currentStageId, status) &&
      <EncounterButton
        type={ENCOUNTER_BUTTON_TYPES.prescription}
        onClick={() => updateStage(`/patients/${patient.id}/medical`, false)}
        encounterId={encounterId}/>
    }
    <RestrictedComponent
      displayErrorMessage={false}
      condition={user.permissions.indexOf(PERMISSIONS.payment.create_payment) > -1}>
      <EncounterButton
        type={ENCOUNTER_BUTTON_TYPES.payment}
        encounterId={encounterId}
        />
    </RestrictedComponent>
  </div>
));

const hideButton = (validStageId, currentStageId, currentStatus) => {
  return !(currentStageId === validStageId && (Number(currentStatus) === STAGEDATA.status.waiting || Number(currentStatus) === STAGEDATA.status.active) )
};

const ENCOUNTER_BUTTON_TYPES = {
  prescription: "prescription",
  payment: "payment"
};

class EncounterButton extends React.Component {
  constructor(props){
    super(props);
    const { user: {credentials: {accessToken}} } = props;
    this.service = new ApiService(accessToken);
    this.state = {
      isLoading:false,
      encounter:null,
      isEnabled:false
    };
  }

  componentDidMount(){
    const { encounterId, type, user } = this.props;
    this.service.get(ENDPOINTS.encounters.encounter(encounterId))
      .then(response => {
        let isEnabled = false;
        const encounter = response.data;
        if(type === ENCOUNTER_BUTTON_TYPES.prescription){
          isEnabled = (encounter.prescriptions.filter(p => Number(p.status_id) === 1).length > 0)
                      && (user.permissions.indexOf(PERMISSIONS.prescription.create_prescription) > -1);
        }else if(type === ENCOUNTER_BUTTON_TYPES.payment){
          isEnabled = (encounter.invoices.filter(i => (Number(i.status) !== INVOICE_STATUS.fullPayment)).length > 0)
                      && (user.permissions.indexOf(PERMISSIONS.payment.create_payment) > -1);
        }
        this.setState({
          encounter,
          isEnabled
        })
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  }

  onClick = () => {
    const { onClick, type, history } = this.props;
    const { encounter } = this.state;
    if(onClick) {
      onClick();
    }else{
      history.push(`/patients/${encounter.patient.id}/${type === ENCOUNTER_BUTTON_TYPES.prescription ? "medical" : "invoices"}`);
    }

  };

  componentWillUnmount(){
    this.service.cancel();
  }

  render(){
    const { type } = this.props;
    const { encounter, isEnabled, isLoading } = this.state;

    return (
      (isLoading || !encounter) ? (
        <button className="dashboard__secondary-btn" style={{width:"100%", marginBottom:"1rem",position:"relative", height:"5rem"}}>
          <LoadingScreen/>
        </button>
      ) : (
        <button
          onClick={this.onClick}
          className="dashboard__secondary-btn"
          style={{width:"100%", marginBottom:"1rem"}} disabled={!isEnabled}>
          {type === ENCOUNTER_BUTTON_TYPES.prescription ? "Dispense Prescription" : "Add Payment"}
          {/* <span style={{backgroundColor: "#ab0404", color: 'white', marginLeft: "2rem", padding: "0.5rem"}}>{invoices.length}</span> */}
        </button>
      )
    )
  }

}

EncounterButton = withRouter(withUser(EncounterButton));

export default withUser(StageDetailsModal);
