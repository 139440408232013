import React from 'react';
import { connect } from 'react-redux';
import { arrayPush } from 'redux-form';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import LoadingScreen from '../LoadingScreen';
import Modal from '../boxes/Modal';
import { toastBanner, TARIFF_TYPES } from "../../services/utilityService";
import CreateTestListForm from "../forms/CreateTestListForm";

const TestsRecommendationsModal = (props) => (
  <Modal title="Add Test(s)" onClose={props.onClose}>
    <React.Fragment>
      <TestsRecommendationComponent {...props} />
    </React.Fragment>
  </Modal>
);

class TestsRecommendationComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading:false,
      testList:[],
      policyId: null,
      data:null
      // prescription:{
      //   create_invoice:true,
      //   medications:[],
      //   comment:""
      // }
    }
  }

  componentDidMount(){
    const { patient } = this.props; 

    if(patient.policies.length === 0){
      this.setPolicyId("1");
    } 
  }

  getTestsList = (policyId) => {
    const { dispatch } = this.props; 

    this.setLoading(true);
    this.service.get(ENDPOINTS.utilities.tariffs, {policy_id: policyId, type_id: TARIFF_TYPES.diagnostics})
      .then(response => {
        this.setState({
          isLoading: false,
          testList: response.data
        }, () => dispatch(arrayPush('createTestListForm', 'tests', {quantity:1})))
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  };

  setPolicyId = (policyId) => {
    this.setState({
      policyId
    }, () => {
      this.getTestsList(policyId);
    })
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );

  onSaveTests = (values) => {
    const {encounterId, onTestsAdded, onSaveDiagnosticsAdded} = this.props;

    const data = {
      comment: values.comment || "",
      create_invoice: values.create_invoice || false,
      price: values.tests.reduce((a,c) =>  {
        return a + Number(c.test.price)
      }, 0),
      tests: values.tests.map(t => ({
        id: t.test.id,
        name: t.test.name,
        price: t.test.price,
        quantity: 1,
      }))
    };

    this.setLoading(true);

    if(onSaveDiagnosticsAdded) {
      onSaveDiagnosticsAdded(data["tests"])
    }

    this.service.post(ENDPOINTS.encounters.tests(encounterId), data)
      .then(response => {
        if(onTestsAdded)
          onTestsAdded({...data, id: response.data.test_id});
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  };

  render(){
    const {patient, onSaveDiagnosticsAdded, onTestsAdded } = this.props;
    const {policyId, testList, isLoading} = this.state;

    if(!patient || isLoading) return this.renderLoading();

    return (
      <div className="dashboard-form">
        {policyId ? (
          <CreateTestListForm
            tests={testList}
            onSubmit={this.onSaveTests}
            onTestsAdded={onTestsAdded}
            onSaveDiagnosticsAdded={onSaveDiagnosticsAdded}/>
        ) : (
          <div className="dashboard-form-item-wrapper">
            <div className="dashboard-input-wrap profile-input-wrap">
              <span className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  Select Patient Policy
                </label>
                <select
                  name="create_invoice"
                  className="dashboard-form__input dashboard-form__select"
                  onChange={(e) => this.setPolicyId(e.target.value)}>
                  <option>Select Policy</option>
                  <React.Fragment>
                    <option value="1">Walk In</option>
                    {patient.policies
                      .map(policy => (
                      <option key={policy.policy_id} value={policy.policy_id}>{policy.policy}</option>
                    ))}
                  </React.Fragment>
                </select>
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }
}

TestsRecommendationComponent = connect()(TestsRecommendationComponent);

export default TestsRecommendationsModal;