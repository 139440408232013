import React from 'react';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import queryString from 'querystring';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {ApiService, ENDPOINTS} from '../services/ApiService';
import MainContent from '../components/boxes/MainContent';
import PageBody from "../components/boxes/PageBody";
import LoadingScreen from "../components/LoadingScreen";
import PageHeader from "../components/boxes/PageHeader";
import AppointmentModal from "../components/modals/AppointmentModal";
import { toastBanner, APPOINTMENT_TYPES, PERMISSIONS, STAGEDATA} from "../services/utilityService";
import RestrictedComponent from "../components/utils/RestrictedComponent";

const localizer = BigCalendar.setLocalizer(
  BigCalendar.momentLocalizer(moment)
);

class AppointmentsContainer extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      appointments:[],
      showBookAppointmentModal:false,
      selectedDate: moment(),
      selectedPatient: null,
      selectedPatientId: null,
      selectedAppointment: null,
      filter: ''
    }
  }

  componentDidMount() {
    const { location, patient } = this.props;
    const queryData = queryString.parse(location.search.substr(1));
    const pId = queryData.p_id; //get patient id from url,i.e  for booking
    const type = Number(queryData.t) || APPOINTMENT_TYPES.appointment; //get appointment type if available

    this.setState({
      type,
      isFollowUp: queryData.f === "true"
    });

    const appointment = queryData.e_id ? {
      id: queryData.e_id, //get encounter_id if available, i.e for rescheduling from overview
      date: queryData.d || moment(), //get appointment date if available, i.e for rescheduling from overview
    } : null;

    // if a patient is passed into the props, then filter by patient id i.e to view appointments for the patient
    this.getAppointments(patient ? patient.id : null);

    if(pId){
      //get patient details
      this.service.get(ENDPOINTS.patients.patient(pId))
        .then(response => {
          this.setState({
            selectedPatient: response.data
          }, () => {
            if(type === APPOINTMENT_TYPES.emergency){
              //show appointment modal with current date
              this.toggleBookAppointmentModal(moment());
            }else if(appointment){
              // show appointment modal for follow-up / rescheduling of appointment
              this.toggleBookAppointmentModal(appointment.date, {
                patient: this.state.selectedPatient,
                ...appointment
              });
            }
          })
        })
        .catch((error) => {
          toastBanner(error.message || "An error occured!");
        });
    }
  }

  toggleFilter = (event) => {
    this.setState({
      filter: event.target.value
    }, () => this.getAppointments())
  } 

  getAppointments = (patientId, from=moment().startOf('month').subtract(3,'months').format("YYYY-MM-DD"), to=moment().endOf('month').add(3,'months').format("YYYY-MM-DD")) => {
    this.setLoading(true);
    const requestData = {
      from,
      to,
      status: this.state.filter
    };
    
    if(patientId){
      //if patient id is passed, filter appointments by patient id
      requestData.patient_id = patientId;
    }
    
    this.service.get(ENDPOINTS.appointments.base, requestData)
      .then(response => {
        this.setState({
          isLoading: false,
          appointments: response.data
        })
      })
      .catch(error => {
        this.setLoading(false);
        toastBanner(error.message || "An error occured!");
      });
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => {
    return (
      <MainContent>
        <PageHeader title="Appointments"/>
        <PageBody>
          <LoadingScreen />
        </PageBody>
      </MainContent>
    )
  };

  toggleBookAppointmentModal = (date, appointment) => {
    this.setState({
      showBookAppointmentModal: !this.state.showBookAppointmentModal,
      selectedDate: date,
      selectedAppointment: appointment,
      selectedPatientId: appointment ? appointment.patient.id : null
    })
  };

  onAppointmentBooked = (appointment) => {
    //this.getAppointments(this.state.selectedPatient.id);

    //navigate to patients invoices, so front desk can log payment
    const { history } = this.props;
    const { selectedPatient } = this.state;
    if(appointment.payment_required){
      history.push(`/patients/${selectedPatient.id}/invoices`);
    }else{
      this.setState({
        showBookAppointmentModal: false,
        selectedAppointment: null,
        appointments:[],
        selectedDate: moment()
      }, () => {
        this.getAppointments();
        // this.toggleBookAppointmentModal();
      })
    }
  };

  cancelBooking = () => {
    this.setState({
      selectedPatient: null,
      showBookAppointmentModal: false,
      selectedDate: moment()
    }, () => {
      this.getAppointments();
      // this.toggleBookAppointmentModal();
    })
  };


  render(){
    const {
      selectedPatient, selectedPatientId, selectedDate, selectedAppointment, isFollowUp,
      isLoading, appointments, showBookAppointmentModal, type, filter } = this.state;

    const { patient, user } = this.props;

    if(isLoading) return this.renderLoading();

    return (
      <MainContent>
        {!patient &&
          <PageHeader
            title="Appointments"
            showButton={!!selectedPatient && user.permissions.indexOf(PERMISSIONS.encounter.create_encounter) > -1}
            onButtonClick={this.toggleBookAppointmentModal}
            buttonTitle="Book Appointment"/>
        }

        <div className="dashboard-card appointments-header-layout">
          <select
            className="dashboard__secondary-btn dashboard-form__select"
            onChange={this.toggleFilter}
            value={filter}>
            <option value="">All Appointments</option>
            <option value="active">Active Appointments</option>
            <option value="pending">Pending Appointments</option>
          </select>

          <div className="legend-wrapper">
            <div className="legend">
              <i className="fa fa-circle" style={{color:"#094063"}}/>
              <p>Doctor Appointments</p>
            </div>
            <div className="legend">
              <i className="fa fa-circle" style={{color:"#87ceeb"}}/>
              <p>Nurse Appointments</p>
            </div>
            <div className="legend">
              <i className="fa fa-circle" style={{color:"#e8e813"}}/>
              <p>Specialist Appointments</p>
            </div>
          </div>
        </div>

        {showBookAppointmentModal &&
          <AppointmentModal
            onClose={this.toggleBookAppointmentModal}
            patientId={selectedPatientId || selectedPatient.id}
            selectedDate={selectedDate}
            type={type}
            isFollowUp={isFollowUp}
            mode = { (selectedAppointment === undefined) ? AppointmentModal.MODES.create:
              (selectedAppointment && !isFollowUp) ? AppointmentModal.MODES.view :
              AppointmentModal.MODES.edit
              }
            selectedAppointment={selectedAppointment}
            userToken={this.props.user.credentials.accessToken}
            onAppointmentBooked={this.onAppointmentBooked}
           />
        }

        {selectedPatient &&
          <div className="page-info-banner appointments-banner">
            <p>{`Book ${ isFollowUp ? "follow-up" : APPOINTMENT_TYPES.getAppointmentType(type)} appointment for ${selectedPatient.first_name ? `${selectedPatient.first_name} ${selectedPatient.last_name}` : selectedPatient.name} `}</p>
            <span onClick={this.cancelBooking}><i className="fa fa-times"/></span>
          </div>
        }
        <div style={{display:"flex",flexDirection:"row", height:"100%"}}>
          <RestrictedComponent
            displayErrorMessage={true}
            errorComponent={() => (
              <div className="dashboard-card">
                <div style={{position:"relative", height:"10rem"}}>
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                </div>
              </div>
            )}
            condition={user.permissions.indexOf(PERMISSIONS.encounter.read_encounter) > -1}>

            <AppointmentsView
              filter={filter}
              appointments={appointments}
              onBookAppointment={(date) => {
                if(selectedPatient){
                  this.toggleBookAppointmentModal(date);
                }
              }}
              onAppointmentClicked={(date, event) => {
                if(event.current_stage_id === STAGEDATA.stages.yetToArrive || event.current_stage_id === null){
                 this.toggleBookAppointmentModal(date, event);
                } else {
                  this.props.history.push(`/encounters/${event.id}`)
                }
              }}
              
            />
          </RestrictedComponent>
        </div>
      </MainContent>
    )
  }
}

const AppointmentsView = ({appointments, onBookAppointment, onAppointmentClicked, filter}) => {
  const events = mapAppointmentsToEvents(appointments, filter);
  return (
    <div style={{padding:"1rem", width:"100%"}}>
      <BigCalendar
        events={events}
        defaultView={'week'}
        views={['month','week','day']}
        defaultDate={new Date()}
        localizer={localizer}
        selectable
        min={moment().hours(8).minutes(0).toDate()}
        max={moment().hours(20).minutes(0).toDate()}
        components={{
          eventWrapper: EventWrapper
        }}
        onSelectSlot={(slot) => {
          onBookAppointment(slot.start);
        }}
        onSelectEvent={(event) => {
          onAppointmentClicked(event.start, event)
        }}
      />
    </div>
  )
};

const EventWrapper = ({children, event: {active_status_id, care_category}}) => {
  return (
    <div className={`
        calender-event-wrapper ${active_status_id === 2 ? 'pending': ''} ${care_category ? care_category.staff_type.toLowerCase() : 'doctor'}
      `}>
      {children}
    </div>
  )
};

const mapAppointmentsToEvents = (appointmentList, filter) => {
  return appointmentList.map((appointment) => ({
    title: appointment.patient.name,
    start: new Date(appointment.start_time),
    end: new Date(appointment.end_time),
    allDay: false,
    filter,
    ...appointment
  }));
};

export default withRouter(AppointmentsContainer);