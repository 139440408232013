import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import { ApiService, ENDPOINTS } from "../../services/ApiService";
import LoadingScreen from "../LoadingScreen";
import RestrictedComponent from "../utils/RestrictedComponent";
import { toastBanner, PERMISSIONS} from "../../services/utilityService";
import MedicalHistoryComponent from "../MedicalHistoryComponent";

class MedicalsInfo extends Component {
  constructor(props){
    super(props);

    this.ApiService = new ApiService(props.user.credentials.accessToken);

    this.state ={
      medicalHistory: [],
      allergies: [],
      isLoading:{
        loadingPrescriptions:false,
        loadingAllergies:false
      },
      prescriptions: []
    }
  }

  componentDidMount() {
    this.setLoading({
      loadingPrescriptions:true,
      loadingAllergies:true
    });
    this.getAllergies();
    this.getPrescriptions();
  }

  getAllergies = () => {
    const { match: { params } } = this.props;

    this.ApiService.get(ENDPOINTS.patients.allergies(params.patientId)).then((response) => {
      this.setState({
        allergies: response.data
      }, () => {
        this.setLoading(Object.assign({},this.state.isLoading, {loadingAllergies:false}));
      })
    })
    .catch((error) => {
      toastBanner(error.message || "An error occured!");
    });
  }

  getPrescriptions = () => {
    const { match: { params } } = this.props;

    this.ApiService.get(ENDPOINTS.patients.prescriptions(params.patientId)).then((response) => {
      this.setState({
        prescriptions: response.data
      }, () => {
        this.setLoading(Object.assign({},this.state.isLoading, {loadingPrescriptions:false}));
      })
    })
    .catch((error) => {
      toastBanner(error.message || "An error occured!");
    });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );

  onDispensePrescription = (encounterId, prescriptionId) => {
    const { prescriptions } = this.state;
    const { history } = this.props;
    this.setLoading(Object.assign({},this.state.isLoading, {loadingPrescriptions:true}));
    this.ApiService.put(ENDPOINTS.encounters.prescriptions.prescription(encounterId, prescriptionId), {status_id:2})
      .then(response => {
        const pIndex = prescriptions.findIndex(p => p.id === response.data.prescription_id);
        prescriptions[pIndex].status_id = 2;
        this.setState({
          prescriptions
        }, () => {
          this.setLoading(Object.assign({},this.state.isLoading, {loadingPrescriptions:false}))
          history.push(`/overview?e_id=${encounterId}`);
        })
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  };

  render() {
    const { allergies, prescriptions, isLoading: {loadingPrescriptions, loadingAllergies} } = this.state;
    const { patient } = this.props;

    return (
        <div className="patient-profile" style={{}}>
          <div className="dashboard-card" style={{marginBottom: "2rem"}}>
            <MedicalHistoryComponent
              user={this.props.user}
              patient={patient}
              showTitle
            />
          </div>

          <div className="dashboard-card" style={{marginBottom: "2rem"}}>
            <h2 className="dashboard-card__title-text">Allergies</h2>
            <RestrictedComponent
              displayErrorMessage={true}
              errorComponent={() => (
                <div className="dashboard-card">
                  <div style={{position:"relative", height:"10rem"}}>
                    <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                  </div>
                </div>
              )}
              condition={this.props.user.permissions.indexOf(PERMISSIONS.patient.patient_allergy.read_patient_allergy) > -1}>
              {loadingAllergies ? (
                this.renderLoading()
              ) : (
                <React.Fragment>
                  {allergies.length === 0 && (<div style={{fontSize: "2rem", color: "grey"}}>No Allergies</div>)}
                  {allergies.length > 0 &&
                  <div className="dashboard-table-overflow">
                    <table className="dashboard-table">
                      <thead>
                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">Allergen</th>
                        <th className="dashboard-table-item dashboard-table-item__header">Reaction</th>
                        <th className="dashboard-table-item dashboard-table-item__header">Date Created</th>
                      </tr>
                      </thead>
                      <tbody>
                      {allergies.map((allergy, index)=>(
                        <tr key={`${allergy.id}-${index}`} >
                          <td className="dashboard-table-item dashboard-table-item__body">{allergy.name || allergy.allergy.name}</td>
                          <td className="dashboard-table-item dashboard-table-item__body">{allergy.reaction || allergy.allergy.reaction}</td>
                          <td className="dashboard-table-item dashboard-table-item__body">{moment(allergy.created_at || allergy.allergy.created_at).format('MMMM Do YYYY')}</td>
                        </tr>
                      ))
                      }
                      </tbody>
                    </table>
                  </div>
                  }
                </React.Fragment>
              )}
            </RestrictedComponent>
          </div>

          <div className="dashboard-card" style={{marginBottom: "2rem"}}>
            <h2 className="dashboard-card__title-text">Prescriptions</h2>
            <RestrictedComponent
              displayErrorMessage={true}
              errorComponent={() => (
                <div className="dashboard-card">
                  <div style={{position:"relative", height:"10rem"}}>
                    <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                  </div>
                </div>
              )}
              condition={this.props.user.permissions.indexOf(PERMISSIONS.prescription.read_prescription) > -1}>
              {loadingPrescriptions ? (
                this.renderLoading()
              ) : (
                <React.Fragment>
                  {prescriptions.length === 0 && (<div style={{fontSize: "2rem", color: "grey"}}>No Prescriptions</div>)}
                  {prescriptions.length > 0 && (
                    <div className="dashboard-table-overflow">
                      <table className="dashboard-table">
                        <thead>
                        <tr>
                          <th className="dashboard-table-item dashboard-table-item__header">Date Created</th>
                          <th className="dashboard-table-item dashboard-table-item__header">Total Price</th>
                          <th className="dashboard-table-item dashboard-table-item__header">Medication(s)</th>
                          <th className="dashboard-table-item dashboard-table-item__header">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          prescriptions.map((prescription, index)=>(
                            <tr key={`${prescription.id}-${index}`} >
                              <td className="dashboard-table-item dashboard-table-item__body">{moment(prescription.created_at).format('MMMM Do YYYY')}</td>
                              <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter( "#,###.00", prescription.medications.reduce((total, cur) =>  total + Number(cur.total_price),0))}</td>
                              <td className="dashboard-table-item dashboard-table-item__body">{prescription.medications.map(m => `${m.name} x ${m.quantity || 1}`).join(", ")}</td>
                              <td className="dashboard-table-item dashboard-table-item__body">
                                {Number(prescription.status_id) === 1 &&
                                <button
                                  onClick={() => this.onDispensePrescription(prescription.encounter_id, prescription.id)}
                                  className="button_link">
                                  Dispense
                                </button>
                                }
                              </td>
                            </tr>
                          ))
                        }
                        </tbody>
                      </table>
                    </div>
                  )}
                </React.Fragment>
              )}
            </RestrictedComponent>
          </div>
        </div>
    )
  }
}

export default withRouter(MedicalsInfo);