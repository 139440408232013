import axios from "axios";
import querystring from "querystring";
import {
  BASE_URL_RELIANCE,
  getCookie,
  DOMAIN_COOKIE_NAME,
} from "./utilityService";

const BASE_URL = BASE_URL_RELIANCE;
const CancelToken = axios.CancelToken;

export const ENDPOINTS = {
  base: `${BASE_URL}`,
  validate: "/validate",
  signup: `/signup`,
  stages: {
    base: "/stages",
  },
  appointments: {
    base: "/appointments",
    available: "/appointments/available",
    edit: (id) => `/appointments/${id}`,
    mediums: "/appointments/mediums",
    careCategories: "/appointments/care-categories",
  },
  patients: {
    base: "/patients",
    medicalHistory: {
      base: (id) => `patients/${id}/medical-history`,
      revisions: (id) => `patients/${id}/medical-history/revisions`,
    },
    patient: (id) => `/patients/${id}`,
    vitals: (id) => `/patients/${id}/vitals`,
    prescriptions: (id) => `/patients/${id}/prescriptions`,
    allergies: (id) => `/patients/${id}/allergies`,
    relations: (id) => `/patients/${id}/relations`,
    notes: (id) => `/patients/${id}/notes`,
    results: (id) => `/patients/${id}/results`,
    file: (id) => `/utilities/files/test-result/${id}`,
    insurers: {
      base: (id) => `/patients/${id}/policies`,
      insurer: (id, iId) => `/patients/${id}/policies/${iId}`,
    },
    validateId: "/validate",
  },
  users: {
    base: "/users",
    user: (id) => `/users/${id}`,
  },
  encounters: {
    base: "/encounters",
    claims: (eId) => `/encounters/${eId}/claims`,
    vitals: (eId) => `/encounters/${eId}/vitals`,
    encounter: (eId) => `/encounters/${eId}`,
    patient: (eId) => `/encounters?patient_id=${eId}`,
    notes: {
      base: (eId) => `/encounters/${eId}/notes`,
      history: (eId) => `/encounters/${eId}/notes/revisions`,
    },
    comments: (eId) => `/encounters/${eId}/comments`,
    prescriptions: {
      base: (eId) => `/encounters/${eId}/prescriptions`,
      prescription: (eId, pId) => `/encounters/${eId}/prescriptions/${pId}`,
    },
    tests: (eId) => `/encounters/${eId}/tests`,
    results: (eId, tId) => `/encounters/${eId}/tests/${tId}/results`,
    paCodes: (eId) => `/encounters/${eId}/pacodes`,
    procedures: (eId) => `/encounters/${eId}/procedures`,
    services: (eId) => `/encounters/${eId}/services`,
    diagnoses: (eId) => `/encounters/${eId}/diagnoses`,
    invoices: (eId) => `/encounters/${eId}/invoices`,
  },
  claims: {
    base: "/claims",
    claim: (cId) => `/claims/${cId}`,
  },
  utilities: {
    policies: "/utilities/policies",
    diagnoses: "utilities/diagnoses",
    diagnosesCategories: "/utilities/diagnoses/categories",
    allergies: "/utilities/allergies",
    tariffs: "/utilities/tariffs",
    upload: "/utilities/files/upload",
  },
  invoices: {
    base: "/invoices",
    invoice: (id) => `/invoices/${id}`,
    invoiceItem: (id, itemId) => `/invoices/${id}/items/${itemId}`,
  },
  payments: {
    base: "/payments",
  },
  nps: {
    base: "/net-promoter-system",
    dispatch: "/net-promoter-system/create-manual-dispatch",
  },
  // teletherapy: {
  //   getAccessToken: "utilities/jwt",
  // },
};

export class ApiService {
  constructor(accessToken = getCookie(DOMAIN_COOKIE_NAME)) {
    this.ax = axios.create({
      baseURL: `${BASE_URL}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    this.data = {};
  }

  cancel = null;

  dummyQuery = (data, delay = 2000) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, delay);
    });
  };

  get = (url, data) => {
    return this.ax
      .get(`${url}?${querystring.stringify(data)}`, {
        cancelToken: new CancelToken((c) => {
          this.cancel = c;
        }),
        responseType: data && data.responseType ? data.responseType : undefined,
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw new ApiServiceException(e);
      });
  };

  post = (url, data) => {
    return this.ax
      .post(url, data)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw new ApiServiceException(e);
      });
  };

  put = (url, data) => {
    return this.ax
      .put(url, data)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw new ApiServiceException(e);
      });
  };

  patch = (url, data) => {
    return this.ax
      .patch(url, data)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw new ApiServiceException(e);
      });
  };

  delete = (url, data) => {
    return this.ax
      .delete(url, data)
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throw new ApiServiceException(e);
      });
  };
}

function ApiServiceException(e, _meta) {
  if (!e.response)
    e = {
      response: {
        status: 0,
        statusText: "Unknown Error",
        data: { message: "An Error Occurred" },
      },
    };
  this.code = e.response.status;
  this.message = e.response.data.message || e.response.statusText;
  this.meta = _meta;
}

export default new ApiService();
