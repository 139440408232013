import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import LoadingScreen from "../LoadingScreen";
import PageBody from "../boxes/PageBody";
import RestrictedComponent from "../utils/RestrictedComponent";
import { toastBanner, PERMISSIONS} from "../../services/utilityService";

class Vitals extends Component {

  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading: false,
      vitals:[]
    }
  }

  componentDidMount(){
    const { patient } = this.props;
    this.setLoading(true);
    this.service.get(ENDPOINTS.patients.vitals(patient.id))
      .then(response => {
        this.setState({
          isLoading: false,
          vitals: response.data
        })
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => {
    return (
      <PageBody>
        <LoadingScreen />
      </PageBody>
    )
  };

  render() {
    const { isLoading, vitals } = this.state;

    if(isLoading) return this.renderLoading();

    return (
      <div className="patient-profile">
        <div className="dashboard-card">
          <h2 className="dashboard-card__title-text">Last Recorded Vitals</h2>
          <RestrictedComponent
            displayErrorMessage={true}
            errorComponent={() => (
              <div className="dashboard-card">
                <div style={{position:"relative", height:"10rem"}}>
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                </div>
              </div>
            )}
            condition={this.props.user.permissions.indexOf(PERMISSIONS.patient.patient_vital.read_patient_vital) > -1}>

            {isLoading ? (
              this.renderLoading()
            ) : (
              vitals.length === 0 ? (
                <div style={{fontSize: "2rem", color: "grey"}}>No Vital found</div>
              ) : (
                <React.Fragment>
                  <p className="dashboard-card__body-text">{`Recorded on ${moment(vitals[0].created_at).format('MMMM Do YYYY, h:mm a')}, by ${vitals[0].created_by.name}`}</p>
                  <div className="basic-info">
                    <div style={{ width: "50%", marginRight: "1rem"}}>
                      <div className="dashboard-table-overflow">
                        <table className="dashboard-table">
                          <tbody>
                          <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">Height</th>
                            <td className="dashboard-table-item dashboard-table-item__body">{vitals[0].height}cm</td>
                          </tr>

                          <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">Weight</th>
                            <td className="dashboard-table-item dashboard-table-item__body">{vitals[0].weight}kg</td>
                          </tr>

                          <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">Blood Pressure</th>
                            <td className="dashboard-table-item dashboard-table-item__body">{vitals[0].systolic_blood_pressure} / {vitals[0].diastolic_blood_pressure} mmHg</td>
                          </tr>

                          <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">Temperature</th>
                            <td className="dashboard-table-item dashboard-table-item__body">{vitals[0].temperature} °C</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div style={{ width: "50%", marginRight: "1rem"}}>
                      <div className="dashboard-table-overflow">
                        <table className="dashboard-table">
                          <tbody>
                          <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">BMI</th>
                            <td className="dashboard-table-item dashboard-table-item__body">{vitals[0].bmi ? `${vitals[0].bmi}` : "Not Available"}</td>
                          </tr>

                          <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">Heart Rate</th>
                            <td className="dashboard-table-item dashboard-table-item__body">{vitals[0].heart_rate ? `${vitals[0].heart_rate} per minute` : "Not Available"}</td>
                          </tr>

                          <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">Respiratory Rate</th>
                            <td className="dashboard-table-item dashboard-table-item__body">{vitals[0].respiratory_rate ? `${vitals[0].respiratory_rate} per minute` : 'Not Available'}</td>
                          </tr>

                          <tr>
                            <th className="dashboard-table-item dashboard-table-item__header">Oxygen Saturation</th>
                            <td className="dashboard-table-item dashboard-table-item__body">{vitals[0].oxygen_saturation ? `${vitals[0].oxygen_saturation} %` : 'Not Available'}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            )}

          </RestrictedComponent>
          {/*<button className="dashboard__secondary-btn dashboard-form__btn">*/}
            {/*<Link to={`/patients/${this.props.match.params.patientId}/vitals/new`}>Record Vital</Link>*/}
          {/*</button>*/}
        </div>
      </div>
    )
  }
}

export default withRouter(Vitals);