import React, { Component } from 'react';
import numberFormatter from 'number-formatter';
import moment from 'moment';
import Paginator from "./Paginator";
import PropTypes from 'prop-types';
import SearchInput from "../inputs/SearchInput";
import LoadingScreen from '../LoadingScreen';
import CenteredContentBlock from "./CenteredContentBlock";
import { ApiService } from '../../services/ApiService';

class DataTable extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      tableData:[],
      filter:"",
      pagination: null
    };
  };

  componentDidMount(){
    this.getTableData();
  };

  filterTableData = (filter) => {
    const { tableData, tableColumnData } = this.props;

    let tableArr = [];

    for (let i=0; i < tableData.length; i++) {
      for (let j = 0; j < tableColumnData.length; j++) {
        const columnData = tableColumnData[j];
        const tableKey = tableData[i][tableColumnData[j].key];
        if(columnData.searchable && (tableKey ? tableKey : 'N/A').toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          tableArr.push(tableData[i]);
        }
      }
    }

    return tableArr;
  }

  getTableData = (page=1, filter="",limit=10) => {
    const { dataSource, tableData } = this.props;
    const queryData = { limit, page, q: filter};

    if(dataSource) {
      this.setLoading(true);

      this.ApiService = new ApiService(dataSource.accessToken);

      this.ApiService.get(dataSource.url, queryData)
        .then((response) =>
          this.setState({
            isLoading:false,
            tableData: response.data,
            filter,
            pagination: response.pagination
          }))
        .catch((error) => {
          this.setLoading(false);
        });
    }
    else {
      this.setState({
        tableData: filter ? this.filterTableData(filter) : tableData
      });
    }
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  filterList = (query) => {
    if(query.length > 3){
      this.getTableData(1, query);
    }
  };

  clearFilter = () => {
    this.getTableData();
  };

  formatDataBasedOnType = (data,type="string") => {
    switch (type){
      case 'string':
        return data;
      case 'number':
        return numberFormatter("#,###.00", Number(data));
      case 'date':
        return moment(data).format("ddd, MMM Do YYYY");
      default:
        return data;
    }
  };

  render() {
    const { tableColumnData, canSearchTableList, searchInputPlaceholder, showTableDetail } = this.props;
    const { tableData, isLoading, filter, pagination  } = this.state;
    return (
      <React.Fragment>
        {
          canSearchTableList && 
            <SearchInput
              placeholder={searchInputPlaceholder}
              onSubmit={this.filterList}
              clearFilter={this.clearFilter}/>
        }
        {
          isLoading ? 
          <div className="dashboard-card" style={{minHeight:"20rem", position:"relative"}}>
            <LoadingScreen/>
          </div>
          :
          <div className="dashboard-table-overflow">
            {
              (tableData.length === 0) ? (
                <CenteredContentBlock>
                  <div style={{fontSize: "40px", color: "grey"}}>No result found</div>
                </CenteredContentBlock>
              ) : (
                <React.Fragment>
                <table className="dashboard-table">
                  <thead>
                    <tr>
                      {
                        tableColumnData.map((columnData) => (
                          <th key={columnData.key || columnData.alias} className="dashboard-table-item dashboard-table-item__header">
                            {columnData.alias ? columnData.alias : columnData.key.replace(/_/g, " ")}
                          </th>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableData.map((data, index)=>(
                        <tr
                          key={`${data.id}-${index}`}
                          onClick={() => showTableDetail && showTableDetail(data.id)}
                          style={{cursor:"pointer"}}
                        >
                          { 
                            tableColumnData.map((colData, index) => (
                              <td key={index+1} className="dashboard-table-item dashboard-table-item__body">
                                {
                                  colData.format ? 
                                    colData.format(data[colData.key]) 
                                    : 
                                    (
                                      !data[colData.key] ?
                                        "N/A"
                                        :
                                        this.formatDataBasedOnType(data[colData.key || colData],colData.type)
                                    )
                                }
                              </td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                {
                  pagination &&
                    <Paginator
                      lastPage={pagination.last_page}
                      currentPage={pagination.current_page}
                      onPageChange={(page) => {
                        this.getTableData(page, filter || "");
                      }}/>
                }
              </React.Fragment>
            )}
          </div>
        }
      </React.Fragment>
    );
  }
}

DataTable.propTypes = {
  tableData: PropTypes.array,
  tableColumnData: PropTypes.arrayOf(PropTypes.shape({
    key:PropTypes.string.isRequired,
    type:PropTypes.string.isRequired,
    searchable: PropTypes.bool,
    alias:PropTypes.string,
    format:PropTypes.func
  })),
  showTableDetail: PropTypes.func,
  styles: PropTypes.object,
  styleClass: PropTypes.string,
  dataSource: PropTypes.shape({
    url:PropTypes.string,
    accessToken:PropTypes.string
  })
};

export default DataTable;