import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import PropsRoute from "../components/utils/PropsRoute";
import ClaimDetailComponent from "../components/ClaimDetailComponent";
import ClaimsContainer from "../containers/ClaimsContainer";

class ClaimRoutes extends React.Component {
  render () {
    const {user} = this.props;
    return (
      <Switch>
        <PropsRoute path='/claims/:claimId' user={user} component={ClaimDetailComponent}/>
        <PropsRoute path='/claims' user={user} component={ClaimsContainer}/>
      </Switch>
    )
  }
}

export default withRouter(ClaimRoutes);