import React from 'react';
import {ApiService,ENDPOINTS} from "../../services/ApiService";
import Modal from '../boxes/Modal';
import AddAllergyForm from "../forms/AddAllergyForm";
import LoadingScreen from "../LoadingScreen";
import FilterableSelect from "../inputs/FilterableSelect";
import  { toastBanner } from "../../services/utilityService";

const AllergyModal = (props) => (
  <Modal title="Add Allergy" onClose={props.onClose}>
    <React.Fragment>
      <AddAllergyComponent {...props}/>
    </React.Fragment>
  </Modal>
);

class AddAllergyComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading:false,
      allergies: [],
      selectedAllergies:[],
      mode: AddAllergyComponent.MODES.search
    }
  }

  componentDidMount(){
    this.setLoading(true);
    this.service.get(ENDPOINTS.utilities.allergies)
      .then(response => {
        this.setState({
          isLoading: false,
          allergies: response.data
        })
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );

  setMode = (mode) => {
    this.setState({
      mode
    })
  };

  onAllergySelected = (allergy) => {
    this.setState({
      selectedAllergies: [...this.state.selectedAllergies, allergy],
      mode: AddAllergyComponent.MODES.search
    })
  };

  removeAllergy = (id) => {
    const {selectedAllergies} = this.state;
    const index = selectedAllergies.findIndex(a => a.id === id);
    selectedAllergies.splice(index,1);
    this.setState({
      selectedAllergies
    });
  };

  onSaveAllergies = () => {
    const { onAllergiesAdded, patientId } = this.props;

    const requestData = this.state.selectedAllergies.map(allergy => ({id: allergy.id}));

    this.service.post(ENDPOINTS.patients.allergies(patientId), {allergies: requestData})
      .then(() => {
        this.setState({
          isLoading: false,
          allergies: []
        }, () => {
          if(onAllergiesAdded){
            onAllergiesAdded(this.state.selectedAllergies);
          }
        })
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  };


  render(){

    const {isLoading, selectedAllergies, mode, allergies} = this.state;

    if(isLoading) return this.renderLoading();

    return (
      <div style={{display:"flex",flexDirection:"column"}}>

        {mode === AddAllergyComponent.MODES.add ? (
          <AddAllergyForm
            userToken={this.props.userToken}
            onAllergyCreated={this.onAllergySelected}
            onClose={() => this.setMode(AddAllergyComponent.MODES.search)}/>
        ) : (
          <div style={{display: "flex", flexDirection:"column", justifyContent:"center"}}>
            <FilterableSelect
              items={allergies}
              className="search-section filterable-select"
              filterBy={['name','reaction']}
              clearOnSelect
              extraKey="reaction"
              placeholder="&#xf002; Enter allergy name or reactions"
              input={{
                className: "dashboard-form__input",
                value: '',
                onChange: (selectedItem) => {
                  this.onAllergySelected(selectedItem);
                }
              }}/>
            <p style={{margin:"1rem", fontSize:"1.5rem"}}>OR</p>
            <button className="dashboard__secondary-btn" onClick={() => this.setMode(AddAllergyComponent.MODES.add)}>
              Add New Allergy
            </button>

            {selectedAllergies.length > 0 &&
              <p className="dashboard-card__body-text" style={{marginTop: "2rem"}}>Added Allergies: </p>
            }
            <div style={{display:"flex"}}>
              {selectedAllergies.map(allergy => (
                <div key={allergy.id} className="overview-item__tag">
                  <span className="overview-item__small-text">{allergy.name}</span>
                  {`    `}
                  <span
                    className="overview-item__small-text"
                    style={{cursor:"pointer", margin:"0.5rem"}}
                    onClick={() => {
                    this.removeAllergy(allergy.id);
                  }}>x</span>
                </div>
              ))}
            </div>

            <button
              className="dashboard__primary-btn dashboard-form__btn"
              type="submit" style={{marginTop:"3rem"}}
              onClick={this.onSaveAllergies}
              disabled={selectedAllergies.length === 0}>
              Add {selectedAllergies.length} {selectedAllergies.length > 1 ? 'Allergies' : 'Allergy'}
            </button>
          </div>
        )}
      </div>
    )
  }
}

AddAllergyComponent.MODES = {
  search: "search",
  add: "add"
};

export default AllergyModal;