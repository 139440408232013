import React from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';
import LoadingScreen from '../LoadingScreen';

export function withUser(WrappedComponent){
  const mapStateToProps = ({session}) => ({
    sessionUser:session.user
  });
  return connect(mapStateToProps)(class extends React.Component {
      constructor(props){
        super(props);
        this._isMounted = false;
        this.state = {
          isAuth:false,
          user: null,
          error:null
        }
      }

      componentDidMount(){
        this._isMounted = true;
        //check cookie
        //if cookie exists,
        //    check session user
        //        if session user exists
        //             check if cookie and session user accesstoken are the same
        //                  if tokens are the same
        //                      return child component with user prop attached
        //                  if token are not the same
        //                      re-auth with cookie token, and refresh app
        //       if session user doesnt exist
        //            auth with cookie token and create session user
        //if cookie doesnt exists
        //    logout (clear session user) and redirect to accounts login.
          sessionService.loadUser()
            .then(user=>{
                //set authenticated as true
              if(!user.roles){
                this.logout(this.props.history.location.pathname);
              }else{
                this.setUser(user);
               }
            })
            .catch(e=>{
              this.logout();
            })
      }

      setUser = (user) => {
        if(this._isMounted){
          this.setState({
            user,
            isAuth:true
          })
        }
      };

      setError = (error) => {
        this.setState({
          isAuth:false,
          error
        });
      };

      logout = () => {
        window.location.href = `/logout?r=${encodeURI(window.location.href)}`;
      };

      goToUrl = (url) => {
        window.location.href = url;
      };

      componentDidUpdate(){
        if(this.state.isAuth && !_.isEmpty(this.props.sessionUser) && this.props.sessionUser !== this.state.user){
          this.setUser(this.props.sessionUser);
        }
      }

      componentWillUnmount(){
        this._isMounted = false;
      }

      render(){
        return this.state.isAuth ? <WrappedComponent user={this.state.user} {...this.props}/> : <LoadingScreen/>
      }
    })
}

