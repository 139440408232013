import * as _ from 'lodash';
import axios from 'axios';
import {BASE_URL_RELIANCE} from './utilityService';
import ApiService from "../services/ApiService";
const BASE_URL = BASE_URL_RELIANCE;

class UserService {
  constructor() {
    this.ax = axios.create({
      baseURL: BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
    this.data = {};
    this.accessToken = "";
  }

  setAccessToken(_accessToken) {
    this.accessToken = _accessToken;
  }

  terminateAccessToken(token) {
    return this.ax.post(`/logout`, null, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }) 
    .then((response) => {
      return response.data;
    });
  }

  login(username, password) {
    return ApiService.post(`/login`, {
      username: username.trim().toLowerCase(),
      password,
    }).then((response) => {
      return mapUser(response.data);
    });
  }

  resetPassword(values) {
    this.data = values;
    return this.ax.post('/password-reset/generate', this.data)
      .then((response) => {
        return response;
      })
  }

  changePassword(values) {
    this.data = values;
    return this.ax.post('/password-reset/store-password', this.data, {
      headers: {
        "Authorization": `Bearer ${this.accessToken}`
      }
    })
      .then((response) => {
        return response;
      })
  }

  register(values) {
    delete values.profile_picture;
    //delete access_token from post body
    return this.ax.post(`/enrollees/create`, values).then((response) => {
      return response.data.message;
    });
  }

  verifyAccount(values) {
    return this.ax.post('password-reset/verify', values)
      .then(() => {
        return true;
      })
  }

  sendVerificationDetails(values) {
    return this.ax.post('password-reset/security-question', values)
      .then(() => {
        return true;
      })
  }
}

function mapUser(json) {
  return {
    id: _.get(json, "id"),
    firstName: _.get(json, "first_name"),
    lastName: _.get(json, "last_name"),
    emailAddress: _.get(json, "email_address"),
    phoneNumber: _.get(json, "phone_number"),
    roles: _.get(json, "roles"),
    permissions: _.get(json, "permissions"),
    isLoggedIn: true,
    credentials: {
      accessToken: _.get(json, "access_token"),
    },
    preferences: {
      allowPushNotifications: true,
      showCyclePlanner: true,
    },
    branches: _.get(json, 'branches'),
    active_branch_id: _.get(json, "active_branch_id"),
  };
}

export default new UserService();
