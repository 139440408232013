import React, { Component } from 'react';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import MainContent from "../components/boxes/MainContent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import DataTable from "../components/boxes/DataTable";
import LoadingScreen from "../components/LoadingScreen";
import { ApiService, ENDPOINTS } from "../services/ApiService";
import { toastBanner, PERMISSIONS, STAGEDATA } from "../services/utilityService";
import { prescriptionColumnData, invoicesColumnData, 
         procedureColumnData, pacodeColumnData,
         allergiesColumnData, testsColumnData } from "../services/tableColumnData";
import ResultUploadModal from "../components/modals/ResultUploadModal";
import ViewTestResultModal from "../components/modals/ViewTestResultModal";
import RestrictedComponent from "../components/utils/RestrictedComponent";
import {UpdateStageComponentModal} from "../components/UpdateStageComponent";
import ChangeLogModal from "../components/ChangeLogModal";
import Modal from "../components/boxes/Modal";
import EncounterNotesForm from "../components/forms/EncounterNotesForm";
import EncounterCommentForm from "../components/EncounterCommentComponent";
import nl2br from 'react-nl2br';

class EncounterDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.ApiService = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      encounter: null,
      encounterCommentsData: [],
      selectedTest: null,
      showNextStageModal: false,
      showResultUploadModal: false,
      showViewResultModal: false,
      showChangeLogModal: false,
      showEditNoteModal: false,
    }
  }

  componentDidMount(){
    const { match : { params } } = this.props;
    this.setLoading(true);
    this.ApiService.get(ENDPOINTS.encounters.encounter(params.encounterId)).then((response) => {
      this.setState({
        isLoading: false,
        encounter: response.data
      })
    }).catch((error) => {
      toastBanner(error.message || "An error occured!");
    });
    this.getEncounterComments(params.encounterId);
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <MainContent>
      <PageBody>
        <LoadingScreen/>
      </PageBody>
    </MainContent>
  );

  onCreateClaim = () => {
    const {encounter} = this.state;
    const {history} = this.props;
    this.setLoading(true);
    this.ApiService.post(ENDPOINTS.encounters.claims(encounter.id))
      .then(response => {
        const claim = response.data;
        history.push(`/claims/${claim.id}`);
      })
  };

  getEncounterComments = (encounterId) => {
    this.ApiService.get(ENDPOINTS.encounters.comments(encounterId))
      .then((response) => {
        this.setState({
          encounterCommentsData: response.data
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  onEncounterCommentsAdded = (encounterComment) => {
    const { encounterCommentsData } = this.state;

    let updatedEncounterComments = encounterCommentsData;
    updatedEncounterComments.unshift(encounterComment);

    this.setState({
      encounterCommentsData: updatedEncounterComments,
    });
  };

  toggleResultUploadModal = (selectedTest) => {
    this.setState({
      showResultUploadModal: !this.state.showResultUploadModal,
      selectedTest
    })
  };

  toggleChangeLogModal = () => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal
    })
  };

  toggleEditNoteModal = () => {
    this.setState({
      showEditNoteModal: !this.state.showEditNoteModal
    })
  };

  toggleViewResultModal = (selectedTest) => {
    this.setState({
      showViewResultModal: !this.state.showViewResultModal,
      selectedTest
    })
  };

  toggleNextStageModal = () => {
    this.setState({
      showNextStageModal: !this.state.showNextStageModal,
    })
  };

  onResultUploaded = (result) => {
    const {encounter} = this.state;
    const testIndex = encounter.tests.findIndex(t => t.id === result.test_id);
    if(testIndex !== -1){
      encounter.tests[testIndex].status = 3;
      this.setState({
        encounter,
        showResultUploadModal:false,
        selectedTest: null
      })
    }
  };

  onEncounterNoteEdited = (newNote) => {
    const { encounter } = this.state;
    encounter.note = newNote;

    this.setState({
      encounter,
      showEditNoteModal:false
    })
  };

  render() {
    const {
      encounter,
      encounterCommentsData,
      isLoading,
      showResultUploadModal,
      showNextStageModal,
      showViewResultModal,
      selectedTest,
      showChangeLogModal,
      showEditNoteModal
    } = this.state;

    const { history, user } = this.props;

    if(isLoading || !encounter) return this.renderLoading();

    return (
      <MainContent>
        {showResultUploadModal &&
          <ResultUploadModal
            encounterId={encounter.id}
            userToken={user.credentials.accessToken}
            test={selectedTest}
            onResultUploaded={this.onResultUploaded}
            onClose={this.toggleResultUploadModal}/>
        }
        {showViewResultModal &&
          <ViewTestResultModal
            test={selectedTest}
            onClose={this.toggleViewResultModal}/>
        }

        {showNextStageModal &&
          <UpdateStageComponentModal
            onClose={this.toggleNextStageModal}
            userToken={user.credentials.accessToken}
            encounterId={encounter.id}
            currentStageId={encounter.current_stage_id}
            statusId={STAGEDATA.status.completed}/>
        }

        {showChangeLogModal &&
          <ChangeLogModal
            type="encounter_note"
            onClose={this.toggleChangeLogModal}
            typeId={encounter.id}
            title={`Changes on Encounter #${encounter.id}`}/>
        }
        {showEditNoteModal &&
          <Modal title={`Edit Encounter #${encounter.id} Note`} onClose={this.toggleEditNoteModal}>
            <EncounterNotesForm
              userToken={user.credentials.accessToken}
              encounterId={encounter.id}
              encounterNote={encounter.note}
              onEncounterNoteSaved={this.onEncounterNoteEdited}/>
          </Modal>
        }
        <PageHeader
          title={`Encounter #${encounter.id}`}/>
        <PageBody>
          <div style={{display:"flex", justifyContent:"flex-end"}}>
            { encounter.policy && encounter.invoices.length > 0 &&
              <button
                onClick={
                  !encounter.claim_id
                  ?
                  this.onCreateClaim
                  :
                  () => history.push(`/claims/${encounter.claim_id}`)
                }
                className="dashboard__secondary-btn"
                style={{alignSelf:"flex-end", marginBottom:"1rem"}}>
                {!encounter.claim_id ? "Create Claim" : "View Claim"}
              </button>
            }
            {
              encounter.current_stage_id !== 1 &&
                <button
                  onClick={() => history.push(`/appointments?p_id=${encounter.patient.id}&e_id=${encounter.id}&f=true`) }
                  className="dashboard__secondary-btn"
                  style={{alignSelf:"flex-end", marginBottom:"1rem"}}>
                  Book Follow-up Appointment
                </button>
            }
          </div>
          <div className="patient-detail">
            <div style={{display:"flex", justifyContent:"flex-start",marginBottom:"2rem"}}>
              <div className="dashboard-card" style={{ width:"50%"}}>
                <h1 className="dashboard-card__title-text">
                  {`Encounter for ${encounter.patient.name}`}
                  <Link to={`/patients/${encounter.patient.id}`} className="dashboard__secondary-btn-link no-print">
                    <i className="fa fa-external-link-alt"/>&nbsp;View Patient
                  </Link>
                </h1>
                <p className="dashboard-card__body-text">{`Created ${moment(encounter.created_at).format("dddd, MMMM Do YYYY")}`}</p>
                <h1 className="dashboard-card__title-text">Encounter Type</h1>
                <p className="dashboard-card__body-text">{
                  !encounter.parent_encounter_id ? (
                    "Regular Encounter"
                  ) : (
                    <React.Fragment>
                      <span>Follow Up Encounter</span>
                      <Link to={`/encounters/${encounter.parent_encounter_id}`} className="dashboard__secondary-btn-link no-print">
                        <i className="fa fa-external-link-alt"/>&nbsp;Click to View previous encounter
                      </Link>
                    </React.Fragment>
                  )}
                </p>
                <h1 className="dashboard-card__title-text">Encounter Medium</h1>
                  <p className="dashboard-card__body-text">{encounter.appointment_medium || "Visit"}</p>
                {
                  encounter.physician &&
                    <React.Fragment>
                      <h1 className="dashboard-card__title-text">Physician/Health Coach</h1>
                      <p className="dashboard-card__body-text">
                        {encounter.physician.name}
                      </p>
                    </React.Fragment>
                }
                <h1 className="dashboard-card__title-text">Encounter Reason</h1>
                <p className="dashboard-card__body-text">
                {encounter.note ? encounter.note.reason : "N/A"}
                </p>

                <h1 className="dashboard-card__title-text">Care Category</h1>
                <p className="dashboard-card__body-text">
                {encounter.care_category ? encounter.care_category.name : "N/A"}
                </p>


                <h1 className="dashboard-card__title-text">Current Stage</h1>
                <p className="dashboard-card__body-text">
                  {STAGEDATA.getStageName(encounter.current_stage_id) || "Not Available"}
                  {encounter.current_stage_id !== STAGEDATA.stages.completed &&
                    <span
                      className="button_link"
                      onClick={this.toggleNextStageModal}
                      style={{fontSize:"1rem", marginLeft:"1rem", cursor:"pointer"}}>
                      Update Stage
                    </span>
                  }
                </p>
              </div>

              <div className="dashboard-card" style={{ marginLeft: "2rem", width:"50%"}}>
                <h1 className="dashboard-card__title-text">Encounter Comments ({encounterCommentsData.length})</h1>
                <div style={{ height: "30rem", overflow:"scroll" }}>
                  {
                    encounterCommentsData.length > 0 ?
                      encounterCommentsData.map((encounterComment, index) => (
                          <div key={encounterComment.id} className="dashboard-card" style={{padding: "1rem",borderBottom: "2px solid #f8f8f8"}}>
                            <p className="dashboard-card__body-text" style={{fontSize:"1.3rem", marginBottom:"1rem"}}>{encounterComment.created_by.name} wrote:</p>

                            <h2 style={{wordBreak: "break-word", marginBottom:"1rem"}} className="dashboard-card__body-text">
                              {encounterComment.comment}
                            </h2>
                            <p className="dashboard-card__body-text" style={{fontSize: "1rem", color:"#565656", marginBottom:"1rem"}}>{moment(encounterComment.created_at).calendar()}</p>
                          </div>
                      ))
                      :
                      <div className="dashboard-card" style={{fontSize: "1.5rem", color: "grey", marginTop:"1rem"}}>Nothing to see here :)</div>
                  }
                </div>

                <EncounterCommentForm
                  user={user}
                  patient={encounter.patient}
                  encounterId={encounter.id}
                  onEncounterCommentsAdded={this.onEncounterCommentsAdded}
                />
              </div>
            </div>

            <div style={{display:"flex",justifyContent:"flex-start",marginBottom:"2rem"}}>
              <div className="dashboard-card" style={{ width:"50%" }} >
                <h1 className="dashboard-card__title-text">
                  Encounter Details
                  {(encounter.note.chief_complaint && encounter.current_stage_id > STAGEDATA.stages.yetToArrive) &&
                  <span
                    className="button_link"
                    onClick={this.toggleChangeLogModal}
                    style={{fontSize:"1rem", marginLeft:"1rem", cursor:"pointer"}}>
                    View change log
                  </span>
                  }
                </h1>

                <RestrictedComponent
                  displayErrorMessage={true}
                  errorComponent={() => (
                    <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                  )}
                  condition={this.props.user.permissions.indexOf(PERMISSIONS.encounter.encounter_note.read_encounter_note) > -1}>

                  {(!encounter.note.chief_complaint && encounter.current_stage_id > STAGEDATA.stages.yetToArrive) ? (
                    <RestrictedComponent
                      displayErrorMessage={false}
                      condition={this.props.user.permissions.indexOf(PERMISSIONS.encounter.encounter_note.create_encounter_note) > -1}>
                      <button className="dashboard__secondary-btn">
                        <Link to={`/patients/${encounter.patient.id}/encounter-notes/new?e_id=${encounter.id}`}>
                          Add Encounter Note
                        </Link>
                      </button>
                    </RestrictedComponent>
                  ) : (
                    <React.Fragment>
                      <div className="profile-item__label" style={{marginBottom:"1rem"}}>Diagnoses:</div>
                      <p className="dashboard-card__body-text">
                        {!(encounter.diagnoses && encounter.diagnoses.length > 0)  ? (
                          "No Diagnosis available"
                        ) : (
                          encounter.diagnoses.map(d => d.name).join(", ")
                        )}
                      </p>
                      <div className="profile-item__label" style={{marginBottom:"1rem"}}>Chief Complaint:</div>
                      <p className="dashboard-card__body-text">{!encounter.note.chief_complaint  ? "No Complaint available": nl2br(encounter.note.chief_complaint)}</p>

                      <div className="profile-item__label" style={{marginBottom:"1rem"}}>Observation:</div>
                      <p className="dashboard-card__body-text">{!encounter.note.observation ? "No observation available": nl2br(encounter.note.observation)}</p>

                      <div className="profile-item__label" style={{marginBottom:"1rem"}}>Treatment:</div>
                      <p className="dashboard-card__body-text">{!encounter.note.treatment ? "No treatment available": nl2br(encounter.note.treatment)}</p>
                      <button className="dashboard__secondary-btn" 
                        onClick={ () =>
                          encounter.current_stage_id !== STAGEDATA.stages.physician ?
                            this.toggleEditNoteModal()
                            :
                            this.props.history.push(`/patients/${encounter.patient.id}/encounter-notes/new?e_id=${encounter.id}`)
                        }>
                        Edit Encounter Note
                      </button>
                    </React.Fragment>
                  )}
                </RestrictedComponent>
              </div>
              <div className="dashboard-card" style={{marginLeft:"2rem", width:"50%"}}>
                <h1 className="dashboard-card__title-text">Vitals Recorded</h1>
                <RestrictedComponent
                  displayErrorMessage={true}
                  errorComponent={() => (
                    <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                  )}
                  condition={this.props.user.permissions.indexOf(PERMISSIONS.encounter.encounter_note.read_encounter_note) > -1}>

                  {(!(encounter.vitals && encounter.vitals.length > 0) && encounter.current_stage_id > STAGEDATA.stages.yetToArrive) ? (
                    <RestrictedComponent
                      displayErrorMessage={false}
                      condition={this.props.user.permissions.indexOf(PERMISSIONS.encounter.encounter_vital.create_encounter_vital) > -1}>
                      <button className="dashboard__secondary-btn">
                        <Link to={`/patients/${encounter.patient.id}/vitals/new?e_id=${encounter.id}`}>
                          Record Vitals
                        </Link>
                      </button>
                    </RestrictedComponent>
                  ) : (
                    <React.Fragment>
                      {encounter.vitals && encounter.vitals.length === 0 ? (
                        <div style={{fontSize: "2rem", color: "grey"}}>No Vital found</div>
                      ) : (
                        <div className="dashboard-table-overflow">
                          <table className="dashboard-table">
                            <tbody>
                            <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">Height</th>
                              <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].height}cm</td>
                            </tr>

                            <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">Weight</th>
                              <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].weight}kg</td>
                            </tr>

                            <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">Blood Pressure</th>
                              <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].systolic_blood_pressure} / {encounter.vitals[0].diastolic_blood_pressure} mmHg</td>
                            </tr>

                            <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">Temperature</th>
                              <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].temperature} °C</td>
                            </tr>
                            <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">BMI</th>
                              <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].bmi ? `${encounter.vitals[0].bmi}` : "Not Available"}</td>
                            </tr>

                            <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">Heart Rate</th>
                              <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].heart_rate ? `${encounter.vitals[0].heart_rate} per minute` : "Not Available"}</td>
                            </tr>

                            <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">Respiratory Rate</th>
                              <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].respiratory_rate ? `${encounter.vitals[0].respiratory_rate} per minute` : 'Not Available'}</td>
                            </tr>

                            <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">Oxygen Saturation</th>
                              <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].oxygen_saturation ? `${encounter.vitals[0].oxygen_saturation} %` : 'Not Available'}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </RestrictedComponent>
              </div>
            </div>

            <div className="dashboard-card" style={{marginBottom: "2rem"}}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                  <h2 className="dashboard-card__title-text">Invoices Generated</h2>
                </div>
                <div 
                  style={{justifyContent: "flex-end"}}
                >
                  <button 
                    onClick={() => 
                      this.props.history.push(`/encounters/${encounter.id}/new-invoice/${encounter.patient.id}`)}
                    className="dashboard__secondary-btn"
                    style={{marginBottom:"1rem"}}
                    >
                    Add New Invoice
                  </button>
                </div>
              </div>
              <RestrictedComponent
                displayErrorMessage={true}
                errorComponent={() => (
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                )}
                condition={this.props.user.permissions.indexOf(PERMISSIONS.invoice.read_invoice) > -1}>

                {
                  encounter.invoices.length > 0 ?
                    <React.Fragment>
                      <div>
                        <p className="dashboard-card__body-text">Click on an invoice to see more details</p>
                      </div>
                      <DataTable
                        tableData={encounter.invoices}
                        tableColumnData={invoicesColumnData}
                        showTableDetail={(id) => this.props.history.push(`/invoices/${id}`)}
                      />
                    </React.Fragment>
                    :
                    <div style={{fontSize: "2rem", color: "grey"}}>No Invoice found</div>
                }

              </RestrictedComponent>
            </div>

            <div className="dashboard-card" style={{marginBottom: "2rem"}}>
              <h2 className="dashboard-card__title-text">Procedures</h2>

              <RestrictedComponent
                displayErrorMessage={true}
                errorComponent={() => (
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                )}
                condition={this.props.user.permissions.indexOf(PERMISSIONS.patient.patient_allergy.read_patient_allergy) > -1}>

                {
                  encounter.procedures.length === 0 ?
                  <div style={{fontSize: "2rem", color: "grey"}}>No Procedures</div>
                  :
                  <DataTable
                    tableData={encounter.procedures}
                    tableColumnData={procedureColumnData}
                  />
                }

              </RestrictedComponent>
            </div>

            <div className="dashboard-card" style={{marginBottom: "2rem"}}>
              <h2 className="dashboard-card__title-text">PA Code</h2>

              <RestrictedComponent
                displayErrorMessage={true}
                errorComponent={() => (
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                )}
                condition={this.props.user.permissions.indexOf(PERMISSIONS.encounter.encounter_pacode.read_encounter_pacode) > -1}>
                  {
                    encounter.pacodes.length === 0 ?
                      <div style={{fontSize: "2rem", color: "grey"}}>No PA Code</div>
                      :
                      <DataTable
                      tableData={encounter.pacodes}
                      tableColumnData={pacodeColumnData}
                    />
                  }
              </RestrictedComponent>
            </div>

            <div className="dashboard-card" style={{marginBottom: "2rem"}}>
              <h2 className="dashboard-card__title-text">Prescriptions</h2>

              <RestrictedComponent
                displayErrorMessage={true}
                errorComponent={() => (
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                )}
                condition={this.props.user.permissions.indexOf(PERMISSIONS.prescription.read_prescription) > -1}>
                 { 
                   encounter.prescriptions.length === 0 ?
                    <div style={{fontSize: "2rem", color: "grey"}}>No Prescriptions</div>
                    :
                    <DataTable
                      tableData={encounter.prescriptions}
                      tableColumnData={prescriptionColumnData}
                    />
                  }
              </RestrictedComponent>
            </div>

            <div className="dashboard-card" style={{marginBottom: "2rem"}}>
              <h2 className="dashboard-card__title-text">Allergies</h2>

              <RestrictedComponent
                displayErrorMessage={true}
                errorComponent={() => (
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                )}
                condition={this.props.user.permissions.indexOf(PERMISSIONS.patient.patient_allergy.read_patient_allergy) > -1}>
                { 
                  encounter.allergies.length === 0 ?
                    <div style={{fontSize: "2rem", color: "grey"}}>No Allergies</div>
                    :   
                    <DataTable
                      tableData={encounter.allergies}
                      tableColumnData={allergiesColumnData}
                    />
                }
              </RestrictedComponent>
            </div>

            <div className="dashboard-card" style={{marginBottom:"2rem"}}>
              <h2 className="dashboard-card__title-text">Tests/Diagnostics</h2>

              <RestrictedComponent
                displayErrorMessage={true}
                errorComponent={() => (
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                )}
                condition={this.props.user.permissions.indexOf(PERMISSIONS.encounter.encounter_test.read_encounter_test) > -1}>
                {
                  encounter.tests.length === 0 ? 
                    <div style={{fontSize: "2rem", color: "grey"}}>No Tests</div>
                    :
                    <DataTable
                      tableData={encounter.tests}
                      tableColumnData={testsColumnData}
                    />
                }
              </RestrictedComponent>
            </div>
          </div>
        </PageBody>
      </MainContent>
    )
  }
}

export default withRouter(EncounterDetailContainer);