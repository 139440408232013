import React, { Component } from 'react';
import queryString from 'querystring';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import {withRouter} from 'react-router-dom';
import {reduxForm, Field, change} from 'redux-form';
import PageBody from '../boxes/PageBody';
import LoadingScreen from "../LoadingScreen";
import RestrictedComponent from "../utils/RestrictedComponent";
import { toastBanner, PERMISSIONS } from "../../services/utilityService";

class RecordVitalForm extends Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      eId:null,
      isLoading:false
    }
  }

  componentDidMount(){
    const { location, dispatch } = this.props;
    const eId = queryString.parse(location.search.substr(1)).e_id;
    if(eId){
      dispatch(change("recordVitalForm","encounter_id",eId));
      this.setState({
        eId
      });
    }else{
      toastBanner("Vitals can only be recorded during an Encounter");
    }
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  onSubmit = (values) => {
    const { history, reset } = this.props;

    this.setLoading(true);
    this.service.post(ENDPOINTS.encounters.vitals(this.state.eId), values)
      .then(() => {
        reset();
        toastBanner("Vitals successfully recorded. You will now be redirected away", "success");
        setTimeout(()=>{
          history.push(`/overview?e_id=${this.state.eId}`);
        },3000)
      })
      .catch(error => {
        this.setLoading(false);
        toastBanner(error.message || "An Error Occurred")
      });
  };

  render() {
    const {handleSubmit, pristine, submitting} = this.props;
    const { isLoading } = this.state;

    if(isLoading) return (<PageBody><LoadingScreen /></PageBody>);

    return (
        <PageBody>
          <div className="dashboard-card">
            <RestrictedComponent
              displayErrorMessage={true}
              errorComponent={() => (
                <div className="dashboard-card">
                  <div style={{position:"relative", height:"10rem"}}>
                    <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                  </div>
                </div>
              )}
              condition={this.props.user.permissions.indexOf(PERMISSIONS.encounter.encounter_vital.create_encounter_vital) > -1}>
              <form className="dashboard-form" onSubmit={handleSubmit(this.onSubmit)}>
                <div className="dashboard-form-item-wrapper">
                  <div className="dashboard-input-wrap profile-input-wrap">
                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Height (in cm)
                      </label>
                      <Field component="input" className="dashboard-form__input" type="number" placeholder="Enter height here" name="height" step="0.01"/>
                    </span>

                    <span className="dashboard-form-item">
                        <label
                          className="profile-item__label"
                          htmlFor="">
                          Weight (in kg)
                        </label>
                        <Field component="input" className="dashboard-form__input" type="number" placeholder="Enter weight here" name="weight" step="0.01"/>
                    </span>

                    <span className="dashboard-form-item">
                        <label
                          className="profile-item__label"
                          htmlFor="">
                          Systolic Blood Pressure (in mmHg)
                        </label>
                        <Field component="input" className="dashboard-form__input" type="number" placeholder="Enter systolic blood pressure here" name="systolic_blood_pressure"/>
                    </span>

                    <span className="dashboard-form-item">
                        <label
                          className="profile-item__label"
                          htmlFor="">
                          Diastolic Blood Pressure (in mmHg)
                        </label>
                        <Field component="input" className="dashboard-form__input" type="number" placeholder="Enter diastolic blood pressure here" name="diastolic_blood_pressure"/>
                    </span>
                  </div>

                  <div className="dashboard-input-wrap profile-input-wrap">
                  <span className="dashboard-form-item">
                      <label className="profile-item__label" htmlFor="">
                        Temperature (in °C)
                      </label>
                      <Field component="input" className="dashboard-form__input" type="number" placeholder="Enter temperature here" name="temperature" step="0.01"/>
                  </span>
                    <span className="dashboard-form-item">
                      <label className="profile-item__label" htmlFor="">
                        Respiratory Rate (in mins)
                      </label>
                      <Field component="input" className="dashboard-form__input" type="number" placeholder="Enter respiratory rate here" name="respiratory_rate"/>
                  </span>
                    <span className="dashboard-form-item">
                      <label className="profile-item__label" htmlFor="">
                        Heart Rate (in °C)
                      </label>
                      <Field component="input" className="dashboard-form__input" type="number" placeholder="Enter heart rate here" name="heart_rate"/>
                  </span>
                    <span className="dashboard-form-item">
                      <label className="profile-item__label" htmlFor="">
                        Oxygen Saturation ( in %)
                      </label>
                      <Field component="input" className="dashboard-form__input" type="number" placeholder="Enter oxygen saturation here" name="oxygen_saturation"/>
                  </span>
                  </div>
                </div>
                <Field component="input" type="hidden" name="encounter_id"/>
                <div style={{display:"flex",flexDirection:"flex-start"}}>
                  <button className="dashboard__primary-btn dashboard-form__btn" disabled={pristine || submitting}>Save</button>
                  <button className="dashboard__secondary-btn dashboard-form__btn">Cancel</button>
                </div>
              </form>
            </RestrictedComponent>
          </div>
        </PageBody>
    );
  }
}

RecordVitalForm = reduxForm({
  form: 'recordVitalForm',
  destroyOnUnmount: false
})(RecordVitalForm);

export default withRouter(RecordVitalForm);