import React from 'react';
import moment from 'moment';
import {STAGEDATA} from "../services/utilityService";

const WaitListComponent = ({stageList, stageName, onClickStage}) => {

  const ongoing = stageList.filter(stage => Number(stage.status) === STAGEDATA.status.active);
  const waiting = stageList.filter(stage => Number(stage.status) === STAGEDATA.status.waiting);

  return (
      <div className="dashboard-card overview-card">
        <h2 className="dashboard-card__title-text">{stageName}</h2>

        {stageName === "Yet to arrive" ? (
          <React.Fragment>
            {/*<WaitListSection title="Waiting" count={ongoing.length}/>*/}
            {stageList.length > 0 ? stageList.map(stage => (
              <WaitListCard
                stage={stage}
                key={stage.encounter.id}
                onClickStage={onClickStage}/>
            )) : (
              <p style={{margin:"2rem 1rem", fontStyle:"italic"}}>(None)</p>)
            }
          </React.Fragment>
        ) : (
          <React.Fragment>

            <WaitListSection title="Ongoing" count={ongoing.length}/>

            {ongoing.length > 0 ? ongoing.map(stage => (
              <WaitListCard
                stage={stage}
                key={stage.encounter_id}
                onClickStage={onClickStage}/>
            )) : (
              <p style={{margin:"2rem 1rem", fontStyle:"italic"}}>(None)</p>)
            }

            <WaitListSection title="Waiting" count={waiting.length}/>

            {waiting.length > 0 ? waiting.map(stage => (
              <WaitListCard
                stage={stage}
                key={stage.encounter_id}
                onClickStage={onClickStage}/>
            )) : (<p style={{margin:"2rem 1rem", fontStyle:"italic"}}>(None)</p>)}

          </React.Fragment>
        )}
      </div>

  )
};

export const WaitListSection = ({title, count}) => (
  <div className={`overview-card__group overview-item__group-${title === "Ongoing" ? "active" : "waiting"}`}>
    <p>
      {title}
    </p>
    <p className="overview-card__group-number">
      {count}
    </p>
  </div>
);

export class WaitListCard extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      waitTime: '00:00',
    }
  }

  componentDidMount(){
    const { stage } = this.props;
    if(Number(stage.status) === STAGEDATA.status.waiting) {
      this.waitTimer = setInterval(()=>{
        const duration = moment.duration(moment().diff(moment(stage.created_at)));
        this.setState({
          // waitTime: `${padInteger(duration.asMinutes())}:${padInteger(duration.asSeconds())}`
          waitTime: moment(duration.asMilliseconds()).format('mm:ss')
        });
      },1000)
    }
  }

  componentWillUnmount(){
    if(this.waitTimer)
      clearInterval(this.waitTimer);
  }

  getOngoingStageText = () => {
    const { stage : { current_stage_id } } = this.props;
    switch (current_stage_id){
      case STAGEDATA.stages.yetToArrive:
        return 'Waiting for Arrival';
      case STAGEDATA.stages.frontDesk:
        return "Checking-in";
      case STAGEDATA.stages.triage:
        return "Recording Vitals";
      case STAGEDATA.stages.physician:
        return "Ongoing consultation";
      case STAGEDATA.stages.pharmacist:
        return "Receiving Drugs";
      default:
        return "Doing Stuff"
    }
  };

  onStageClicked = () => {
    const {onClickStage, stage} = this.props;
    if(onClickStage)
      onClickStage(stage);
  };

  render(){
    const { waitTime } = this.state;
    const { stage } = this.props;
    return (
      <div className="overview-card__item-scroll" onClick={this.onStageClicked}>
        <div className="overview-item">
          <p className="dashboard-card__body-text" style={{marginBottom:"1rem"}}>{stage.patient.name}</p>
          {stage.current_stage_id === STAGEDATA.stages.frontDesk && stage.previous_stage !== null &&
            <span className="overview-item__tag-wrap">
              {/*<div className="overview-item__tag">From: {STAGEDATA.stageIds[stage.previous_stage]}</div>*/}
            </span>
          }
          {Number(stage.status) === STAGEDATA.status.active &&
            <React.Fragment>
              <span className="overview-item__tag-wrap">
                <div className="overview-item__tag">{this.getOngoingStageText()}</div>
              </span>
              {/*{stage.current_stage.user !== null && <div>{stage.current_stage.user.name}</div>}*/}
            </React.Fragment>
          }
          {Number(stage.status) === STAGEDATA.status.waiting && stage.current_stage_id !== STAGEDATA.stages.yetToArrive &&
            <p className="overview-item__small-text">Wait time: {waitTime}</p>
          }
          {stage.current_stage_id === STAGEDATA.stages.yetToArrive &&
            <p className="overview-item__small-text">Arrives {moment(stage.appointment_time).fromNow()}</p>
          }
        </div>
      </div>
    )
  }
}

export const padInteger = (integer) => {
  return integer < 10 ? '0'+Math.floor(integer) : Math.floor(integer);
};


export default WaitListComponent;