import React from 'react';
import {Redirect, Switch, withRouter} from 'react-router-dom';
import PropsRoute from "../components/utils/PropsRoute";
import BasicInfo from "../components/patientNavbar/BasicInfo";
import MedicalsInfo from "../components/patientNavbar/MedicalsInfo";
import Vitals from "../components/patientNavbar/Vitals";
import EncounterNotes from "../components/patientNavbar/Encounters";
import EncounterNoteDetail from "../containers/EncounterNoteDetail";
import Invoices from "../components/patientNavbar/Invoices";
//import Payments from "../components/patientNavbar/Payments";
import RecordVitalForm from "../components/forms/RecordVitalForm";
import NewEncounterNoteContainer from "../containers/NewEncounterNoteContainer";
import NewPatientContainer from "../containers/NewPatientContainer";
import AppointmentsContainer from "../containers/AppointmentsContainer";
import Files from "../components/patientNavbar/Files";

const PatientDetailRoutes = ({user, patient}) => (
  <Switch>
    <PropsRoute path='/patients/:patientId/edit' user={user} patient={patient} exact component={(props) => <NewPatientContainer user={props.user} patient={props.patient}/>}
    />
    <PropsRoute path='/patients/:patientId/basic' user={user} patient={patient} exact component={BasicInfo}
    />
    <PropsRoute path='/patients/:patientId/medical' user={user} patient={patient} exact component={MedicalsInfo}
    />
    <PropsRoute path='/patients/:patientId/vitals/new' user={user} patient={patient} exact component={RecordVitalForm}/>
    <PropsRoute
      path='/patients/:patientId/vitals'
      user={user}
      exact
      patient={patient}
      component={Vitals}
    />
    <PropsRoute path='/patients/:patientId/appointments' user={user} patient={patient} exact component={(props) => <AppointmentsContainer user={props.user} patient={props.patient}/>}
    />
    <PropsRoute path='/patients/:patientId/encounter-notes/new' user={user} patient={patient} exact
                component={NewEncounterNoteContainer}/>
    <PropsRoute path='/patients/:patientId/encounter-notes/:eId' user={user} patient={patient} exact
                component={EncounterNoteDetail}/>
    <PropsRoute
      path='/patients/:patientId/encounter-notes'
      user={user}
      patient={patient}
      exact
      component={EncounterNotes}
    />
    <PropsRoute path='/patients/:patientId/invoices' user={user} patient={patient} exact component={Invoices}/>
    {/*<PropsRoute path='/patients/:patientId/payments' user={user} patient={patient} exact component={Payments}/>*/}
    <PropsRoute path='/patients/:patientId/files' user={user} patient={patient} exact
                component={Files}/>
    <PropsRoute path='/patients/:patientId' user={user} patient={patient} exact
                component={(props) => (<Redirect to={`/patients/${props.match.params.patientId}/basic`}/>)}/>
  </Switch>
);

export default withRouter(PatientDetailRoutes);