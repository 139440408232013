import React, {Component} from 'react';

class FileInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file:null
    }
  }

  onSelectFileClicked = (e) => {
    e.preventDefault();
    this.fileInput.click();
  };

  onFileAdded = (e) => {
    e.preventDefault();
    const {input, onFileAdded} = this.props;
    const files = e.target.files;
    this.setState({
      file: files[0]
    }, () => {
      if(input && input.onChange)
        input.onChange(files[0]);
      if(onFileAdded)
        onFileAdded(files[0]);
    })
  };

  render() {
    const {className, required, name, buttonCaption, disabled} = this.props;

    return (
      <div className={className} style={{display: "flex", justifyContent: "space-between",alignItems: "center", fontSize:"1.3rem"}}>
        <span style={{marginRight:"2rem"}}>{this.state.file ? this.state.file.name : "Click the button to Add a File"}</span>
        <input
          ref={(el)=>this.fileInput=el}
          type="file"
          onChange={this.onFileAdded}
          name={name}
          style={{display:"none"}}
          required={required||false} />

        <button
          // style={buttonStyle || defaultButtonStyle}
          onClick={this.onSelectFileClicked}
          type="button"
          className="dashboard__secondary-btn"
          disabled={disabled}
          data-tooltip="Click here to upload a File">
          <i className="fa fa-upload" style={{color:"inherit"}}/>
          {(` ${buttonCaption || "Upload"}`)}
        </button>
      </div>
    );
  }
}

export default FileInput