import React from 'react';

class Accordion extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open:props.open || false
    }
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    })
  };

  render(){
    const {open} = this.state;
    const {title, content, component} = this.props;
    return (
      <div className={`dashboard-card accordion ${open ? 'open' : ''}`} style={{marginBottom: "1rem", height:"auto",padding:"1rem"}}>
        <div className="header" onClick={this.toggleOpen}>{title}</div>
        <div className="body" >{component ? (React.createElement(component)) : content}</div>
      </div>
    )
  }
}

export default Accordion;