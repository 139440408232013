import React from 'react';
import {reduxForm, Field} from 'redux-form';
import {STAGEDATA} from "../../services/utilityService";

let UpdateStageForm = (props) => {

  const {handleSubmit, pristine, submitting, exclude} = props;

  return (
    <div className="dashboard-form">
      <form onSubmit={handleSubmit}>
        <span className="dashboard-form-item">
          <Field component="select" name="stage_id" required className="dashboard-form__input dashboard-form__select">
            <option>Please select the next stage for this Patient</option>
            {Object.keys(STAGEDATA.stages)
              .filter(key => exclude.indexOf(STAGEDATA.stages[key]) === -1)
              .map(key => (
                <option key={STAGEDATA.stages[key]} value={STAGEDATA.stages[key]}>{STAGEDATA.getStageName(STAGEDATA.stages[key])}</option>
              ))}
          </Field>
        </span>
        <button className="dashboard__primary-btn dashboard-form__btn" type="submit" disabled={pristine || submitting}>SUBMIT</button>
      </form>
    </div>
  )
};


UpdateStageForm = reduxForm({
  form: 'updateStageForm'
})(UpdateStageForm);

export default UpdateStageForm