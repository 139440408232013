import React, { Component } from "react";
import moment from "moment";
import { ApiService, ENDPOINTS } from "../../services/ApiService";
import { withRouter } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";
import PageBody from "../boxes/PageBody";
import {
  toastBanner,
  PERMISSIONS,
  STAGEDATA,
} from "../../services/utilityService";
import RestrictedComponent from "../utils/RestrictedComponent";
import DataTable from "../boxes/DataTable";
import { patientEncounter } from "../../services/tableColumnData";
class Encounters extends Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading: false,
      encounters: [],
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setLoading(true);
    this.service
      .get(ENDPOINTS.encounters.base, { patient_id: params.patientId })
      .then((response) => {
        this.setState({
          isLoading: false,
          encounters: response.data,
        });
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  renderLoading = () => (
    <PageBody>
      <LoadingScreen />
    </PageBody>
  );

  showTableDetail = (id) => {
    return this.props.history.push(`/encounters/${id}`);
  };

  render() {
    const { isLoading, encounters } = this.state;
    const {
      match: { params },
    } = this.props;
    const { user } = this.props;

    if (isLoading) return this.renderLoading();

    return (
      <div className="patient-profile">
        <div className="dashboard-card">
          <div className="dashboard-control">
            {/*<div className="dashboard-btn-section search-section">*/}
            {/*<input className="dashboard-form__input search-section" type="text" placeholder="&#xf002;  Search Encounter Notes..."/>*/}
            {/*</div>*/}
            {/*<button className="dashboard__secondary-btn">*/}
            {/*<i className="fas fa-plus" style={{marginRight: "4px"}}/>&nbsp;*/}
            {/*<Link to={`/patients/${this.props.match.params.patientId}/encounter-notes/new`}>Add New Encounter Note</Link>*/}
            {/*</button>*/}
          </div>
          <RestrictedComponent
            displayErrorMessage={true}
            errorComponent={() => (
              <div className="dashboard-card">
                <div style={{ position: "relative", height: "10rem" }}>
                  <p className="dashboard-card__body-text">
                    You don't have permission to view this.
                  </p>
                </div>
              </div>
            )}
            condition={
              this.props.user.permissions.indexOf(
                PERMISSIONS.encounter.read_encounter
              ) > -1
            }
          >
            <div className="dashboard-card dashboard-table-wrap">
              <DataTable
                dataSource={{
                  url: `${ENDPOINTS.encounters.patient(params.patientId)}`,
                  accessToken: user.credentials.accessToken,
                }}
                tableColumnData={patientEncounter}
                showTableDetail={this.showTableDetail}
              />
            </div>
          </RestrictedComponent>
        </div>
      </div>
    );
  }
}

export default withRouter(Encounters);
