import React from 'react';
import { connect } from 'react-redux';
import { arrayPush } from 'redux-form';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import LoadingScreen from '../LoadingScreen';
import Modal from '../boxes/Modal';
import AddProcedureForm from "../forms/AddProcedureForm";
import { toastBanner, TARIFF_TYPES} from "../../services/utilityService";

const CreateProcedureModal = (props) => (
  <Modal title="Add Procedure" onClose={props.onClose}>
    <React.Fragment>
      <CreateProcedureComponent {...props} />
    </React.Fragment>
  </Modal>
);

class CreateProcedureComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading:false,
      procedureList: [],
      policyId: null,
    }
  }

  componentDidMount(){
    const { patient } = this.props;
    
    if(patient.policies.length === 0){
      this.setPolicyId("1");
    } 
  }

  getProcedureList = (policyId) => {
    const { dispatch } = this.props; 

    this.setLoading(true);
    this.service.get(ENDPOINTS.utilities.tariffs, {policy_id: policyId, type_id: TARIFF_TYPES.procedures})
      .then(response => {
        this.setState({
          isLoading: false,
          procedureList: response.data
        }, () => dispatch(arrayPush('addProcedureForm', 'procedures', {quantity:1})))
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  };

  setPolicyId = (policyId) => {
    this.setState({
      policyId
    }, () => {
      this.getProcedureList(policyId);
    })
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );

  onSaveProcedure = (values) => {
    const { encounterId, onProcedureAdded, onSaveProcedureAdded } = this.props;

    const procedure = {
      create_invoice: values.create_invoice || false,
      procedures: values.procedures.map(m => ({
        id: m.procedure.id,
        summary: m.summary,
        name: m.procedure.name,
        price: m.procedure.price,
        quantity: m.quantity
      }))
    };

    this.setLoading(true);

    if(onSaveProcedureAdded){
      onSaveProcedureAdded({...procedure})
    }

    if(onProcedureAdded){
      this.service.post(ENDPOINTS.encounters.procedures(encounterId), procedure)
        .then(response => {
          if(onProcedureAdded)
          onProcedureAdded({...procedure, id: response.data.id});
        })
        .catch((error) => {
          toastBanner(error.message || "An error occured!");
        });
    }      
  };

  render(){
    const {patient, onSaveProcedureAdded, onProcedureAdded} = this.props;
    const {policyId, procedureList, isLoading} = this.state;

    if(!patient || isLoading) return this.renderLoading();

    return (
      <div className="dashboard-form">
        {policyId ? (
          <AddProcedureForm
            procedures={procedureList}
            onSubmit={this.onSaveProcedure}
            onSaveProcedureAdded={onSaveProcedureAdded}
            onProcedureAdded={onProcedureAdded}/>
        ) : (
          <div className="dashboard-form-item-wrapper">
            <div className="dashboard-input-wrap profile-input-wrap">
              <span className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  Select Patient Policy
                </label>
                <select
                  name="create_invoice"
                  className="dashboard-form__input dashboard-form__select"
                  onChange={(e) => this.setPolicyId(e.target.value)}>
                  <option>Select Policy</option>
                  <React.Fragment>
                    <option value="1">Walk In</option>
                    {patient.policies
                      .map(policy => (
                      <option key={policy.policy_id} value={policy.policy_id}>{policy.policy}</option>
                    ))}
                  </React.Fragment>
                </select>
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }
}

CreateProcedureComponent = connect()(CreateProcedureComponent);

export default CreateProcedureModal;