import React from 'react';
import {Link, Route} from 'react-router-dom';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

const SideBarMenuItem = (props) => (
    <Route path={props.to} exact children={({match}) => (
        <li className={`dashboard__menu ${match ? "menu-active":""} ${props.customClass ? props.customClass : ''}`}>
            <i className={props.icon}/>
            <div className="sidebar-title__container">
                <Link to={props.to}>{props.title}
                <br />
                <p className="sidebar-link">{props.link}</p>
                </Link>
               
            </div>
            
        </li>
    )}/>
);

export default withRouter(connect()(SideBarMenuItem))