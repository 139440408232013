import React from "react";
import { reduxForm, Field, change } from "redux-form";
import moment from 'moment';
import DateTime from "react-datetime";
import "./datepicker.css";

class NPSForm extends React.Component {
  
  onDateSet = (date) => {
    const {dispatch} = this.props;
    dispatch(change('NPSForm', 'encounter_date', moment(date).format("YYYY-MM-DD")));
  }

  render() {
    const { onCancel, onSubmit, handleSubmit, isLoading, renderLoading,  pristine, submitting } = this.props;

    if (isLoading) return renderLoading();

    return (
      <form className="dashboard-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="dashboard-card">
        <h1 className="dashboard-card__title-text">Send Feedback Form</h1>
        <div className="dashboard-form-item-wrapper">
          <div className="dashboard-input-wrap profile-input-wrap">
            <span className="dashboard-form-item">
              <label className="profile-item__label" htmlFor="name">
                Enter Recipient Name
              </label>
              <Field
                component="input"
                className="dashboard-form__input"
                type="text"
                placeholder="Enter Recipient Name"
                name="name"
                required
              />
            </span>

            <span className="dashboard-form-item">
              <label className="profile-item__label" htmlFor="email">
                Enter Email Address
              </label>
              <Field
                component="input"
                className="dashboard-form__input"
                type="text"
                placeholder="Enter Email Address"
                name="email"
                required
              />
            </span>
            <span className="dashboard-form-item">
              <label className="profile-item__label" htmlFor="service type">
                Select Service Type
              </label>
              <Field
                component="select"
                name="encounter_stage"
                className="dashboard-form__input dashboard-form__select"
                required
              >
                <option value="">Please select</option>
                <option value="2">Front Desk Officer</option>
                <option value="3">Triage Officer</option>
                <option value="4">Physician</option>
                <option value="5">Pharmacist</option>
              </Field>
            </span>
            <span className="dashboard-form-item">
              <label className="profile-item__label" htmlFor="encounter date">
                Encounter Date
              </label>
              <Field component="input" type="hidden" name="encounter_date" placeholder="Date of Encounter" required/>
                <DateTime
                  closeOnSelect
                  viewMode="years"
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                  inputProps={{required: true, placeholder: "YYYY-MM-DD", readOnly: true, className:"dashboard-form__input"}}
                  onChange={this.onDateSet}
                  isValidDate={(curr) => curr.isBefore()}
                  required
                  />
            </span>

            <div style={{ display: "flex", flexDirection: "flex-start" }}>
              <button
                className="dashboard__primary-btn dashboard-form__btn"
                disabled={pristine||submitting}
                type="submit"
              >
                Send Form
              </button>
              <button
                className="dashboard__secondary-btn dashboard-form__btn"
                type="button"
                disabled={pristine||submitting}
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        </div>
      </form>
    );
  }
}

NPSForm = reduxForm({
  form: "NPSForm",
  destroyOnUnmount: true,
})(NPSForm);

export default NPSForm;
