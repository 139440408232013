import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import { Field, reduxForm, change, formValueSelector, reset } from 'redux-form';
import  { toastBanner } from "../../services/utilityService";
import DateTime from 'react-datetime';
import './datepicker.css';
import MainContent from "../boxes/MainContent";
import PageBody from "../boxes/PageBody";
import LoadingScreen from "../LoadingScreen";

class PatientForm extends Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      policies: [],
      isValidating: false,
      validatedEnrollee: null
    }
  };

  componentDidMount() {
    const { user, dispatch, patient } = this.props;
    if(!_.isEmpty(user)){
      //create new patient from user
      dispatch(change('newPatientForm', 'first_name', user.first_name));
      dispatch(change('newPatientForm', 'last_name', user.last_name));
      dispatch(change('newPatientForm', 'email_address', user.email_address));
      dispatch(change('newPatientForm', 'phone_number', user.phone_number));
    }else if(patient){
      //edit patient
      dispatch(change('newPatientForm', 'first_name', patient.first_name));
      dispatch(change('newPatientForm', 'last_name', patient.last_name));
      dispatch(change('newPatientForm', 'email_address', patient.email_address));
      dispatch(change('newPatientForm', 'address', patient.address));
      dispatch(change('newPatientForm', 'phone_number', patient.phone_number));
      dispatch(change('newPatientForm', 'date_of_birth', patient.date_of_birth));
      dispatch(change('newPatientForm', 'gender', patient.gender));
    }

    this.service.get(ENDPOINTS.utilities.policies)
      .then(response => {
        this.setState({
          policies: [...response.data]
        });

        let policyData = null;
        if((!_.isEmpty(user) && user.policies.length > 0)){
          policyData = user.policies[0];
          policyData.hidePolicyData = true;
        }else if(patient && patient.policies.length > 0){
          policyData = patient.policies[0];
          dispatch(change('newPatientForm', 'policy_id', policyData.policy_id));
          dispatch(change('newPatientForm', 'policy_code', policyData.code));
        }

        this.setState({
          policyData
        });
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      })
  }

  validateEnrolleeId = () => {
    const { dispatch, enrolleeId } = this.props;
      this.setLoading(true);

      if(enrolleeId && enrolleeId.length > 0) {
        this.service.post(ENDPOINTS.patients.validateId, { username: enrolleeId, hide_response: false })
          .then((response) => {
            this.setLoading();

            this.setState({
              validatedEnrollee: response.data
            })

            if(response.data.user) {
              toastBanner("Enrollee Found!", "success");
              let user = response.data.user;
              dispatch(change('newPatientForm', 'first_name', user.first_name));
              dispatch(change('newPatientForm', 'last_name', user.last_name));
              dispatch(change('newPatientForm', 'email_address', user.email_address));
              dispatch(change('newPatientForm', 'phone_number', user.phone_number));
              dispatch(change('newPatientForm', 'address', `${user.address.address},${user.address.state.name}`));
              dispatch(change('newPatientForm', 'date_of_birth', user.date_of_birth));
              dispatch(change('newPatientForm', 'gender', user.gender));              
            } else {
              toastBanner("The Enrollee Id does not exist!");

              dispatch(change('newPatientForm', 'first_name', ""));
              dispatch(change('newPatientForm', 'last_name', ""));
              dispatch(change('newPatientForm', 'email_address', ""));
              dispatch(change('newPatientForm', 'phone_number', ""));
            }
          })
          .catch((error) => {
            toastBanner(error.message || "An error occured!");
          });
      } else {
        this.setLoading();

        dispatch(reset("newPatientForm"));
      }
  }

  componentWillUnmount(){
    const { dispatch } = this.props;
    
    if(this.service.cancel &&  this.service.cancel instanceof Function)
      this.service.cancel();
    
    dispatch(reset("newPatientForm"));
  }

  onDateSet = (date) => {
    const {dispatch} = this.props;
    dispatch(change('newPatientForm', 'date_of_birth', moment(date).format("YYYY-MM-DD")));
  };

  renderLoading = () => (
    <MainContent>
      <PageBody>
        <LoadingScreen />
      </PageBody>
    </MainContent>
  );

  setLoading = (loading=false) => {
    this.setState({
      isValidating: loading
    });
  };

  render() {
    const {user, dateOfBirth, handleSubmit, onCancel} = this.props;
    const { policies, policyData, isValidating } = this.state;

    return (
      <React.Fragment>
        <form className="dashboard-form" onSubmit={handleSubmit}>
          {
            !_.isEmpty(user) ?
            ((!policyData || (policyData && !policyData.hidePolicyData)) &&
              <div
                className={`dashboard-card ${(policyData && policyData.hidePolicyData) ? 'hidden' : ''}`}
                style={{marginBottom:"2rem"}}>
                <h1 className="dashboard-card__title-text">Insurance Information</h1>
                <div className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                    Insurer <span> (optional)</span>
                  </label>
                  <Field component="select" className="dashboard-form__input dashboard-form__select" name="policy_id">
                    <option>Please select an Insurer</option>
                    {policies
                      .filter(policy => policy.id !== 1)
                      .map(policy => (
                        <option key={policy.id} value={policy.id}>{policy.name}</option>
                      ))}
                  </Field>
                </div>

                <div className="dashboard-form-item">
                  <label className="profile-item__label" htmlFor="">
                    Policy Number <span> (optional)</span>
                  </label>
                  <Field component="input" className="dashboard-form__input" type="text" placeholder="Enter policy no." name="policy_code"/>
                </div>
              </div>)
              :
              <div
                className="dashboard-card"
                style={{marginBottom:"2rem"}}>
                <h1 className="dashboard-card__title-text">Insurance Information</h1>
                <div className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                    Enrollee ID:  <span> (optional)</span>
                  </label>
                  <div style={{display: "flex"}}>
                    <Field 
                      component="input" 
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          this.validateEnrolleeId();
                        }                      
                      }}
                      className="dashboard-form__input" type="text" placeholder="Enter enrollee id here" name="enrollee_id" style={{marginTop:"0"}}/>
                    <button 
                      className="dashboard__secondary-btn" 
                      style={{marginLeft:"1rem"}}
                      onClick={(e) => {
                        e.preventDefault();
                        this.validateEnrolleeId();
                      }}>
                        Search
                    </button>  
                  </div>
                </div>
              </div>
          }
          {
            isValidating ? 
            this.renderLoading()
            :
            <React.Fragment>
            <div className="dashboard-card" style={{marginBottom:"2rem"}}>
              <h1 className="dashboard-card__title-text">Basic Information</h1>
              <div className="dashboard-form-item-wrapper">
                <div className="dashboard-input-wrap profile-input-wrap">
                  <span className="dashboard-form-item" style={{ marginRight: "10px"}}>
                    <label
                      className="profile-item__label"
                      htmlFor="">
                      First Name <span style={{color : "red"}}>*</span>
                    </label>
                    <Field
                      component="input"
                      className="dashboard-form__input"
                      placeholder="Enter first name"
                      name="first_name"
                      readOnly={!!user.first_name}/>
                  </span>
                  <span className="dashboard-form-item">
                    <label
                      className="profile-item__label"
                      htmlFor="">
                      Gender <span style={{color : "red"}}>*</span>
                    </label>
                    <Field component="select" className="dashboard-form__input dashboard-form__select" name="gender">
                      <option>Please select a gender</option>
                      <option value="m">Male</option>
                      <option value="f">Female</option>
                    </Field>
                  </span>
                </div>
                <div className="dashboard-input-wrap profile-input-wrap">
                  <span className="dashboard-form-item">
                    <label
                      className="profile-item__label"
                      htmlFor="">
                      Last Name <span style={{color : "red"}}>*</span>
                    </label>
                    <Field
                      component="input"
                      className="dashboard-form__input"
                      type="text"
                      placeholder="Enter last name"
                      name="last_name"
                      readOnly={!!user.last_name}/>
                  </span>
                  <span className="dashboard-form-item">
                    <label
                      className="profile-item__label"
                      htmlFor="">
                      Date of Birth <span style={{color : "red"}}>*</span>
                    </label>
                    <Field component="input" type="hidden" name="date_of_birth" placeholder="Date of Birth" required/>
                    <DateTime
                      closeOnSelect
                      viewMode="years"
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                      inputProps={{required: true, placeholder: "YYYY-MM-DD", readOnly: true, className:"dashboard-form__input"}}
                      onChange={this.onDateSet}
                      isValidDate={(curr) => curr.year() <= DateTime.moment().year()}
                      value={dateOfBirth}/>
                  </span>
                </div>
              </div>
            </div>
            <div className="dashboard-card" style={{marginBottom:"2rem"}}>
              <h1 className="dashboard-card__title-text">Contact Information</h1>
              <div className="dashboard-form-item-wrapper">
                <div className="dashboard-input-wrap profile-input-wrap">
                  <span className="dashboard-form-item">
                    <label
                      className="profile-item__label"
                      htmlFor="">
                      Email Address <span> (optional)</span>
                    </label>
                    <Field
                      component="input"
                      className="dashboard-form__input"
                      type="text"
                      placeholder="Enter email address"
                      name="email_address"
                      readOnly={!!user.email_address}/>
                  </span>
                </div>
                <div className="dashboard-input-wrap profile-input-wrap">
                  <span className="dashboard-form-item">
                    <label className="profile-item__label" htmlFor="">
                      Phone Number <span style={{color : "red"}}>*</span>
                    </label>
                    <Field
                      component="input"
                      className="dashboard-form__input"
                      type="text"
                      name="phone_number"
                      placeholder="Enter phone no."
                      readOnly={!!user.phone_number}/>
                  </span>
                </div>
              </div>
              <div className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  Address <span style={{color : "red"}}>*</span>
                </label>
                <Field
                  component="textarea"
                  className="dashboard-form__input"
                  name="address"
                  placeholder="Patient's Address"/>
              </div>
            </div>
          </React.Fragment>
          }
          
          <div className="dashboard-card" style={{marginBottom:"2rem"}}>
            <Field component="input" type="hidden" name="user_id"/>
            <div style={{display:"flex",flexDirection:"flex-start"}}>
              <button type="submit" className="dashboard__primary-btn dashboard-form__btn">Save Patient Info</button>
              <button onClick={onCancel} className="dashboard__secondary-btn dashboard-form__btn">Cancel</button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

PatientForm = reduxForm({
  form: 'newPatientForm',
  destroyOnUnmount: false
})(PatientForm);

const selector = formValueSelector('newPatientForm');
PatientForm = connect(state => {
  // can select values individually
  const dateOfBirth = selector(state, 'date_of_birth');
  const enrolleeId = selector(state, 'enrollee_id')

  return {
    dateOfBirth,
    enrolleeId
  }
})(PatientForm);

export default PatientForm;