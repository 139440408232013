import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import DataTable from "../components/boxes/DataTable";
import { ENDPOINTS } from '../services/ApiService';
import { PERMISSIONS } from "../services/utilityService";
import { NPSColumnData } from "../services/tableColumnData";
import RestrictedComponent from "../components/utils/RestrictedComponent";

class EncounterContainer extends Component {
  render() {
    const { user } = this.props;

    return (
      <MainContent>
        <PageHeader 
          title="Feedback System" 
          showButton={user.permissions.indexOf(PERMISSIONS.nps.create_nps) > -1}
          buttonTitle="Send New Feedback Form"
          onButtonClick={() => this.props.history.push("/nps-feedback/new")}
        />
          
        <PageBody>
          <RestrictedComponent
            displayErrorMessage={true}
            errorComponent={() => (
              <div className="dashboard-card">
                <div style={{position:"relative", height:"10rem"}}>
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                </div>
              </div>
            )}
            condition={user.permissions.indexOf(PERMISSIONS.nps.read_nps) > -1}>

            <div className="dashboard-card dashboard-table-wrap">
              <DataTable
                dataSource={{
                  url:`${ENDPOINTS.nps.base}`,
                  accessToken: user.credentials.accessToken
                }}
                tableColumnData={NPSColumnData}
              />
            </div>
          </RestrictedComponent>
        </PageBody>
      </MainContent>
    );
  }
}

export default withRouter(EncounterContainer);