import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import { ENDPOINTS } from '../services/ApiService';
import { patientsColumnData } from "../services/tableColumnData";
import DataTable from "../components/boxes/DataTable";

class PatientContainer extends Component {
  showTableDetail = (id) => {
    return this.props.history.push(`/patients/${id}`)
  }

  render() {
    const { user } = this.props;

    return (
      <MainContent>
        <PageHeader 
          title="Patients"
          showButton
          buttonTitle="Add New Patient"
          onButtonClick={() => this.props.history.push("/patients/new")}
        />
        <PageBody>
          <div className="dashboard-card dashboard-table-wrap">
            <DataTable
              canSearchTableList
              searchInputPlaceholder="&#xf002; Enter Patient's Name or RFC ID and Press Enter"
              dataSource={{
                url:`${ENDPOINTS.patients.base}`,
                accessToken: user.credentials.accessToken
              }}
              tableColumnData={patientsColumnData}
              showTableDetail={this.showTableDetail}
            />
          </div>
        </PageBody>
      </MainContent>
    );
  }
}

export default withRouter(PatientContainer);