import * as React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import UserService from "../../services/userService";
import { toastBanner } from "../../services/utilityService";
import DateTime from "react-datetime";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";
import LOGO from "../../img/logo.svg";
import userService from "../../services/userService";

let ValidateAccountForm = (props) => {
  const [verificationStatus, setVerificationStatus] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const url = new URL(window.location.href);
  const tokenParam = url.searchParams.get("token");
  const userEmail = url.searchParams.get("email");

  React.useEffect(() => {
    setIsLoading(true);
    UserService.verifyAccount({
      token: tokenParam,
      email_address: userEmail,
    }).then(
      (response) => {
        setIsLoading(false);
        setVerificationStatus(true);
      },
      (err) => {
        setIsLoading(false);
        setVerificationStatus(false);
      }
    );
  }, [tokenParam, userEmail]);

  const onDateSet = (date) => {
    const { dispatch } = props;
    dispatch(
      change("validateAccountForm", "date", moment(date).format("YYYY-MM-DD"))
    );
  };

  const verifyAccountDetails = (values) => {
    let data = { email_address: userEmail };
    if (values.validateMethod === "digits") {
      data = {
        ...data,
        security_question: "phone_number",
        answer: values.phoneNumber,
      };
    } else {
      data = { ...data, security_question: "date_of_birth", answer: values.date };
    }
    setIsLoading(true);
    userService
      .sendVerificationDetails(data)
      .then((response) => {
        setIsLoading(false);
        toastBanner(`Account succesfully verified`, "success");
        setTimeout(() => (window.location.href = `/change-password?email=${userEmail}`), 2000);
      })
      .catch((err) => {
        setIsLoading(false);
        toastBanner(`${err && err.response && err.response.data?.message || "An error occured!"}`);
      });
  };

  const renderLoading = () => (
    <div className="loader">
      <LoadingScreen />
    </div>
  );

  const { handleSubmit, pristine, submitting, validateMethod, date, phoneNumber } = props;
  const checkFormFields = validateMethod === "digits" ? phoneNumber : date;

  return (
    <>
      {isLoading ? (
        renderLoading()
      ) : (
        <div className="verify-account-container">
          <div className="verify-account-form-wrapper">
            <div className="form_logo">
              <Link to={"/"}>
                <img src={LOGO} alt="Reliance HMO's Logo" />
              </Link>
            </div>
            {!verificationStatus ? (
              <div>
                <h1 className="verify-expired-text">
                  The link you are trying to access has expired.
                </h1>
              </div>
            ) : (
              <div className="login_form" style={{ justifyContent: "unset" }}>
                <div
                  style={{
                    padding: "10px",
                    backgroundColor: "red",
                    color: "white",
                    visibility:
                      !props.isLoading && props.message ? "visible" : "hidden",
                  }}
                >
                  {props.message}
                </div>
                <div className="form_wrapper">
                  <div className="form dashboard-form">
                    <form onSubmit={handleSubmit(verifyAccountDetails)}>
                      <div className="dashboard-form-item">
                        <p className="verify-account__header--small">
                          Enter your Date of Birth or last six digits of your
                          phone number to validate your account
                        </p>
                        <div
                          className="dashboard-form__checklist-wrap"
                          style={{ alignItems: "start" }}
                        >
                          <div>
                            <Field
                              component="input"
                              type="radio"
                              name="validateMethod"
                              value="dateofbirth"
                              required
                              style={{ width: "2rem" }}
                            />
                            <label
                              htmlFor="validateMethod"
                              className="verify-account-form__label"
                            >
                              {" "}
                              Date of Birth
                            </label>
                          </div>
                          <div>
                            <Field
                              component="input"
                              type="radio"
                              name="validateMethod"
                              value="digits"
                              style={{ width: "2rem" }}
                              required
                            />
                            <label
                              htmlFor="validateMethod"
                              className="verify-account-form__label"
                            >
                              Last Six digits of your phone number
                            </label>
                          </div>
                        </div>
                      </div>
                      {validateMethod === "dateofbirth" && (
                        <div className="dashboard-form-item">
                          <Field
                            component="input"
                            type="hidden"
                            name="date"
                            required
                          />
                          <DateTime
                            closeOnSelect
                            viewMode="years"
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                            inputProps={{
                              required: true,
                              placeholder: "YYYY-MM-DD",
                              readOnly: true,
                              className: "dashboard-form__input",
                            }}
                            onChange={onDateSet}
                            isValidDate={(curr) => curr.isBefore()}
                            required
                          />
                        </div>
                      )}

                      {validateMethod === "digits" && (
                        <div className="dashboard-form-item">
                          <Field
                            component="input"
                            type="tel"
                            name="phoneNumber"
                            placeholder="last 6 digits"
                            pattern="[0-9]{6}"
                            minLength="6"
                            maxLength="6"
                            required
                          />
                        </div>
                      )}
                      <button
                        type="submit"
                        disabled={pristine || submitting || !checkFormFields }
                      >
                        Reset Password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            )}
            <Link className="verify-back-to-login" to={"/login"}>
              Back to Login
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

ValidateAccountForm = reduxForm({
  form: "validateAccountForm",
})(ValidateAccountForm);

const selector = formValueSelector("validateAccountForm");

ValidateAccountForm = connect((state) => ({
  validateMethod: selector(state, "validateMethod"),
  date: selector(state, "date"),
  phoneNumber: selector(state, "phoneNumber"),
}))(ValidateAccountForm);

export default ValidateAccountForm;
