import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import PropsRoute from "../components/utils/PropsRoute";
import NPSFeedbackContainer from "../containers/NPSFeedbackContainer";
import NewNPSContainer from "../containers/NewNPSContainer";

class NPSRoutes extends React.Component {
  render () {
    const {user} = this.props;
    return (
      <Switch>
        <PropsRoute path='/nps-feedback/new' user={user} exact component={NewNPSContainer}/>
        <PropsRoute path='/nps-feedback' user={user} component={NPSFeedbackContainer}/>
      </Switch>
    )
  }
}

export default withRouter(NPSRoutes);