import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import PropsRoute from "../components/utils/PropsRoute";
import EncounterContainer from "../containers/EncounterContainer";
import EncounterDetailContainer from "../containers/EncounterDetailContainer";
import NewInvoiceContainer from '../containers/NewInvoiceContainer';

class EncountersRoutes extends React.Component {
  render () {
    const {user} = this.props;
    return (
      <Switch>
        <PropsRoute path='/encounters/:encounterId' user={user} component={EncounterDetailContainer} exact/>
        <PropsRoute path='/encounters/:encounterId/new-invoice/:patientId' user={user} component={NewInvoiceContainer} exact/>
        <PropsRoute path='/encounters' user={user} component={EncounterContainer} exact/>
      </Switch>
    )
  }
}

export default withRouter(EncountersRoutes);