import React from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, FieldArray, formValueSelector} from 'redux-form';
import FilterableSelect from "../inputs/FilterableSelect";

const AddServiceForm = ({handleSubmit, services, onServiceAdded, onSaveServiceAdded}) => {
  return (
    <form className="dashboard-form" onSubmit={handleSubmit}>
      { 
        (!onSaveServiceAdded && onServiceAdded) &&
          <span className="dashboard-form-item">
            <label
              className="profile-item__label"
              htmlFor="">
              Create Invoice?
            </label>
            <Field
              component="select"
              name="create_invoice"
              className="dashboard-form__input dashboard-form__select"
              normalize={value => value === 'true'}>
              <option>Please select</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Field>
          </span>
      }

      <span className="dashboard-form-item" style={{margin:"2rem 0"}}>
        <FieldArray name="services" component={renderServiceFields} props={{services}}/>
      </span>

      <div style={{display:"flex",flexDirection:"flex-start"}}>
        <button className="dashboard__primary-btn dashboard-form__btn">Save Service</button>
      </div>
    </form>
  )
};

let ServiceEntryField = ({services, service, index, onRemove, selectedService}) => (
  <li>
    <div style={{display:"flex", alignItems:"center", margin:"2rem 0"}}>
      <span className="dashboard-form-item" style={{width:"auto"}}>
        <label className="profile-item__label" htmlFor="">
          Service #{index+1}
        </label>
        <Field
          name={`${service}.service`}
          className="filterable-select search-section embedded"
          component={FilterableSelect}
          props={
            {
              items: services,
              placeholder:"Enter service name...",
              filterBy:["name"]
            }
          }/>
      </span>
      {
        selectedService &&
          <React.Fragment>
            <span className="dashboard-form-item" style={{width:"auto"}}>
              <label className="profile-item__label" htmlFor="">
                Summary
              </label>
              <Field
                component="input"
                style={{width:"auto"}}
                name={`${service}.summary`}
                className="dashboard-form__input"
                type="text"
                placeholder="Summary"/>
            </span>
            <span className="dashboard-form-item" style={{width:"auto"}}>
              <label className="profile-item__label" htmlFor="">
                Unit Price
              </label>
              <div
                style={{width:"auto"}}
                className="dashboard-form__input">
                {selectedService.price || "0.00"}
              </div>
            </span>
            <span className="dashboard-form-item" style={{marginLeft: "1rem", width:"auto"}}>
              <label className="profile-item__label" htmlFor=""> Delete </label>
              <button className="dashboard__secondary-btn" onClick={onRemove}>
                <label className="profile-item__label" htmlFor="">
                  <i className="fas fa-trash"/>
                </label>
              </button>
            </span>
          </React.Fragment>
      }
    </div>
  </li>
);

const selector = formValueSelector('addServiceForm');

ServiceEntryField = connect(
  (state, props) => ({
    selectedService:  selector(state, `${props.service}.service`)
  })
)(ServiceEntryField);

const renderServiceFields = ({fields, services}) => (
  <ul>
    {fields.map((service, index) => (
      <ServiceEntryField
        key={index}
        index={index}
        service={service}
        services={services}
        onRemove={() => fields.remove(index)}/>
    ))}
    <li>
      <button
        className="dashboard__secondary-btn"
        type="button"
        onClick={() => fields.push({quantity:1})}>
        {`${fields.length > 0 ? "Add Another Service" : "Click to add a Service"} `}
      </button>
    </li>
  </ul>
);

export default reduxForm({
  form: "addServiceForm"
})(AddServiceForm);
