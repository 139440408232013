import React from 'react';
import {connect} from 'react-redux';
import {DISPLAY_ACTION_TYPES} from '../../actions/actionTypes';

class PageHeader extends React.Component {
  toggleSideBar = () => {
    const {dispatch} = this.props;
    dispatch({type: DISPLAY_ACTION_TYPES.TOGGLE_SIDEBAR});
  };

  updateSearchInput = (event) => {
    return this.props.handleSearch(event)
  }

  render() {
    //const {title, isSearchable, hasChatButton} = this.props;
    const {title, showButton, onButtonClick, buttonTitle, showSearchBar, searchBarPlaceholder} = this.props;

    document.title = `RFC | ${title}`;

    return (
      <div className="top-right content">
        <div className="header">
          <div className="header__left">
            <button className="toggle-nav" onClick={this.toggleSideBar}>&#9776;&nbsp;</button>
          </div>
          <div className="header__title">
            <h2>{title}</h2>
          </div>
          <div className="header__right">
            {
              showSearchBar &&
              <div className="header__search">
                <input type="text" name="search-bar"
                       onChange={this.updateSearchInput}
                       placeholder={searchBarPlaceholder}/>
              </div>
            }

            {
              showButton &&
              <div>
                <button onClick={onButtonClick}>{buttonTitle}</button>
              </div>
            }

            {false && <div className="header__notif">
              <button className="badge notif" data-badge="1" aria-hidden="true">
                <i className="fa fa-bell fa-2"/>
              </button>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}


export default connect()(PageHeader)