import {STAGES_ACTION_TYPES} from './actionTypes';
import {ApiService, ENDPOINTS} from "../services/ApiService";


const getStages = () => {
  return {
    type: STAGES_ACTION_TYPES.GET_STAGES
  }
};

const invalidateStages = () => {
  return {
    type: STAGES_ACTION_TYPES.INVALIDATE
  }
};

const getStagesSuccessful = (stages) => {
  return {
    type: STAGES_ACTION_TYPES.GET_STAGES_SUCCESS,
    data: stages
  }
};

const getStagesFailed = (error) => {
  return {
    type: STAGES_ACTION_TYPES.GET_STAGES_FAIL,
    data: error
  }
};

// export const updateStage = () => {
//   return {
//     type: STAGES_ACTION_TYPES.UPDATE_STAGE
//   }
// };
//
// const updateStageSuccessful = (data) => {
//   return {
//     type: STAGES_ACTION_TYPES.UPDATE_STAGE_SUCCESS,
//     data
//   }
// };
//
// const updateStageFailed = (error) => {
//   return {
//     type: STAGES_ACTION_TYPES.UPDATE_STAGE_FAIL,
//     data: error
//   }
// };

//////////////////// THUNKS  //////////////////////////////

export const loadStages = (accessToken, invalidate) => {
  return (dispatch) => {
    dispatch(getStages());
    if(invalidate){
      dispatch(invalidateStages());
    }
    const service = new ApiService(accessToken);
    service.get(ENDPOINTS.stages.base)
      .then(response => {
        dispatch(getStagesSuccessful(response.data));
      })
      .catch(err => {
        dispatch(getStagesFailed(err));
      })
  }
};
