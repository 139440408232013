import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import TestComponent from "./components/boxes/TestComponent";
import ValidateAccountForm from './components/forms/ValidateAccountForm';
import { withUser } from "./components/utils/AuthHOC";
import Dashboard from "./containers/DashboardContainer";
import LoginContainer from './containers/LoginContainer';
import LogoutContainer from "./containers/LogoutContainer";
import ResetPasswordContainer from "./containers/ResetPasswordContainer";

import './App.css';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <Router history="">
    <div className="content dash">
      <Switch>
        <Route component={LoginContainer} path="/login" exact/>
        <Route component={ResetPasswordContainer} path="/change-password" exact/>
        <Route component={ValidateAccountForm} path="/validate-account" exact/>
        <Route component={TestComponent} path="/test" exact/>
        <Route component={LogoutContainer} path="/logout" exact/>
        <Route component={withUser(Dashboard)} path="/" />
      </Switch>
      <ToastContainer 
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
      />
    </div>
  </Router>
);



export default (App);
