import React from 'react';
import {Field, reduxForm, change} from 'redux-form';

class NewPaymentForm extends React.Component {

  componentDidMount(){
    const {dispatch, invoiceId, amount } = this.props;
    dispatch(change("newPaymentForm", "invoice_id", invoiceId));
    dispatch(change("newPaymentForm", "amount", amount));
  }

  render(){
    const {patient, handleSubmit, pristine, submitting} = this.props;
    return (
      <form className="dashboard-form" onSubmit={handleSubmit}>
        <div className="dashboard-form-item-wrapper">
          <div className="dashboard-input-wrap profile-input-wrap">
            <span className="dashboard-form-item">
              <label
                className="profile-item__label"
                htmlFor="">
                Amount
              </label>
              <Field component="input" className="dashboard-form__input" type="number" placeholder="Enter amount"
                     name="amount"/>
            </span>

            <span className="dashboard-form-item">
              <label
                className="profile-item__label"
                htmlFor="">
                Paid By:
              </label>
              <Field component="select" className="dashboard-form__input dashboard-form__select"
                     name="paid_by">
                  <option value>Please select a person:</option>
                  <option key={patient.id} value={patient.id}>{`${patient.first_name} ${patient.last_name}`}</option>
              </Field>
            </span>

            <span className="dashboard-form-item">
              <label className="profile-item__label" htmlFor="">
                Payment Medium
              </label>
              <Field component="select" className="dashboard-form__input dashboard-form__select" name="payment_medium">
                <option value>Please select a payment medium</option>
                <option value={1}>Cash</option>
                <option value={2}>Card</option>
                <option value={3}>HMO</option>
              </Field>
            </span>
          </div>
        </div>
        <Field component="input" type="hidden" name="invoice_id"/>
        <div style={{display: "flex", flexDirection: "flex-start"}}>
          <button className="dashboard__primary-btn dashboard-form__btn"
                  disabled={pristine || submitting}>Add Payment
          </button>
        </div>
      </form>
    )
  }

}


export default reduxForm({
  form: "newPaymentForm"
})(NewPaymentForm);
