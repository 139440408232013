import React from 'react';
import PropTypes from 'prop-types';

const RestrictedComponent = ({condition, errorComponent, displayErrorMessage, children}) => (
  condition ? (
    children
  ) : (
    displayErrorMessage ? (
      errorComponent ?
        (React.createElement(errorComponent)) : (
          <div className="dashboard-card">
            <div style={{fontSize: "2rem", color: "grey"}}>You don't have permission to view this</div>
          </div>
        )
    ) : (<div>  </div>)
  )
);

RestrictedComponent.propTypes = {
  condition: PropTypes.bool,
  displayErrorMessage: PropTypes.bool
};

export default RestrictedComponent;