import React from 'react';
import { connect } from 'react-redux';
import {Route, withRouter} from 'react-router-dom';
import queryString from 'querystring';
import Sidebar from './Sidebar';
import {DISPLAY_ACTION_TYPES} from '../actions/actionTypes';
import AppRoutes from "../routes/AppRoutes";

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    const {dispatch, location} = props;

    props.history.listen(() => {
      dispatch({type: DISPLAY_ACTION_TYPES.HIDE_SIDEBAR});
    });

    this.state = {
      isLoading: false,
      referrer: queryString.parse(location.search.substr(1)).r || window.location.href
    };
  }

  render() {
    const {user} = this.props;

    return (
      <Route>
        <React.Fragment>
          <Sidebar user={user}/>
          <AppRoutes user={user} authenticated={true}/>
        </React.Fragment>
      </Route>
    );
  }
}


export default withRouter(connect()(Dashboard));