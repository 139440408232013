import React from 'react';
import SidebarMenuItem from '../components/SidebarMenuItem';
import LOGO from '../img/logo-white.svg'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {ROLES, PERMISSIONS} from "../services/utilityService";

const items = [
  {
    path:"/branch",
    icon:"fas fa-map",
    title:"Branch",
    link: "Change Location"
  },
  {
    path:"/overview",
    icon:"fas fa-tachometer-alt",
    title:"Overview"
  },
  {
    path:"/appointments",
    icon:"fas fa-calendar-check",
    title:"Appointments"
  },
  {
    path:"/patients",
    icon:"fas fa-user-friends",
    title:"Patients"
  },
  {
    path:"/encounters",
    icon:"fas fa-comment-dots",
    title:"Encounters"
  },
  {
    path:"/invoices",
    icon:"fas fa-file-alt",
    title:"Invoices"
  },
  {
    path:"/claims",
    icon:"fas fa-file-invoice",
    title:"Claims"
  },
  {
    path:"/nps-feedback",
    icon:"fas fa-question-circle",
    title:"NPS Feedback"
  }

];

// const patientItems = [
//   {
//     path:"/teletherapy",
//     icon:"fas fa-question-circle",
//     title:"Teletherapy"
//   }
// ]

class Sidebar extends React.Component{

 
  
  render(){
    const { showSideBar, user } = this.props;
    let filteredSideBarItems = items;
    if (user.permissions.indexOf(PERMISSIONS.nps.read_nps) < 1) {
      filteredSideBarItems = items.filter((item) =>
        item.title.trim().toLowerCase() !== "nps feedback"
      )
    } 
    
  //  if(user.permissions.length === 0 && user.roles.length === 0) {
  //    filteredSideBarItems = patientItems
  //  }
    
    return (
      <div id="left" className={`column sidebar ${showSideBar ? '' : 'active'}`}>
        <div className="top-left">
          <div className="sidebar__top">
            <img src={LOGO} alt="RelianceHMO's Logo" height="45px"/>
          </div>
        </div>
        <div className="bottom sidebar--space-between sidebar--rfc">
          <nav>
            <ul>
              {filteredSideBarItems.map((item,i)=>(
                <SidebarMenuItem
                  key={i}
                  to={item.path}
                  icon={item.icon}
                  title={item.title}
                  link={item.link}/>
              ))}
              <li className={`dashboard__menu`}>
                <i className="fas fa-sign-out-alt"/>
                <a href="/logout">Logout</a>
              </li>
            </ul>
          </nav>
          <div className="sidebar__bottom">
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <span>
              <h2 className="sidebar__user-name">{`${user.firstName} ${user.lastName}`}</h2>
              <div style={{display:"flex"}}>
              {user.roles.map((role, index) => (
                <div key={index} className="overview-item__tag">
                  <span className="overview-item__small-text">{ROLES.getRoleName(role)}</span>
                </div>
              ))}
            </div>
            </span>

            <button 
              className="badge notif" 
              // data-badge="1" 
              aria-hidden="true"
            >
              <i className="fas fa-bell fa-2x" style={{color: "white"}}/>
            </button>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state){
    const {showSideBar} = state.display||{};
    return {showSideBar};
}

export default withRouter(connect(mapStateToProps)(Sidebar))