import React from 'react';
import { PulseLoader } from 'react-spinners';
import {ApiService, ENDPOINTS} from "../services/ApiService";
import { toastBanner } from "../services/utilityService";

class EncounterCommentForm extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      isSending:false,
      encounterComment: '',
      mode: "display"
    }
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  postEncounterComments = (comment) => {
    const { encounterId, user, onEncounterCommentsAdded } = this.props;

    this.setState({
      isSending: !this.state.isSending,
    });

    this.service.post(ENDPOINTS.encounters.comments(encounterId), { comment }).then((response) => {
      let newNote = {
        id: response.data.comment_id,
        user_id: user.id,
        comment,
        created_by: `${user.firstName} ${user.lastName}`,
        created_at: new Date()
      };

      onEncounterCommentsAdded(newNote);
      toastBanner("Encounter comment added successfully!", "success");
      this.setState({
        isSending: !this.state.isSending,
      });
    })
    .catch((error) => {
      toastBanner(error.message || "An error occured!");
    });
  };

  handleChange = (event) => {
    this.setState({
      encounterComment: event.target.value
    })
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.postEncounterComments(this.state.encounterComment);
    this.setState({
      encounterComment: ''
    })
  };

  setMode = (mode) => {
    this.setState({
      mode
    })
  };

  render(){
    const { mode, isSending, encounterComment } = this.state;

    return (
      <React.Fragment>
        <div className="dashboard-card" style={{padding:"1rem"}}>
          {
            mode === EncounterCommentForm.MODES.edit &&
              <form onSubmit={this.handleSubmit}>
                <div>
                  <textarea className="patient-notebox"
                    value={this.state.encounterComment} 
                    onChange={this.handleChange} 
                    type="text" placeholder="Enter Comments here..."
                  />
                </div>
                <div>
                  <button className="dashboard__primary-btn dashboard-form__btn" {...(encounterComment.length === 0 ? {disabled: true} : {})}>
                    {isSending ? (
                      <PulseLoader color="#ffffff" size={7}/>
                    ) : (
                      "Send"
                    )}
                  </button>
                  <span
                    className="button_link"
                    onClick={()=>this.setMode(EncounterCommentForm.MODES.display)}
                    style={{fontSize:"1.5rem", cursor:"pointer"}}>
                    Cancel
                  </span>
                </div>
              </form>
          }
          {
            mode === EncounterCommentForm.MODES.display &&
              <button className="dashboard__secondary-btn" onClick={() => this.setMode(EncounterCommentForm.MODES.edit)}>
                Add Encounter Comment
              </button>
          }
        </div>
      </React.Fragment>
    );
  }
}

EncounterCommentForm.MODES = {
  edit: "edit",
  display: "display"
};

export default EncounterCommentForm;
