import React from 'react';
import moment from 'moment';
import {withUser} from "./utils/AuthHOC";
import {ApiService, ENDPOINTS} from "../services/ApiService";
import LoadingScreen from "./LoadingScreen";
import Modal from "./boxes/Modal";
import { toastBanner } from "../services/utilityService";

const ChangeLogModal = ({onClose, typeId, type, title}) => (
  <Modal onClose={onClose} title={title}>
    <ChangeLog typeId={typeId} type={type}/>
  </Modal>
);

class ChangeLog extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      history: []
    }
  }

  componentDidMount(){
    this.getChangeLogHistory();
  }

  getChangeLogHistory = () => {
    const { typeId, type } = this.props;

    this.setLoading(true);
    this.service.get(
      type === ChangeLog.TYPES.medicalHistory ?
        ENDPOINTS.patients.medicalHistory.revisions(typeId)
        :
        ENDPOINTS.encounters.notes.history(typeId)
    )
      .then((response) => {
        this.setState({
          isLoading: false,
          history: response.data
        })
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => {
    return (
      <div style={{position:"relative", height:"5rem"}}>
        <LoadingScreen />
      </div>
    );
  };

  render(){
    const { isLoading, history } = this.state;

    if(isLoading) return this.renderLoading();

    return (
      <table className="dashboard-table">
        <thead>
        <tr>
          <th className="dashboard-table-item dashboard-table-item__header">Date</th>
          <th className="dashboard-table-item dashboard-table-item__header">Type</th>
          <th className="dashboard-table-item dashboard-table-item__header">From</th>
          <th className="dashboard-table-item dashboard-table-item__header">To</th>
          <th className="dashboard-table-item dashboard-table-item__header">Changed By</th>
        </tr>
        </thead>
        <tbody>
        {
          history.map((change, index)=>(
            <tr
              key={index}>
              <td className="dashboard-table-item dashboard-table-item__body">{moment(change.created_at).format('MMMM Do YYYY, h:mm a')}</td>
              <td className="dashboard-table-item dashboard-table-item__body">
                {change.type.replace(/_/g,' ')}
              </td>
              <td className="dashboard-table-item dashboard-table-item__body">{change.old_value}</td>
              <td className="dashboard-table-item dashboard-table-item__body">{change.new_value}</td>
              <td className="dashboard-table-item dashboard-table-item__body">{change.created_by.name}</td>
            </tr>
          ))
        }
        </tbody>
      </table>
    )
  }
}

ChangeLog.TYPES = {
  encounterNote: "encounter_note",
  medicalHistory: "medical_history"
};

ChangeLog = withUser(ChangeLog);

export default ChangeLogModal;