import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Modal from "../boxes/Modal";
import { ApiService, ENDPOINTS } from "../../services/ApiService";
import { toastBanner } from "../../services/utilityService";

export const RESULT_IMAGE_MODE = {
  thumbnail: "thumbnail",
  fullscreen: "fullscreen",
};

class ViewTestResultModal extends React.Component {
  constructor(props) {
    super(props);
    this.ApiService = new ApiService(props.userToken);
    this.state = {
      mode: RESULT_IMAGE_MODE.thumbnail,
      imageData: null,
      contentType: "",
    };
  }

  componentDidMount() {
    this.fetchImageData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.mode !== this.state.mode &&
      this.state.mode === RESULT_IMAGE_MODE.fullscreen
    ) {
      this.fetchImageData();
    }
  }

  componentWillUnmount() {
    if (this.state.imageData) {
      URL.revokeObjectURL(this.state.imageData);
    }
  }

  extractIdFromUrl(url) {
    // Split the URL string by "/"
    const parts = url.split("/");

    // Get the last part of the URL, which contains the ID
    const id = parts[parts.length - 1];

    // Return the extracted ID
    return id;
  }

  fetchImageData = async () => {
    const { result } = this.props;

    const id = this.extractIdFromUrl(result.url);

    try {
      const response = await this.ApiService.get(ENDPOINTS.patients.file(id), {
        responseType: "blob",
      });

      const imageUrl = URL.createObjectURL(response); // Create object URL from the Blob

      this.setState({
        imageData: imageUrl,
        contentType: response.type
      });
    } catch (error) {
      toastBanner(error.message || "An error occurred!");
    }
  };

  setMode = (mode) => {
    this.setState({
      mode,
    });
  };

  render() {
    const { result, onClose } = this.props;
    const { mode, imageData, contentType } = this.state;

    if (mode === RESULT_IMAGE_MODE.fullscreen)
      return (
        <ImagePreview
          image={imageData}
          result={result}
          onClose={() => this.setMode(RESULT_IMAGE_MODE.thumbnail)}
        />
      );

    return (
      <Modal title="View Result" onClose={onClose}>
        <div className="dashboard-card">
          <h1 className="dashboard-card__title-text">{`Result #${result.id}`}</h1>
          <p className="dashboard-card__body-text">{`Created ${moment(
            result.created_at
          ).format("dddd, MMMM Do YYYY")}`}</p>
          <div className="profile-item__label" style={{ marginBottom: "1rem" }}>
            Summary:
          </div>
          <p className="dashboard-card__body-text">{result.summary}</p>
          <div className="profile-item__label" style={{ marginBottom: "1rem" }}>
            Result:
          </div>
          {isPDF(contentType) ? (
            <div style={{ marginTop: "1rem", fontSize: "1.5rem" }}>
              <a href={imageData} target="_blank" rel="noopener noreferrer">
                Click here to view result
              </a>
            </div>
          ) : (
            <div className="image-preview thumbnail">
              <img
                src={imageData}
                alt={`Result for Test #${result.id}`}
                onClick={() => this.setMode(RESULT_IMAGE_MODE.fullscreen)}
              />
              <p onClick={() => this.setMode(RESULT_IMAGE_MODE.fullscreen)}>
                Click to enlarge
              </p>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const ImagePreview = ({ image, result, onClose }) => (
  <div className="modal open image-preview fullscreen">
    <button className="close" onClick={onClose}>
      <i className="fa fa-times" />
    </button>
    <img src={image} alt={`Result #${result.id}`} />
  </div>
);

const isPDF = (type) => {
  return type.includes("pdf");
};

ViewTestResultModal.propTypes = {
  result: PropTypes.object,
  onClose: PropTypes.func,
};

export default ViewTestResultModal;
