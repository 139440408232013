import React, { Component } from 'react';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import { toastBanner } from "../../services/utilityService";
import {Field, reduxForm, change} from 'redux-form';
import PageBody from '../boxes/PageBody';
import LoadingScreen from "../LoadingScreen";

class EncounterNotesForm extends Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading:false
    }
  }

  componentDidMount(){
    const {dispatch, encounterId, encounterNote} = this.props;
    if(encounterNote){
      dispatch(change("encounterNoteForm","encounter_id",encounterId));
      dispatch(change("encounterNoteForm","chief_complaint",encounterNote.chief_complaint));
      dispatch(change("encounterNoteForm","observation",encounterNote.observation));
      dispatch(change("encounterNoteForm","treatment",encounterNote.treatment));
    }
  }

  onSubmit = (values) => {
    const { onEncounterNoteSaved, encounterId } = this.props;
    this.setLoading(true);

    this.service.put(ENDPOINTS.encounters.notes.base(encounterId), values)
      .then(() => {
        this.setLoading(false);
        if(onEncounterNoteSaved){
          onEncounterNoteSaved(values);
        }
      })
      .catch(error => {
        this.setLoading(false);
        toastBanner(error.message)
      });
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <PageBody>
      <div style={{position:"relative", height:"5rem"}}>
        <LoadingScreen />
      </div>
    </PageBody>
  );

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    const { isLoading } = this.state;

    if(isLoading) return this.renderLoading();

    return (
      <PageBody>
        <div className="dashboard-card">
          <form className="dashboard-form" onSubmit={handleSubmit(this.onSubmit)}>
            <div className="dashboard-form-item-wrapper">
              <div className="dashboard-input-wrap profile-input-wrap">
                <span className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                    Chief Complaint
                  </label>
                  <Field component="input" className="dashboard-form__input notes__complaint" type="text" placeholder="Enter chief complaint" name="chief_complaint" required/>
                </span>

                <span className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                    Observation
                  </label>
                  <Field component="textarea" className="dashboard-form__input notes__complaint" placeholder="Enter observation notes" name="observation" required/>
                </span>

                <span className="dashboard-form-item">
                  <label
                    className="profile-item__label"
                    htmlFor="">
                    Treatment
                  </label>
                  <Field component="textarea" className="dashboard-form__input notes__complaint"  placeholder="Enter treatment" name="treatment" required/>
                </span>

                <Field component="input" type="hidden" name="encounter_id"/>

                <button
                  className="dashboard__primary-btn dashboard-form__btn"
                  type="submit"
                  disabled={pristine || submitting}>
                  Save Encounter Note
                </button>
              </div>
            </div>
          </form>
        </div>
      </PageBody>
    );
  }
}

EncounterNotesForm = reduxForm({
  form: "encounterNoteForm"
})(EncounterNotesForm);

export default EncounterNotesForm;