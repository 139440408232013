import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import NewPasswordForm from "../components/forms/NewPasswordForm";
import LoadingScreen from "../components/LoadingScreen";
import userService from "../services/userService";
import { toastBanner } from "../services/utilityService";

class ResetPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      invoice: null,
    };
  }

  renderLoading() {
    return <LoadingScreen />;
  }

  setLoading(value) {
    this.setState({
      isLoading: true,
    });
  }

  submitPassword = (values) => {
    const url = new URL(window.location.href);
    const email = url.searchParams.get("email");
    const data = {
      email_address: email,
      password: values.password,
      confirm_password: values.confirm_password,
    };
    this.setLoading(true);
    if (values.password.trim() !== values.confirm_password.trim()) {
      this.setLoading(false);
      toastBanner("Passwords do not match, please ensure both passwords match", "error");
    } else {
      userService
        .changePassword(data)
        .then((response) => {
          this.setLoading(false);
          toastBanner("Your Password has been changed succesfully", "success");
          setTimeout(() => window.location.href = `/login`, 2000)
        })
.catch((err) => {
          this.setLoading(false);
          toastBanner(`${err && err.response && err.response.data?.message || "An error occured!"}`);
        });
    }
  };

  renderSetNewPassword() {
    const { error, isLoading } = this.props;
    return (
      <FormHolder message={error.message} isLoading={isLoading}>
        <NewPasswordForm onSubmit={this.submitPassword} />
      </FormHolder>
    );
  }

  render() {
    const { isLoading } = this.props;
    if (isLoading) return this.renderLoading();
    return this.renderSetNewPassword();
  }
}

const FormHolder = (props) => {
  return (
    <div className="container">
      <div className="login_form change-password-form">
        <div
          style={{
            padding: "10px",
            backgroundColor: "red",
            color: "white",
            visibility:
              !props.isLoading && props.message ? "visible" : "hidden",
          }}
        >
          {props.message}
        </div>
        <div className="form_wrapper">{props.children}</div>
        <div>
          <Link to={"/login"}>Back to Login</Link>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { isLoading, error, message } = state.user || {};
  return { isLoading, error, message };
}

export default withRouter(connect(mapStateToProps)(ResetPasswordContainer));
