import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FilterableSelect from "../inputs/FilterableSelect";
import { ApiService, ENDPOINTS } from "../../services/ApiService";
import { toastBanner } from "../../services/utilityService";
import LoadingScreen from '../LoadingScreen';

class AddDiagnosesForm extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading: false,
      selectedDiagnosisCategory: {}
    }
  }

  componentWillUnmount(){
    if(this.service.cancel &&  this.service.cancel instanceof Function)
      this.service.cancel();
  }

  onDiagnosisCategorySelected = (diagnosisCategory) => {
    this.setState({
      selectedDiagnosisCategory: diagnosisCategory
    });
  };

  removeDiagnosisCategory = () => {
    this.setState({
      selectedDiagnosisCategory: {}
    });
  };

  onSubmit = (values) => {
    const { selectedDiagnosisCategory } = this.state;
    const { onDiagnosesCreated } = this.props;


    if (!values.is_child && !values.is_adult){
      let toastMessage = "You must select an age group";

      toastBanner(toastMessage);
    } 
    if (!selectedDiagnosisCategory.name || !selectedDiagnosisCategory.id){
      let toastMessage = `You must select a category!`;

      toastBanner(toastMessage);
    }
    if (!values.name){
      let toastMessage = `You must enter a name!`;

      toastBanner(toastMessage);
    }
    else{
      const diagnosis = {
        name: values.name,
        is_child: values.is_child || false,
        is_adult: values.is_adult || false,
        diagnosis_category_name: selectedDiagnosisCategory.name,
        diagnosis_category_id: selectedDiagnosisCategory.id ? selectedDiagnosisCategory.id : null
      };

      this.setLoading(true);
      this.service.post(ENDPOINTS.utilities.diagnoses, diagnosis)
        .then(response => {
          this.setState({
            diagnosis
          });
          this.setLoading(null);
          if(onDiagnosesCreated){
            onDiagnosesCreated({...diagnosis, id: response.data.diagnosis_id});
          }
        })
        .catch((error) => {
          toastBanner(error.message);
          this.setLoading(null);
        });
    }
  };

  // refresh = () => {
  //   const { reset } = this.props;

  //   if(reset)
  //     reset();
  //   this.setState({
  //     selectedDiagnosisCategory: null
  //   });
  // };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );

  render(){
    const { isLoading, selectedDiagnosisCategory } = this.state;
    const { onClose, handleSubmit, diagnosesCategories } = this.props;

    if(isLoading) return this.renderLoading();

    return (
      <form className="dashboard-form" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="dashboard-form-item-wrapper">
          <div className="dashboard-input-wrap profile-input-wrap">
            <span className="dashboard-form-item">
              <label
                className="profile-item__label"
                htmlFor="">
                Diagnoses
              </label>
              <Field component="input" className="dashboard-form__input" type="text" placeholder="Enter diagnosis name" name="name"/>
            </span>

            <span className="dashboard-form-item">
              <label htmlFor="" className="profile-item__label">Age category</label>
              <span className="dashboard-form__checklist-wrap" style={{ flexDirection: "row"}}>
                <span>
                  <Field
                    component="input"
                    type="checkbox" 
                    name="is_adult" 
                    className="" 
                    value="adult"
                    id="adult"
                  />
                  <label htmlFor="adult" style={{marginRight: "1rem"}}>Adult</label>
                </span>
                <span>
                  <Field 
                    component="input"
                    type="checkbox" 
                    name="is_child" 
                    className="" 
                    value="child" 
                    id="child" 
                  />
                  <label htmlFor="child" style={{marginRight: "1rem"}}>Child</label>
                </span>
              </span>
            </span>
            {
              selectedDiagnosisCategory && (!selectedDiagnosisCategory.id ?
                <React.Fragment>
                  <span className="dashboard-form-item">
                    <label htmlFor="" className="profile-item__label">Diagnoses category</label>
                    <Field 
                      component={FilterableSelect}
                      name="category" 
                      placeholder={`${String.fromCharCode(0xD83D,0xDD0D)} Select Category`}

                      props={{
                        items: diagnosesCategories,
                        className: "search-section filterable-select embedded",
                        filterBy: ['name'],
                        canAddBtn: true,
                        clearOnSelect: true,
                        setCategory: this.setCategory,
                        input: {
                          onChange: (item) => {
                            this.onDiagnosisCategorySelected(item);
                          }
                        }
                      }}
                    />
                  </span>
                </React.Fragment>
                :
                <React.Fragment>
                  <p className="dashboard-card__body-text" style={{marginTop: "2rem"}}>Selected Diagnosis Category: </p>
                  <div style={{display:"flex"}}>
                      <div key={selectedDiagnosisCategory.id} className="overview-item__tag">
                        <span className="overview-item__small-text">{selectedDiagnosisCategory.name}</span>
                        {`    `}
                        <span
                          className="overview-item__small-text"
                          style={{cursor:"pointer", margin:"0.5rem"}}
                          onClick={() => {
                            this.removeDiagnosisCategory();
                          }}>x</span>
                      </div>
                  </div>
                </React.Fragment>)
            }
            <div style={{display:"flex",flexDirection:"flex-start"}}>
              <button className="dashboard__primary-btn dashboard-form__btn" type="submit">Save</button>
              <button className="dashboard__secondary-btn dashboard-form__btn" type="button" onClick={onClose}>Cancel</button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

AddDiagnosesForm = reduxForm({
  form:"addDiagnosesForm",
  destroyOnUnmount: true
})(AddDiagnosesForm);

export default AddDiagnosesForm;
