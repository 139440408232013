import React from 'react';
import { connect } from 'react-redux';
import {ApiService, ENDPOINTS} from "../services/ApiService";
import * as stageActions from '../actions/stages';
import Modal from "./boxes/Modal";
import UpdateStageForm from "./forms/UpdateStageForm";
import { toastBanner, STAGEDATA } from "../services/utilityService";
import LoadingScreen from "./LoadingScreen";

export const UpdateStageComponentModal = (props) => (
  <Modal onClose={props.onClose} title="Update Patient Stage">
    <UpdateStageComponent
      {...props} />
  </Modal>
);

export class UpdateStageComponent extends React.Component {

  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading: false,
    }
  }

  onSubmit = (values, dispatch) => {
    const {
      encounterId,
      currentStageId,
      statusId,
      onClose,
      userToken
    } = this.props;

    //update the current stage status
    this.setLoading(true);

    this.service.post(ENDPOINTS.stages.base, {
      encounter_id: encounterId,
      status_id: statusId,
      current_stage_id: currentStageId,
      next_stage_id: values.stage_id
    })
      .then(() => {
        //refresh stages view
        this.setLoading(false);

        toastBanner("The patient's stage has successfully been updated.", "success");

        dispatch(stageActions.loadStages(userToken, true));

        setTimeout(() => {
          if(onClose)
            onClose();
        },1500);
      })
      .catch(error => {
        toastBanner(error.message || "An error occurred!");
      })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  render(){
    const { isLoading } = this.state;

    if(isLoading) return this.renderLoading();

    return (
      <React.Fragment>
        <UpdateStageForm onSubmit={this.onSubmit} exclude={[STAGEDATA.stages.yetToArrive, this.props.currentStageId]}/>
      </React.Fragment>
    )
  }
}

UpdateStageComponent =  connect()(UpdateStageComponent);

export default UpdateStageComponent;
