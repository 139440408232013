import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";
import moment from "moment";
import { ApiService, ENDPOINTS } from "../../services/ApiService";
import LoadingScreen from "../LoadingScreen";
import PageBody from "../boxes/PageBody";
import ResultUploadModal, { UPLOAD_MODE } from "../modals/ResultUploadModal";
import ViewTestResultModal from "../modals/ViewTestResultModal";
import EditResultUploadModal from "../modals/EditTestResultModal";
import RestrictedComponent from "../utils/RestrictedComponent";
import { toastBanner, PERMISSIONS } from "../../services/utilityService";
import DecisionModal from "../modals/DecisionModal";
import EditIcon from "../../img/edit.svg";
import DeleteIcon from "../../img/delete.svg";

class Files extends Component {
  constructor(props) {
    super(props);
    this.ApiService = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading: false,
      files: [],
      selectedFile: null,
      showFileUploadModal: false,
      showViewFileModal: false,
      showEditFileModal: false,
      showDeleteFileModal: false,
    };
  }

  componentDidMount() {
    const { patient } = this.props;
    this.setLoading(true);
    this.ApiService.get(ENDPOINTS.patients.results(patient.id))
      .then((response) => {
        this.setState({
          isLoading: false,
          files: response.data,
        });
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  renderLoading = () => (
    <PageBody>
      <LoadingScreen />
    </PageBody>
  );

  toggleFileUploadModal = () => {
    this.setState({
      showFileUploadModal: !this.state.showFileUploadModal,
    });
  };

  toggleViewFileModal = (selectedFile) => {
    if (selectedFile !== null) {
      this.setState({
        showViewFileModal: !this.state.showViewFileModal,
        selectedFile,
      });
    } else{
      this.setState({
        showViewFileModal: !this.state.showViewFileModal
      });
    }
  };

  downloadFile = (selectedFile) => {
    if (selectedFile !== null && selectedFile.url) {
      const url = selectedFile.url;
      const filename = url.substring(url.lastIndexOf("/") + 1);

      fetch(url, {
        headers: {
          Authorization: `Bearer ${this.props.user.credentials.accessToken}`,
        },
      })
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = filename;

          document.body.appendChild(link);
          link.click();

          // Clean up
          URL.revokeObjectURL(blobUrl);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    }
  };

  toggleEditFileModal = (selectedFile) => {
    if (selectedFile !== null) {
      this.setState({
        showEditFileModal: !this.state.showEditFileModal,
        selectedFile,
      });
    } else{
      this.setState({
        showEditFileModal: !this.state.showEditFileModal
      });
    }
  };

  toggleDeleteFileModal = (selectedFile) => {
    if (selectedFile !== null) {
      this.setState({
        showDeleteFileModal: !this.state.showDeleteFileModal,
        selectedFile,
      });
    } else{
      this.setState({
        showDeleteFileModal: !this.state.showDeleteFileModal
      });
    }
  };

  deleteFile = () => {
    const { selectedFile } = this.state;
    const { patient } = this.props;
    this.ApiService.delete(
      `${ENDPOINTS.patients.results(patient.id)}/${selectedFile.id}`
    )
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        toastBanner("You have successfully deleted the result", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        toastBanner(error.message || "An error occured!");
      });
  };

  render() {
    const {
      isLoading,
      files,
      selectedFile,
      showViewFileModal,
      showFileUploadModal,
      showEditFileModal,
      showDeleteFileModal,
    } = this.state;
    const { user, patient } = this.props;

    if (isLoading) return this.renderLoading();

    return (
      <div className="patient-profile">
        {showFileUploadModal && (
          <ResultUploadModal
            patient={patient}
            userToken={user.credentials.accessToken}
            test={selectedFile}
            type={UPLOAD_MODE.patientUpload}
            onClose={this.toggleFileUploadModal}
          />
        )}
        {showViewFileModal && (
          <ViewTestResultModal
            patient={patient}
            result={selectedFile}
            onClose={() => this.toggleViewFileModal(null)}
            userToken={user.credentials.accessToken}
          />
        )}
        {showEditFileModal && (
          <EditResultUploadModal
            patient={patient}
            userToken={user.credentials.accessToken}
            result={selectedFile}
            onClose={() => this.toggleEditFileModal(null)}
          />
        )}
        {showDeleteFileModal && (
          <DecisionModal
            title="Delete this File"
            onClose={() => this.toggleDeleteFileModal(null)}
            open={showDeleteFileModal}
            content=""
            onYes={this.deleteFile}
          />
        )}
        <div className="dashboard-card">
          <div
            className="dashboard-control"
            style={{ justifyContent: "flex-end" }}
          >
            <RestrictedComponent
              displayErrorMessage={false}
              condition={
                user.permissions.indexOf(
                  PERMISSIONS.encounter.encounter_test_result
                    .create_encounter_test_result
                ) > -1
              }
            >
              <button
                className="dashboard__secondary-btn"
                onClick={this.toggleFileUploadModal}
              >
                <i className="fas fa-plus" style={{ marginRight: "4px" }} />
                &nbsp; Add File
              </button>
            </RestrictedComponent>
          </div>
          <RestrictedComponent
            displayErrorMessage={true}
            errorComponent={() => (
              <div className="dashboard-card">
                <div style={{ position: "relative", height: "10rem" }}>
                  <p className="dashboard-card__body-text">
                    You don't have permission to view this.
                  </p>
                </div>
              </div>
            )}
            condition={
              user.permissions.indexOf(
                PERMISSIONS.encounter.encounter_test_result
                  .read_encounter_test_result
              ) > -1
            }
          >
            <div className="dashboard-table-overflow">
              {files.length > 0 ? (
                <table className="dashboard-table">
                  <thead>
                    <tr>
                      <th className="dashboard-table-item dashboard-table-item__header">
                        Upload Date
                      </th>
                      <th className="dashboard-table-item dashboard-table-item__header">
                        Test
                      </th>
                      <th className="dashboard-table-item dashboard-table-item__header">
                        Summary
                      </th>
                      <th className="dashboard-table-item dashboard-table-item__header">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file, index) => (
                      <tr key={`${file.id}-${index}`}>
                        <td className="dashboard-table-item dashboard-table-item__body">
                          {moment(file.created_at).format(
                            "MMMM Do YYYY, h:mm a"
                          )}
                        </td>
                        <td className="dashboard-table-item dashboard-table-item__body">
                          {_.isEmpty(file.test)
                            ? "Not Available"
                            : `${file.test.name}`}
                        </td>
                        <td className="dashboard-table-item dashboard-table-item__body">
                          {file.summary}
                        </td>
                        <td className="dashboard-table-item dashboard-table-item__body">
                          <button
                            onClick={() => this.toggleViewFileModal(file)}
                            className="button_link"
                            style={{
                              marginRight: "1rem",
                              verticalAlign: "middle",
                              cursor: "pointer",
                            }}
                          >
                            View File
                          </button>
                          <button
                            onClick={() => this.downloadFile(file)}
                            className="button_link"
                            style={{
                              marginRight: "1rem",
                              verticalAlign: "middle",
                              cursor: "pointer",
                            }}
                          >
                            Download File
                          </button>
                          <button
                            onClick={() => this.toggleEditFileModal(file)}
                            className="button_link"
                            style={{
                              marginRight: "1rem",
                              verticalAlign: "middle",
                            }}
                          >
                            <img src={EditIcon} alt="edit file" />
                          </button>
                          <button
                            onClick={() => this.toggleDeleteFileModal(file)}
                            className="button_link"
                            style={{
                              marginRight: "1rem",
                              verticalAlign: "middle",
                            }}
                          >
                            <img src={DeleteIcon} alt="delete file" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div style={{ fontSize: "2rem", color: "grey" }}>No files</div>
              )}
            </div>
          </RestrictedComponent>
        </div>
      </div>
    );
  }
}

export default withRouter(Files);
