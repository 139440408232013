import React from 'react';
import {reduxForm, Field} from 'redux-form';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import { toastBanner } from "../../services/utilityService";
import LoadingScreen from '../LoadingScreen';
import Modal from '../boxes/Modal';

//onClose, patient, userToken, onRelationAdded
const AddInsurerModal = (props) => (
  <Modal title="Add Insurer" onClose={props.onClose}>
    <AddInsurerComponent {...props}/>
  </Modal>
);

class AddInsurerComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading: false,
      policies: [],
      insurer:null,
    };
  }

  componentDidMount(){
    this.setLoading(true);
    this.service.get(ENDPOINTS.utilities.policies)
      .then(response => {
        this.setState({
          isLoading: false,
          policies: [...response.data]
        });
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });
        toastBanner(error.message || "An error occured!")
      });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  onSubmit = (values) => {
    const { patient, onInsurerAdded } = this.props;
    const { policies } = this.state;
    this.setLoading(true);

    this.service.post(ENDPOINTS.patients.insurers.base(patient.id), values)
      .then(response => {
        const insurer = {
          id: response.data.id,
          policy: policies.filter(p => p.id === Number(values.policy_id))[0].name,
          code: values.policy_code
        };
        
        this.setLoading(false);
        this.setState({
          insurer: insurer
        });
        toastBanner(`${insurer.policy_code} has successfully been added.`, "success")

        if(onInsurerAdded)
          onInsurerAdded(insurer);
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });
        toastBanner(error.message || "An error occured!")
      });
  };

  refresh = () => {
    const { reset } = this.props;
    if(reset)
      reset();
    this.setState({
      insurer: null
    })
  };

 

  render(){
    const {handleSubmit, onClose } = this.props;
    const {isLoading, insurer, policies} = this.state;

    if(isLoading) return (<div style={{position:"relative", height:"5rem"}}><LoadingScreen/></div>);

    return (
      <form className="dashboard-form" onSubmit={handleSubmit(!insurer ? this.onSubmit : this.refresh)}>

        <div className="dashboard-form-item-wrapper">
          <div className="dashboard-form-item">
            <label
              className="profile-item__label"
              htmlFor="">
              Insurer
            </label>
            <Field component="select" className="dashboard-form__input dashboard-form__select" name="policy_id">
              <option>Please select an Insurer</option>
              {policies
                .filter(policy => policy.id !== 1)
                .map(policy => (
                  <option key={policy.id} value={policy.id}>{policy.name}</option>
                ))}
            </Field>
          </div>

          <div className="dashboard-form-item">
            <label className="profile-item__label" htmlFor="">
              Policy Number
            </label>
            <Field component="input" className="dashboard-form__input" type="text" placeholder="Enter policy no." name="policy_code"/>
          </div>
        </div>
        <div style={{display:"flex",flexDirection:"flex-start"}}>
          <button className="dashboard__primary-btn dashboard-form__btn" type="submit" disabled={!!insurer}>Save</button>
          <button className="dashboard__secondary-btn dashboard-form__btn" type="button" onClick={onClose}>Cancel</button>
        </div>
      </form>
    )
  }
}

AddInsurerComponent = reduxForm({
  form:"addInsurerForm"
})(AddInsurerComponent);

export default AddInsurerModal;