import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import LoadingScreen from "../components/LoadingScreen";
import { ApiService, ENDPOINTS } from "../services/ApiService";
import { toastBanner } from "../services/utilityService";
import PageBody from "../components/boxes/PageBody";

class EncounterNoteDetail extends Component {
  constructor(props){
    super(props);
    this.ApiService = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      encounter: null
    }
  }

  componentDidMount(){
    const { match : { params } } = this.props;
    this.setLoading(true);
    this.ApiService.get(ENDPOINTS.encounters.encounter(params.eId)).then((response) => {
      this.setState({
        isLoading: false,
        encounter: response.data
      })
    })
    .catch((error) => {
      toastBanner(error.message || "An error occured!");
    });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <PageBody>
      <LoadingScreen />
    </PageBody>
  );


  render() {
    const { isLoading, encounter } = this.state;

    if(isLoading || !encounter) return this.renderLoading();

    return (
        <div className="patient-detail">
          <div className="dashboard-card" style={{marginBottom: "2rem"}}>
            <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem"}}>Summary</h2>
            <p className="dashboard-card__body-text">{encounter.note.summary}</p>
          </div>

          <div className="dashboard-card" style={{marginBottom: "2rem"}}>
            <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem"}}>Observation</h2>
            <p className="dashboard-card__body-text">{encounter.note.observation}</p>
          </div>

          <div className="dashboard-card" style={{marginBottom: "2rem"}}>
            <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem"}}>Treatment</h2>
            <p className="dashboard-card__body-text">{encounter.note.treatment}</p>
          </div>

          <div className="dashboard-card" style={{marginBottom: "2rem"}}>
            <h2 className="dashboard-card__title-text">Recorded Allergies</h2>
            <div className="dashboard-table-overflow">
              {encounter.allergies.length > 0 ? (
                <React.Fragment>
                  <div className="dashboard-table-overflow">
                    <table className="dashboard-table">
                      <thead>
                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">Allergen</th>
                        <th className="dashboard-table-item dashboard-table-item__header">Reaction</th>
                        <th className="dashboard-table-item dashboard-table-item__header">Date Created</th>
                      </tr>
                      </thead>
                      <tbody>
                      {encounter.allergies.map((allergy, index)=>(
                        <tr key={`${allergy.id}-${index}`} >
                          <td className="dashboard-table-item dashboard-table-item__body">{allergy.name}</td>
                          <td className="dashboard-table-item dashboard-table-item__body">{allergy.reaction || allergy.description}</td>
                          <td className="dashboard-table-item dashboard-table-item__body">{moment(allergy.created_at).format('MMMM Do YYYY')}</td>
                        </tr>
                      ))
                      }
                      </tbody>
                    </table>
                  </div>
                </React.Fragment>
              ) : (
                <div style={{fontSize: "2rem", color: "grey"}}>None</div>
              )}
            </div>
          </div>

          <div className="dashboard-card">
            <h2 className="dashboard-card__title-text">Prescriptions</h2>
            <div className="dashboard-table-overflow">
              {encounter.prescriptions.length > 0 ? (
                <React.Fragment>
                  {encounter.prescriptions.map(prescription => (
                    <div className="dashboard-card" style={{marginBottom: "2rem"}}>
                      <h3 className="dashboard-card__title-text">Prescription #{prescription.id}</h3>
                      <label className="profile-item__label">Status:</label>
                      <p className="dashboard-card__body-text">
                        {Number(prescription.status_id) === 1 ? "Pending" : "Dispensed"}
                      </p>

                      <label className="profile-item__label">Drugs:</label>
                      <p className="dashboard-card__body-text">
                        {prescription.medications.map(m => m.name).join(", ")}
                      </p>
                    </div>
                  )) }
                </React.Fragment>
              ) : (
                <div style={{fontSize: "2rem", color: "grey"}}>No Prescription</div>
              )}
            </div>
          </div>
        </div>
    )
  }
}

export default withRouter(EncounterNoteDetail);