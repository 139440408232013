import React from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, FieldArray, formValueSelector} from 'redux-form';
import FilterableSelect from "../inputs/FilterableSelect";

const CreatePrescriptionForm = ({handleSubmit, drugs, onPrescriptionAdded, onSavePrescriptionAdded}) => {
  return (
    <form className="dashboard-form" onSubmit={handleSubmit}>
      {
        (!onSavePrescriptionAdded && onPrescriptionAdded) &&
        <span className="dashboard-form-item">
          <label
            className="profile-item__label"
            htmlFor="">
            Create Invoice?
          </label>
          <Field
            component="select"
            name="create_invoice"
            className="dashboard-form__input dashboard-form__select"
            normalize={value => value === 'true'}>
            <option>Please select</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Field>
        </span>
      }

      <span className="dashboard-form-item" style={{margin:"2rem 0"}}>
          <FieldArray name="medications" component={renderDrugFields} props={{drugs}}/>
        </span>

      {
        (!onSavePrescriptionAdded && onPrescriptionAdded) &&
          <span className="dashboard-form-item">
            <label className="profile-item__label" htmlFor="">
              Comment
            </label>
            <Field
              component="textarea"
              name="comment"
              className="dashboard-form__input"
              type="text"
              placeholder="Enter comment..."/>
          </span>
      }
      <div style={{display:"flex",flexDirection:"flex-start"}}>
        <button className="dashboard__primary-btn dashboard-form__btn">Save Prescription</button>
      </div>
    </form>
  )
};

let DrugEntryField = ({drugs, drug, index, onRemove, selectedDrug}) => (
  <li>
    <div style={{display:"flex", alignItems:"center", margin:"2rem 0"}}>
      <span className="dashboard-form-item" style={{width:"auto"}}>
        <label className="profile-item__label" htmlFor="">
          Drug #{index+1}
        </label>
        <Field
          name={`${drug}.drug`}
          className="filterable-select search-section embedded"
          component={FilterableSelect}
          props={
            {
              items: drugs,
              placeholder:"Enter drug name...",
              filterBy:["name"],
            }
          }/>
      </span>
      {selectedDrug &&
      <React.Fragment>
          <span className="dashboard-form-item" style={{width:"auto"}}>
            <label className="profile-item__label" htmlFor="">
              Quantity
            </label>
            <Field
              component="input"
              style={{width:"auto"}}
              name={`${drug}.quantity`}
              className="dashboard-form__input"
              type="number"
              placeholder="Quantity"/>
          </span>
        <span className="dashboard-form-item" style={{width:"auto"}}>
            <label className="profile-item__label" htmlFor="">
              Unit Price
            </label>
            <div
              style={{width:"auto"}}
              className="dashboard-form__input">
              {selectedDrug.price || "0.00"}
            </div>
          </span>
        <span className="dashboard-form-item" style={{marginLeft: "1rem", width:"auto"}}>
            <label className="profile-item__label" htmlFor=""> Delete </label>
            <button className="dashboard__secondary-btn" onClick={onRemove}>
              <label className="profile-item__label" htmlFor="">
                <i className="fas fa-trash"/>
              </label>
            </button>
          </span>
      </React.Fragment>
      }
    </div>
  </li>
);

const selector = formValueSelector('createPrescriptionForm');

DrugEntryField = connect(
  (state, props) => ({
    selectedDrug:  selector(state, `${props.drug}.drug`)
  })
)(DrugEntryField);

const renderDrugFields = ({fields, drugs}) => (
  <ul>
    {fields.map((drug, index) => (
      <DrugEntryField
        key={index}
        index={index}
        drug={drug}
        drugs={drugs}
        onRemove={() => fields.remove(index)}/>
    ))}
    <li>
      <button
        className="dashboard__secondary-btn"
        type="button"
        onClick={() => fields.push({quantity:1})}>
        {`${fields.length > 0 ? "Add Another Drug" : "Click to add a Drug"} `}
      </button>
    </li>
  </ul>
);

export default reduxForm({
  form: "createPrescriptionForm"
})(CreatePrescriptionForm);
