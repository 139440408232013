import React from 'react';
import PropTypes from 'prop-types';
import Modal from "../boxes/Modal";

const DecisionModal = ({title, open, onYes, content, onClose}) => {
  return (
    open ? <Modal title={title} onClose={onClose}>
      <div style={{maxWidth:"60rem"}}>
        <p style={{fontSize: "1.5rem"}}>Are you sure you want to {title}?</p>
        <p style={{fontSize: "1.2rem"}}>{content}</p>
        <div style={{display: "flex",padding: "1rem",background: "#FFFFFF"}}>
          <button
            className="primary_button red"
            style={{background: "none", color:"#ab0404"}}
            onClick={onYes}>
            {'  '}
            YES
          </button>
          <button
            className="primary_button"
            onClick={onClose}>
            {'  '}
            NO
          </button>
        </div>
      </div>
    </Modal> : null
  )
};


DecisionModal.propTypes = {
  onYes: PropTypes.instanceOf(Function),
  onClose: PropTypes.instanceOf(Function),
  title: PropTypes.string,
  content: PropTypes.string
};

export default DecisionModal;