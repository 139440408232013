import {combineReducers} from 'redux';
import userReducer from './user';
import {reducer as formReducer} from 'redux-form';
import displayReducer from './displayReducer';
import {sessionReducer} from 'redux-react-session';
import stagesReducer from "./stages";

const rootReducer = combineReducers({
  form: formReducer,
  session: sessionReducer,
  user: userReducer,
  stages: stagesReducer,
  display: displayReducer,
});

export default rootReducer;
