import {
  PAYMENT_STATUS,
  INVOICE_STATUS,
  STAGEDATA,
  getAge,
} from "./utilityService";

export const patientsColumnData = [
  {
    key: "rfc_id",
    type: "string",
    alias: "RFC ID",
  },
  {
    key: "first_name",
    type: "string",
    searchable: true,
  },
  {
    key: "last_name",
    type: "string",
    searchable: true,
  },
  {
    key: "email_address",
    type: "string",
  },
  {
    key: "phone_number",
    type: "string",
  },
  {
    key: "gender",
    type: "string",
    format: (gender) => (gender === "m" ? "Male" : "Female "),
  },
  {
    key: "date_of_birth",
    type: "string",
    alias: "Age",
    format: (dob) => getAge(dob),
  },
  {
    key: "address",
    type: "string",
  },
];

export const encountersColumnData = [
  {
    key: "patient",
    type: "string",
    alias: "Name",
    searchable: true,
    format: (patient) => patient.name,
  },
  {
    key: "total_price",
    type: "number",
  },
  {
    key: "appointment_time",
    type: "date",
  },
  {
    key: "parent_encounter_id",
    type: "string",
    alias: "Encounter Type",
    format: (data) => (data ? "Follow-up Encounter" : "Normal Encounter"),
  },
  {
    key: "current_stage_id",
    type: "string",
    alias: "Current Stage",
    format: (data) => STAGEDATA.getStageName(data),
  },
];

export const patientEncounter = [
  {
    key: "encounter_type",
    type: "string",
    alias: "Encounter Type",
    format: (data) => (data ? "Follow-up Encounter" : "Normal Encounter"),
  },
  {
    key: "appointment_time",
    type: "date",
  },
  {
    key: "status",
    type: "string",
    alias: "Current Stage",
    format: (data) => STAGEDATA.getStageName(data),
  },
  {
    key: "chief_complaint",
    type: "string",
    alias: "Chief Complaints",
    format: (data) => (data ? data : "Not Available"),
  },
];

export const invoicesListColumnData = [
  {
    key: "id",
    type: "string",
    alias: "Invoice ID",
  },
  {
    key: "patient",
    type: "string",
    alias: "Patient",
    searchable: true,
    format: (data) => `${data.first_name} ${data.last_name}`,
  },
  {
    key: "amount",
    type: "number",
  },
  {
    key: "description",
    type: "string",
    searchable: true,
  },
  {
    key: "payment_status",
    type: "string",
    format: (data) => INVOICE_STATUS.getStatus(Number(data)),
  },
  {
    key: "created_at",
    type: "string",
    alias: "Date",
  },
  {
    key: "created_by",
    type: "string",
    alias: "Issued By",
  },
];

export const claimsColumnData = [
  {
    key: "code",
    type: "string",
    alias: "Claim Code",
  },
  {
    key: "patient",
    type: "string",
    searchable: true,
    format: (data) => data.name,
  },
  {
    key: "price",
    type: "number",
  },
  {
    key: "created_at",
    type: "date",
    alias: "Encounter Date",
  },
  {
    key: "created_by",
    type: "custom",
    format: (data) => data.name,
  },
  {
    key: "status",
    type: "string",
    format: (data) => PAYMENT_STATUS.getPaymentStatus(Number(data)),
  },
];

export const prescriptionColumnData = [
  {
    key: "created_at",
    type: "date",
    alias: "Date Created",
  },
  {
    key: "price",
    type: "number",
    alias: "Total Price",
  },
  {
    key: "medications",
    type: "custom",
    searchable: true,
    format: (data) =>
      data.map((m) => `${m.name} x ${m.quantity || 1}`).join(", "),
  },
  {
    key: "comment",
    type: "string",
  },
];

export const invoicesColumnData = [
  {
    key: "id",
    type: "string",
    alias: "Invoice #",
  },
  {
    key: "price",
    type: "number",
  },
  {
    key: "description",
    type: "string",
    searchable: true,
  },
  {
    key: "status",
    type: "number",
    format: (data) => INVOICE_STATUS.getStatus(Number(data)),
  },
  {
    key: "issued_by",
    type: "string",
    searchable: true,
  },
];

export const procedureColumnData = [
  {
    key: "name",
    type: "string",
  },
  {
    key: "summary",
    type: "string",
  },
];

export const pacodeColumnData = [
  {
    key: "pacode",
    type: "string",
    alias: "PA Code",
  },
  {
    key: "description",
    type: "string",
  },
  {
    key: "created_at",
    type: "date",
    alias: "Date Created",
  },
];

export const allergiesColumnData = [
  {
    key: "name",
    type: "string",
  },
  {
    key: "description",
    type: "string",
  },
];

export const testsColumnData = [
  {
    key: "name",
    type: "string",
  },
  {
    key: "price",
    type: "number",
  },
  {
    key: "created_at",
    type: "date",
    alias: "Date Created",
  },
];

export const NPSColumnData = [
  {
    key: "name",
    type: "string",
    searchable: true,
  },
  {
    key: "email",
    type: "string",
    searchable: true,
  },
  {
    key: "comment",
    type: "string",
  },
  {
    key: "date_received",
    type: "date",
    alias: "Date Created",
  },
  {
    key: "encounter_stage",
    type: "string",
  },
  {
    key: "has_reviewed",
    type: "string",
    format: (data) => (data === 1 ? "Completed" : "Pending"),
    alias: "Status",
  },
];
