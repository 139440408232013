import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

import LOGO from '../../img/logo.svg';

let ForgotPasswordForm = (props) => {

    const {handleSubmit,pristine,submitting, rfcLink} = props;

    return (
        <div className="form">
            <form className="reset-password-form" onSubmit={handleSubmit}>
                <div className="form_logo reset-password-form__logo">
                    <Link to={'/'}><img src={LOGO} alt="Reliance HMO's Logo"/></Link>
                </div>
                <p className="reset-password__small-text">Enter your email address to receive a link to reset your password</p>
                <label
                  className="profile-item__label"
                  htmlFor="email_address">
                  Email
                </label>
                <Field component="input" type="text" name="email_address" placeholder="Your email address" required/>
                <button className="reset-send-link" type="submit" disabled={pristine||submitting}>Send Link</button>
            </form>
        </div>
    )
};


ForgotPasswordForm = reduxForm({
    form:'forgotPasswordForm'
})(ForgotPasswordForm);

export default ForgotPasswordForm