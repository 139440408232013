import React, { Component } from 'react';
import moment from 'moment';
import nl2br from 'react-nl2br';
import { Field, reduxForm, change } from 'redux-form';
import RestrictedComponent from "./utils/RestrictedComponent";
import ChangeLogModal from "./ChangeLogModal";
import { toastBanner, PERMISSIONS } from "../services/utilityService";
import { ApiService, ENDPOINTS } from "../services/ApiService";
import LoadingScreen from "./LoadingScreen";

class MedicalHistoryComponent extends Component {
  constructor(props){
    super(props)
    this.ApiService = new ApiService(props.user.credentials.accessToken);
    this.state = {
      medicalHistory: [],
      isLoading: false,
      showChangeLogModal: false,
      mode: "display",
    }
  }

  componentDidMount () {
    this.getMedicalHistory();
  }

  componentWillUnmount () {
    if(this.ApiService.cancel && this.ApiService.cancel instanceof Function)
      this.ApiService.cancel();
  }

  setMode = (mode) => {
    this.setState({
      mode
    });
  }

  getMedicalHistory = () => {
    const { patient, dispatch } = this.props;

    this.setState({
      isLoading: true,
    });

    this.ApiService.get(ENDPOINTS.patients.medicalHistory.revisions(patient.id))
    .then((response) => {
      this.setState({
        medicalHistory: response.data,
        isLoading: false
      });
      
      response.data.length > 0 &&
        dispatch(change("medicalHistoryComponent", "medical_history", response.data[0].new_value));
    })
    .catch((error) => {
      toastBanner(error.message || "An error occured!");
    });
  }

  editMedicalHistory = (values) => {
    const { patient, user } = this.props;

    this.setState({
      isLoading: true,
    });

    this.ApiService.put(ENDPOINTS.patients.medicalHistory.base(patient.id), values)
      .then((response) => {
        const updatedMedicalHistory = {
          patient_id: patient.id,
          type: "medical_history",
          old_value: "",
          new_value: values.medical_history,
          created_by: {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`
          },
          created_at: new Date()
        }

        this.setState({
          medicalHistory: [updatedMedicalHistory],
          isLoading: false
        });
        this.setMode(MedicalHistoryComponent.MODES.display);
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  }

  toggleChangeLogModal = () => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );

  render() {
    const { medicalHistory, isLoading, showChangeLogModal, mode } = this.state;
    const { canEditMedicalHistory, patient, handleSubmit, pristine, submitting, showTitle } = this.props;

    if(isLoading) return this.renderLoading();

    return (
      <React.Fragment>
        {
          showChangeLogModal &&
            <ChangeLogModal
              type="medical_history"
              typeId={patient.id}
              onClose={this.toggleChangeLogModal}
              title={`Changes on Medical History for ${patient.first_name} ${patient.last_name}`}
            />
        }
        { 
          showTitle &&
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div>
                <h2 className="dashboard-card__title-text">Medical History
                  {
                    (medicalHistory.length > 0) &&
                      <span
                        className="button_link"
                        onClick={this.toggleChangeLogModal}
                        style={{fontSize:"1rem", marginLeft:"1rem", cursor:"pointer"}}>
                        View change log
                      </span>
                  }
                </h2>
              </div>
            </div>
        }
        <RestrictedComponent
          displayErrorMessage={true}
          errorComponent={() => (
            <div className="dashboard-card">
              <div style={{position:"relative", height:"10rem"}}>
                <p className="dashboard-card__body-text">You don't have permission to view this.</p>
              </div>
            </div>
          )}
          condition={this.props.user.permissions.indexOf(PERMISSIONS.patient.patient_medical_history.create_patient_medical_history) > -1}>
          {isLoading ? (
            this.renderLoading()
          ) : (
            <React.Fragment>
              {
                mode === MedicalHistoryComponent.MODES.display &&
                  (
                    medicalHistory.length === 0 ? 
                      <div style={{fontSize: "2rem", color: "grey"}}>No Medical History</div>
                      :
                      <React.Fragment>
                        <h2 style={{wordBreak: "break-word", marginBottom:"1rem", fontWeight:"normal"}} className="dashboard-card__body-text">
                          {nl2br(medicalHistory[0].new_value)}
                        </h2>
                        <p className="dashboard-card__body-text" style={{fontSize:"1.3rem", marginBottom:"1rem"}}>Edited by: {medicalHistory[0].created_by.name}</p>
                        <p className="dashboard-card__body-text" style={{fontSize: "1rem", color:"#565656", marginBottom:"1rem"}}>
                          Last recorded at: {moment(medicalHistory[0].created_at).calendar()}
                        </p>
                      </React.Fragment>
                  )
              }
              {
                canEditMedicalHistory &&
                  <React.Fragment>
                    <form className="dashboard-form" onSubmit={handleSubmit(this.editMedicalHistory)}>
                      {
                        mode === MedicalHistoryComponent.MODES.edit &&
                          <div>
                            <div>
                              <Field component="textarea" className="patient-notebox" placeholder="Enter Medical History" name="medical_history" required/>
                            </div>
                            <button
                              className="dashboard__primary-btn dashboard-form__btn"
                              type="submit"
                              disabled={pristine || submitting}>
                              Save
                            </button>
                            <span
                              className="button_link"
                              onClick={()=>this.setMode(MedicalHistoryComponent.MODES.display)}
                              style={{fontSize:"1.5rem", cursor:"pointer"}}>
                              Cancel
                            </span>
                          </div>
                      }
                    </form>
                    {
                      mode === MedicalHistoryComponent.MODES.display &&
                        <button
                          className="dashboard__primary-btn dashboard-form__btn"
                          type="submit"
                          onClick={() => this.setMode(MedicalHistoryComponent.MODES.edit)}>
                          Edit Medical History
                        </button>
                    }
                </React.Fragment>
              }
            </React.Fragment>
          )}
        </RestrictedComponent>
      </React.Fragment>
    );
  }
}

MedicalHistoryComponent.MODES = {
  edit: "edit",
  display: "display"
};

MedicalHistoryComponent = reduxForm({
  form: "medicalHistoryComponent"
})(MedicalHistoryComponent);

export default MedicalHistoryComponent;