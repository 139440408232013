import React from 'react';
import { PulseLoader } from 'react-spinners';
import {ApiService, ENDPOINTS} from "../services/ApiService";
import { toastBanner } from "../services/utilityService";

class PatientNotesComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isSending:false,
      noteInput: ''
    }
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  postNote = (note) => {
    const { patient, user, onPatientsNoteAdded } = this.props;

    this.setState({
      isSending: !this.state.isSending,
    });

    this.service.post(ENDPOINTS.patients.notes(patient.id), { note }).then((response) => {
      let newNote = {
        id: response.data.user_to_comment_id,
        user_id: user.id,
        note,
        created_by: {name: `${user.firstName} ${user.lastName}`},
        created_at: new Date()
      };

      onPatientsNoteAdded(newNote);
      toastBanner("New note added succesfully!", "success");
      this.setState({
        isSending: !this.state.isSending,
      });
    })
    .catch((error) => {
      toastBanner(error.message || "An error occured!");
    });
  };

  handleChange = (event) => {
    this.setState({
      noteInput: event.target.value
    })
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.postNote(this.state.noteInput);
    this.setState({
      noteInput: ''
    })
  };

  render(){
    const { isSending, noteInput } = this.state;

    return (
      <React.Fragment>
        <div className="dashboard-card" style={{padding:"1rem"}}>
          <form onSubmit={this.handleSubmit}>
            <div>
              <textarea className="patient-notebox" value={this.state.noteInput} onChange={this.handleChange} type="text" placeholder="Enter notes here..."/>
            </div>

            <div>
              <button className="dashboard__primary-btn dashboard-form__btn" {...(noteInput.length === 0 ? {disabled: true} : {})}>
                {isSending ? (
                  <PulseLoader color="#ffffff" size={7}/>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default PatientNotesComponent;
