import React from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';

class FilterableSelect extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      mode: "search",
      items: props.items,
      filteredItems:[],
      filterBy:props.filterBy,
      query: '',
      showDropDown:false,
      selectedItem:null,
    }
  }

  componentDidMount(){
    const { mode } = this.state;

    if (mode === FilterableSelect.MODES.search){ 
      let preSelected = this.props.items.filter(item =>item.id === this.props.input.value)[0];

      this.setState({
        filteredItems: this.state.items,
        selectedItem: preSelected || null,
        query: preSelected ? preSelected.name : ''
      })
    }
  }

  filterList = (query) => {
    if(query.length === 0){
      this.setState({
        filteredItems:this.state.items,
        query:query
      })
    }else{
      const l = this.state.items.filter(item=>{
        return this.state.filterBy.some(key=>{
          //return item[key] && item[key].toLowerCase().indexOf(query.toLowerCase()) > -1;
          return _.get(item,key,'').toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      });
      if(l.length === 0)
        l.push({id:-1,name:"no result"});
      this.setState({
        filteredItems:l,
        query:query
      })
    }
  };

  toggleDropDown = (show) => {
    this.setState({
      showDropDown:show
    })
  };
  
  setMode = (mode) => {
    this.setState({
      mode
    });
  }

  setValue = (selectedItem) => {
    const {input:{onChange}, clearOnSelect} = this.props;
    this.setState({
      selectedItem,
      query: clearOnSelect ? "" : selectedItem.name,
      showDropDown:false
    });
    if(onChange) {
      onChange(
        selectedItem
      );
    }else{
      console.log("ON CHANGE IS NULL");
    }
  };

  render(){
    const { mode, filteredItems, showDropDown, query } = this.state;
    const { 
      input: {
        className: inputClassName
      }, 
      placeholder, className, canAddBtn, 
    } = this.props;

    return (
      <div className={className} style={{display:"flex",flexDirection:"column", position:"relative"}}>
        {
          mode === FilterableSelect.MODES.add ?
            <React.Fragment>
              <span className="dashboard-form-item" style={{ marginBottom:"2rem" }}>
                <label
                  className="profile-item__label"
                  htmlFor="">
                    Add Diagnoses Category
                </label>
                <input 
                  type="text" 
                  value={this.state.input} 
                  onChange={(e) => this.setValue({name: e.target.value})}
                />
                <span
                  className="button_link"
                  onClick={()=>this.setMode(FilterableSelect.MODES.search)}
                  style={{fontSize:"1.5rem", cursor:"pointer"}}>
                  Search again?
                </span>
              </span>
            </React.Fragment>
            :
            <React.Fragment>
              <input
                type="text"
                className={inputClassName}
                placeholder={placeholder}
                onInput={(e)=>this.filterList(e.target.value)}
                onChange={(e)=>this.filterList(e.target.value)}
                onFocus={()=>this.toggleDropDown(true)}
                onBlur={()=>this.toggleDropDown(false)}
                value={query}
              />
              {showDropDown &&
                <ul className="dropdown" style={{maxHeight:"20rem",overflowY:"scroll",borderBottom:"1px solid rgba(9, 64, 99, 0.1)",zIndex:"9999", position:"absolute",top:"100%",left:"0", width:"100%",background:"white"}} >
                  {canAddBtn &&
                    <li 
                      style={{cursor:"pointer"}} 
                      onMouseDown={() => this.setMode(FilterableSelect.MODES.add)}
                    >
                      <div className="item" style={{display: "flex", backgroundColor: "rgba(9, 64, 99, 0.06)"}}>
                        <p className="text-main">Add New</p>
                        <i className="fas fa-plus" style={{margin: "1.5rem 0 0 1rem", fontSize: "1.5rem"}}></i>
                      </div>
                    </li>
                  }
                  {filteredItems.map(item=>(
                      <li key={item.id} style={{cursor:"pointer"}} onMouseDown={()=>this.setValue(item)}>
                        <div className="item">
                          <p className="text-main">{item.name}</p>
                          {this.props.extraKey && <p className="text-sub">{item[this.props.extraKey]}</p>}
                        </div>
                      </li>
                  ))}
                </ul>
              }
          </React.Fragment>
        }
      </div>
    )
  }
}

FilterableSelect.MODES = {
  search: "search",
  add: "add"
};

FilterableSelect.propTypes = {
  items:PropTypes.array,
  filterBy:PropTypes.array,
  placeholder:PropTypes.string,
  extraKey:PropTypes.string,
  canAddBtn:PropTypes.bool,
  clearOnSelect:PropTypes.bool,
  className: PropTypes.string,
  input: PropTypes.shape({
    // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.instanceOf(Function)
  })
};

export default FilterableSelect;