import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import moment from 'moment';
import numberFormatter from 'number-formatter'
import queryString from 'querystring';
import {ApiService, ENDPOINTS} from "../services/ApiService";
import PageBody from "../components/boxes/PageBody";
import Accordion from "../components/boxes/Accordion";
import EncounterNotesForm from "../components/forms/EncounterNotesForm";
import EncounterCommentForm from "../components/EncounterCommentComponent";
import LoadingScreen from "../components/LoadingScreen";
import AppointmentModal from "../components/modals/AppointmentModal";
import PaCodeModal from "../components/modals/PaCodeModal";
import ProcedureModal from "../components/modals/ProcedureModal";
import AllergyModal from "../components/modals/AllergyModal";
import MedicationModal from "../components/modals/MedicationModal";
import PatientNotesComponent from "../components/PatientNotesComponent";
import MedicalHistoryComponent from "../components/MedicalHistoryComponent";
import TestListModal from "../components/modals/TestListModal";
import DiagnosisModal from "../components/modals/DiagnosisModal";
import { toastBanner, PERMISSIONS, STAGEDATA} from "../services/utilityService";
import RestrictedComponent from "../components/utils/RestrictedComponent";

class NewEncounterNoteContainer extends React.Component {

  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      encounter:null,
      isLoading:false,
      encounterNote: null,
      encounterCommentsData: [],
      patientNotes: [],
      allergies:[],
      prescriptions:[],
      tests:[],
      diagnoses:[],
      procedures: [],
      pacodes: [],
      showBookAppointmentModal:false,
      showAddAllergyModal:false,
      showAddDiagnosesModal:false,
      showAddPaCodeModal: false,
      showAddProcedureModal: false,
      showAddPrescriptionModal: false,
      showTestListModal: false
    }
  }

  componentDidMount(){
    const { location } = this.props;
    const eId = queryString.parse(location.search.substr(1)).e_id;
    if(eId){
      this.setLoading(true);
      this.service.get(ENDPOINTS.encounters.encounter(eId))
        .then(response => {
          const encounter = response.data;
          this.setState({ 
            isLoading: false,
            encounterNote: (encounter.note.chief_complaint || encounter.note.observation || encounter.note.treatment ) ? encounter.note : null,
            encounter,
            diagnoses: encounter.diagnoses,
            allergies: encounter.allergies,
            pacodes: encounter.pacodes,
            procedures: encounter.procedures,
            prescriptions: encounter.prescriptions || [],
            tests:encounter.tests || []
          });
        })
        .catch(error => {
          this.setLoading(false);
          toastBanner(error.message || "An error occurred!");
        });
      this.displayEncounterComments(eId);
      this.displayNotes();
    }else{
      toastBanner("Encounter not found");
    }
  }

  displayEncounterComments = (encounterId) => {
    this.setLoading(true);
    this.service.get(ENDPOINTS.encounters.comments(encounterId)).then((response) => {
      this.setState({
        isLoading:false,
        encounterCommentsData: response.data
      })
    }).catch((error) => (toastBanner(error.message)));
  };

  onEncounterCommentsAdded = (encounterComment) => {
    const { encounterCommentsData } = this.state;

    let updatedEncounterComments = encounterCommentsData;
    updatedEncounterComments.unshift(encounterComment);

    this.setState({
      encounterCommentsData: updatedEncounterComments
    });
  };

  displayNotes = () => {
    const { patient } = this.props;
    this.setLoading(true);
    this.service.get(ENDPOINTS.patients.notes(patient.id)).then((response) => {
      this.setState({
        isLoading:false,
        patientNotes: response.data
      })
    }).catch((error) => (toastBanner(error.message)));
  };

  onPatientsNoteAdded = (patientNote) => {
    const { patientNotes } = this.state;

    let updatedPatientNotes = patientNotes;
    updatedPatientNotes.unshift(patientNote);

    this.setState({
      patientNotes: updatedPatientNotes
    });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  onEncounterNoteSaved = (encounterNote) => {
    const successMessage = "Encounter Note saved succesfully!"

    this.setState({
      encounterNote
    });
    
    toastBanner(successMessage, "success");
  };

  onAllergiesAdded = (allergies) => {
    this.setState({
      showAddAllergyModal: false,
      allergies: [...this.state.allergies, ...allergies]
    })
  };

  onPrescriptionAdded = (prescription) => {
    this.setState({
      showAddPrescriptionModal: false,
      prescriptions: [...this.state.prescriptions, prescription]
    })
  };

  onProcedureAdded = (procedure) => {
    this.setState({
      showAddProcedureModal: false,
      procedures: [...this.state.procedures, ...procedure.procedures]
    })
  };

  onPaCodeAdded = (pacode) => {
    this.setState({
      showAddPaCodeModal: false,
      pacodes: [...this.state.pacodes, pacode]
    })
  };

  onTestsAdded = (data) => {
    this.setState({
      showTestListModal: false,
      tests: [...this.state.tests, ...data.tests]
    });
  };

  onDiagnosesAdded = (diagnosesAdded) => {
    this.setState({
      showAddDiagnosesModal: false,
      diagnoses: [...this.state.diagnoses, ...diagnosesAdded]
    });
  };

  toggleBookAppointmentModal = () => {
    const { patient } = this.props;
    const { encounter } = this.state;

    this.setState({
      showBookAppointmentModal: !this.state.showBookAppointmentModal,
      selectedAppointment: { patient, id: encounter.id},
      selectedPatientId: patient.id
    })
  };

  toggleTestListModal = () => {
    this.setState({
      showTestListModal: !this.state.showTestListModal
    })
  };

  toggleDiagnosesModal = () => {
    this.setState({
      showAddDiagnosesModal: !this.state.showAddDiagnosesModal
    })
  };

  toggleShowAllergyModal = () => {
    this.setState({
      showAddAllergyModal: !this.state.showAddAllergyModal
    })
  };

  toggleShowPaCodeModal = () => {
    this.setState({
      showAddPaCodeModal: !this.state.showAddPaCodeModal
    })
  };

  toggleShowProcedureModal = () => {
    this.setState({
      showAddProcedureModal: !this.state.showAddProcedureModal
    })
  };

  toggleShowPrescriptionModal = () => {
    this.setState({
      showAddPrescriptionModal: !this.state.showAddPrescriptionModal
    })
  };

  onAppointmentBooked = () => {    
    this.setState({
      showBookAppointmentModal: false,
    });

    toastBanner("Follow-up appointment successfully booked!", "Success")
  };

  renderLoading = (title) => (
    <div className="dashboard-card">
      <h2 className="dashboard-card__title-text">{title}</h2>
      <div style={{position:"relative", height:"10rem"}}>
        <LoadingScreen />
      </div>
    </div>
  );

  render() {
    const { user, patient } = this.props;
    const {
      encounterNote,
      encounterCommentsData,
      patientNotes,
      encounter,
      isLoading,
      allergies,
      procedures,
      pacodes,
      prescriptions,
      diagnoses,
      tests,
      selectedAppointment,
      selectedPatientId,
      showBookAppointmentModal,
      showAddAllergyModal,
      showAddPrescriptionModal,
      showAddPaCodeModal,
      showAddProcedureModal,
      showAddDiagnosesModal,
      showTestListModal
    } = this.state;

    if(isLoading) return (<PageBody><LoadingScreen /></PageBody>);

    return (
      <PageBody>
        <div style={{display: "flex", flexDirection:"column"}}>
          <button
            onClick={() => this.toggleBookAppointmentModal() }
            className="dashboard__secondary-btn"
            style={{alignSelf:"flex-end", marginBottom:"1rem"}}
            >
            Book Follow-up Appointment
          </button>
          <button className="dashboard__secondary-btn" disabled={!encounterNote} style={{alignSelf:"flex-end"}}>
            {encounterNote ? (<Link to={`/overview?e_id=${encounter.id}`}>End Consultation</Link>) : ('End Consultation')}
          </button>

          {showBookAppointmentModal &&
            <AppointmentModal
              onClose={this.toggleBookAppointmentModal}
              patientId={selectedPatientId}
              isFollowUp
              mode={"edit"}
              selectedAppointment={selectedAppointment}
              userToken={this.props.user.credentials.accessToken}
              onAppointmentBooked={this.onAppointmentBooked}
            />
          }
           
          {showTestListModal &&
            <TestListModal
              patient={patient}
              encounterId={encounter.id}
              userToken={user.credentials.accessToken}
              onTestsAdded={this.onTestsAdded}
              onClose={this.toggleTestListModal}/>
          }

          {showAddAllergyModal &&
            <AllergyModal
              patientId={patient.id}
              onClose={this.toggleShowAllergyModal}
              userToken={user.credentials.accessToken}
              onAllergiesAdded={this.onAllergiesAdded}/>
          }

          {showAddProcedureModal &&
            <ProcedureModal
              encounterId={encounter.id}
              onClose={this.toggleShowProcedureModal}
              userToken={user.credentials.accessToken}
              onProcedureAdded={this.onProcedureAdded}
              patient={patient}/>
          }

          {showAddPaCodeModal &&
            <PaCodeModal
              encounterId={encounter.id}
              onClose={this.toggleShowPaCodeModal}
              userToken={user.credentials.accessToken}
              onPaCodeAdded={this.onPaCodeAdded}
              pacodes={pacodes}
              patient={patient}
              />
          }

          {showAddDiagnosesModal &&
            <DiagnosisModal
              encounterId={encounter.id}
              onClose={this.toggleDiagnosesModal}
              userToken={user.credentials.accessToken}
              onDiagnosesAdded={this.onDiagnosesAdded}/>
          }

          {showAddPrescriptionModal &&
            <MedicationModal
              patient={patient}
              encounterId={encounter.id}
              userToken={user.credentials.accessToken}
              onPrescriptionAdded={this.onPrescriptionAdded}
              onClose={this.toggleShowPrescriptionModal}/>
          }

          {encounter &&
            <React.Fragment>
              <div className="dashboard-card dashboard-table-overflow" style={{marginTop:"1rem"}}>
                <h1 className="dashboard-card__title-text dashboard-table-overflow">Vitals Recorded</h1>
                <RestrictedComponent
                  displayErrorMessage={true}
                  errorComponent={() => (
                    <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                  )}
                  condition={this.props.user.permissions.indexOf(PERMISSIONS.encounter.encounter_note.read_encounter_note) > -1}>

                  {(!(encounter.vitals && encounter.vitals.length > 0) && encounter.current_stage_id > STAGEDATA.stages.yetToArrive) ? (
                    <RestrictedComponent
                      displayErrorMessage={false}
                      condition={this.props.user.permissions.indexOf(PERMISSIONS.encounter.encounter_vital.create_encounter_vital) > -1}>
                      <button className="dashboard__secondary-btn">
                        <Link to={`/patients/${encounter.patient.id}/vitals/new?e_id=${encounter.id}`}>
                          Record Vitals
                        </Link>
                      </button>
                    </RestrictedComponent>
                  ) : (
                    <React.Fragment>
                      {encounter.vitals && encounter.vitals.length === 0 ? (
                        <div style={{fontSize: "2rem", color: "grey", paddingBottom: "2rem"}}>No Vital found</div>
                      ) : (
                        <div className="basic-info">
                          <div style={{ width: "50%", marginRight: "1rem"}}>
                            <div className="dashboard-table-overflow">
                              <table className="dashboard-table">
                                <tbody>
                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">Height</th>
                                  <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].height}cm</td>
                                </tr>

                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">Weight</th>
                                  <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].weight}kg</td>
                                </tr>

                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">Blood Pressure</th>
                                  <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].systolic_blood_pressure} / {encounter.vitals[0].diastolic_blood_pressure} mmHg</td>
                                </tr>

                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">Temperature</th>
                                  <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].temperature} °C</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div style={{ width: "50%", marginRight: "1rem"}}>
                            <div className="dashboard-table-overflow">
                              <table className="dashboard-table">
                                <tbody>
                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">BMI</th>
                                  <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].bmi ? `${encounter.vitals[0].bmi}` : "Not Available"}</td>
                                </tr>

                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">Heart Rate</th>
                                  <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].heart_rate ? `${encounter.vitals[0].heart_rate} per minute` : "Not Available"}</td>
                                </tr>

                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">Respiratory Rate</th>
                                  <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].respiratory_rate ? `${encounter.vitals[0].respiratory_rate} per minute` : 'Not Available'}</td>
                                </tr>

                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">Oxygen Saturation</th>
                                  <td className="dashboard-table-item dashboard-table-item__body">{encounter.vitals[0].oxygen_saturation ? `${encounter.vitals[0].oxygen_saturation} %` : 'Not Available'}</td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>

                  )}
                </RestrictedComponent>
              </div>
              <div className="basic-info" style={{marginTop:"1rem"}}>
                <div style={{ width: "65%", marginRight: "1rem"}}>
                  <Accordion
                    title="Encounter Note"
                    component={() => (
                      <RestrictedComponent
                        displayErrorMessage={true}
                        errorComponent={() => (
                          <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                        )}
                        condition={user.permissions.indexOf(PERMISSIONS.encounter.encounter_note.create_encounter_note) > -1}>

                        <EncounterNotesForm
                          encounterNote={encounterNote}
                          onEncounterNoteSaved={this.onEncounterNoteSaved}
                          userToken={user.credentials.accessToken}
                          encounterId={encounter.id}
                        />

                      </RestrictedComponent>
                    )}
                    open={!encounterNote}/>
                  <Accordion title="Diagnoses" component={() => (
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <RestrictedComponent
                        displayErrorMessage={true}
                        errorComponent={() => (
                          <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                        )}
                        condition={user.permissions.indexOf(PERMISSIONS.encounter.encounter_diagnosis.create_encounter_diagnosis) > -1}>
                        <button
                          onClick={this.toggleDiagnosesModal}
                          className="dashboard__secondary-btn"
                          style={{alignSelf:"flex-end", marginBottom:"1rem"}}>
                          Add Diagnosis
                        </button>
                      </RestrictedComponent>
                      {diagnoses.length === 0 ? (
                        <div style={{fontSize: "2rem", color: "grey"}}>No Diagnosis</div>
                      ) : (
                        <RestrictedComponent
                          displayErrorMessage={true}
                          errorComponent={() => (
                            <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                          )}
                          condition={user.permissions.indexOf(PERMISSIONS.encounter.encounter_diagnosis.read_encounter_diagnosis) > -1}>
                          <div className="dashboard-table-overflow">
                            <table className="dashboard-table">
                              <thead>
                              <tr>
                                <th className="dashboard-table-item dashboard-table-item__header">Diagnosis</th>
                                <th className="dashboard-table-item dashboard-table-item__header">Date Created</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                diagnoses.map((diagnosis, index)=>(
                                  <tr key={`${diagnosis.id}-${index}`} >
                                    <td className="dashboard-table-item dashboard-table-item__body">{diagnosis.name}</td>
                                    <td className="dashboard-table-item dashboard-table-item__body">{moment(diagnosis.created_at).format('MMMM Do YYYY')}</td>
                                  </tr>
                                ))
                              }
                              </tbody>
                            </table>
                          </div>
                        </RestrictedComponent>
                      )}
                    </div>
                  )}/>
                  <Accordion
                    title="Allergies"
                    component={() => (
                      <div style={{display:"flex", flexDirection:"column"}}>
                        <RestrictedComponent
                          displayErrorMessage={true}
                          errorComponent={() => (
                            <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                          )}
                          condition={user.permissions.indexOf(PERMISSIONS.patient.patient_allergy.create_patient_allergy) > -1}>

                          <button
                            onClick={this.toggleShowAllergyModal}
                            className="dashboard__secondary-btn"
                            style={{alignSelf:"flex-end", marginBottom:"1rem"}}>
                            Add Allergy
                          </button>

                        </RestrictedComponent>
                        {allergies.length === 0 ? (
                          <div style={{fontSize: "2rem", color: "grey"}}>No Allergies</div>
                        ) : (
                          <RestrictedComponent
                            displayErrorMessage={true}
                            errorComponent={() => (
                              <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                            )}
                            condition={user.permissions.indexOf(PERMISSIONS.patient.patient_allergy.read_patient_allergy) > -1}>


                            <div className="dashboard-table-overflow">
                              <table className="dashboard-table">
                                <thead>
                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">Allergen</th>
                                  <th className="dashboard-table-item dashboard-table-item__header">Reaction</th>
                                  <th className="dashboard-table-item dashboard-table-item__header">Date Created</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                  allergies.map((allergy, index)=>(
                                    <tr key={`${allergy.id}-${index}`} >
                                      <td className="dashboard-table-item dashboard-table-item__body">{allergy.name}</td>
                                      <td className="dashboard-table-item dashboard-table-item__body">{allergy.reaction || allergy.description}</td>
                                      <td className="dashboard-table-item dashboard-table-item__body">{moment(allergy.created_at).format('MMMM Do YYYY')}</td>
                                    </tr>
                                  ))
                                }
                                </tbody>
                              </table>
                            </div>

                          </RestrictedComponent>
                        )}
                      </div>
                    )}/>

                  <Accordion
                    title="Procedures"
                    component={() => (
                      <div style={{display:"flex", flexDirection:"column"}}>
                        <RestrictedComponent
                          displayErrorMessage={true}
                          errorComponent={() => (
                            <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                          )}
                          condition={user.permissions.indexOf(PERMISSIONS.patient.patient_allergy.create_patient_allergy) > -1}>

                          <button
                            onClick={this.toggleShowProcedureModal}
                            className="dashboard__secondary-btn"
                            style={{alignSelf:"flex-end", marginBottom:"1rem"}}>
                            Add Procedure
                          </button>

                        </RestrictedComponent>
                        {procedures.length === 0 ? (
                          <div style={{fontSize: "2rem", color: "grey"}}>No Procedures</div>
                        ) : (
                          <RestrictedComponent
                            displayErrorMessage={true}
                            errorComponent={() => (
                              <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                            )}
                            condition={user.permissions.indexOf(PERMISSIONS.patient.patient_allergy.read_patient_allergy) > -1}>


                            <div className="dashboard-table-overflow">
                              <table className="dashboard-table">
                                <thead>
                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">Summary</th>
                                  <th className="dashboard-table-item dashboard-table-item__header">Date Created</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                  procedures.map((procedure, index)=>(
                                    <tr key={`${procedure.id}-${index}`} >
                                      <td className="dashboard-table-item dashboard-table-item__body">{procedure.summary}</td>
                                      <td className="dashboard-table-item dashboard-table-item__body">{moment(procedure.created_at).format('MMMM Do YYYY')}</td>
                                    </tr>
                                  ))
                                }
                                </tbody>
                              </table>
                            </div>

                          </RestrictedComponent>
                        )}
                      </div>
                    )}/>

                  <Accordion
                    title="PA Code"
                    component={() => (
                      <div style={{display:"flex", flexDirection:"column"}}>
                        <RestrictedComponent
                          displayErrorMessage={true}
                          errorComponent={() => (
                            <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                          )}
                          condition={user.permissions.indexOf(PERMISSIONS.patient.patient_allergy.create_patient_allergy) > -1}>

                          <button
                            onClick={this.toggleShowPaCodeModal}
                            className="dashboard__secondary-btn"
                            style={{alignSelf:"flex-end", marginBottom:"1rem"}}>
                            Add PA Code
                          </button>

                        </RestrictedComponent>
                        {pacodes.length === 0 ? (
                          <div style={{fontSize: "2rem", color: "grey"}}>No PA Code</div>
                        ) : (
                          <RestrictedComponent
                            displayErrorMessage={true}
                            errorComponent={() => (
                              <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                            )}
                            condition={user.permissions.indexOf(PERMISSIONS.patient.patient_allergy.read_patient_allergy) > -1}>


                            <div className="dashboard-table-overflow">
                              <table className="dashboard-table">
                                <thead>
                                <tr>
                                  <th className="dashboard-table-item dashboard-table-item__header">PA Code</th>
                                  <th className="dashboard-table-item dashboard-table-item__header">Description</th>
                                  <th className="dashboard-table-item dashboard-table-item__header">Date Created</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                  pacodes.map((pacode, index)=>(
                                    <tr key={`${pacode.id}-${index}`} >
                                      <td className="dashboard-table-item dashboard-table-item__body">{pacode.pacode}</td>
                                      <td className="dashboard-table-item dashboard-table-item__body">{pacode.description}</td>
                                      <td className="dashboard-table-item dashboard-table-item__body">{moment(pacode.created_at).format('MMMM Do YYYY')}</td>
                                    </tr>
                                  ))
                                }
                                </tbody>
                              </table>
                            </div>

                          </RestrictedComponent>
                        )}
                      </div>
                    )}/>
                  <Accordion title="Prescriptions" component={() => (
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <RestrictedComponent
                        displayErrorMessage={true}
                        errorComponent={() => (
                          <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                        )}
                        condition={user.permissions.indexOf(PERMISSIONS.prescription.create_prescription) > -1}>
                        <button
                          onClick={this.toggleShowPrescriptionModal}
                          className="dashboard__secondary-btn"
                          style={{alignSelf:"flex-end", marginBottom:"1rem"}}>
                          Add Prescription
                        </button>
                      </RestrictedComponent>
                      {prescriptions.length === 0 ? (
                        <div style={{fontSize: "2rem", color: "grey"}}>No Prescriptions</div>
                      ) : (
                        <RestrictedComponent
                          displayErrorMessage={true}
                          errorComponent={() => (
                            <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                          )}
                          condition={user.permissions.indexOf(PERMISSIONS.prescription.read_prescription) > -1}>
                          <div className="dashboard-table-overflow">
                            <table className="dashboard-table">
                              <thead>
                              <tr>
                                <th className="dashboard-table-item dashboard-table-item__header">Drugs</th>
                                <th className="dashboard-table-item dashboard-table-item__header">Price</th>
                                <th className="dashboard-table-item dashboard-table-item__header">Date Created</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                prescriptions.map((prescription, index)=>(
                                  <tr key={`${prescription.id}-${index}`} >
                                    <td className="dashboard-table-item dashboard-table-item__body">{prescription.medications.map(m => `${m.name} x ${m.quantity || 1}`).join(", ")}</td>
                                    <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter( "#,###.00", prescription.medications.reduce((total, cur) =>  total + parseInt(cur.total_price),0))}</td>
                                    <td className="dashboard-table-item dashboard-table-item__body">{moment(prescription.created_at).format('MMMM Do YYYY')}</td>
                                  </tr>
                                ))
                              }
                              </tbody>
                            </table>
                          </div>
                        </RestrictedComponent>
                      )}
                    </div>
                  )}/>
                  <Accordion title="Test/Diagnostics" component={() => (
                    <div style={{display:"flex", flexDirection:"column"}}>
                      <RestrictedComponent
                        displayErrorMessage={true}
                        errorComponent={() => (
                          <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                        )}
                        condition={user.permissions.indexOf(PERMISSIONS.encounter.encounter_test.create_encounter_test) > -1}>

                        <button
                          onClick={this.toggleTestListModal}
                          className="dashboard__secondary-btn"
                          style={{alignSelf:"flex-end", marginBottom:"1rem"}}>
                          Add Test/Diagnostics
                        </button>

                      </RestrictedComponent>

                      {tests.length === 0 ? (
                        <div style={{fontSize: "2rem", color: "grey"}}>No Tests</div>
                      ) : (
                        <RestrictedComponent
                          displayErrorMessage={true}
                          errorComponent={() => (
                            <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                          )}
                          condition={user.permissions.indexOf(PERMISSIONS.encounter.encounter_test.read_encounter_test) > -1}>

                          <div className="dashboard-table-overflow">
                            <table className="dashboard-table">
                              <thead>
                              <tr>
                                <th className="dashboard-table-item dashboard-table-item__header">Tests</th>
                                <th className="dashboard-table-item dashboard-table-item__header">Price</th>
                                <th className="dashboard-table-item dashboard-table-item__header">Date Created</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                tests.map((test, index)=>(
                                  <tr key={`${test.id}-${index}`} >
                                    <td className="dashboard-table-item dashboard-table-item__body">{test.name}</td>
                                    <td className="dashboard-table-item dashboard-table-item__body">{test.price}</td>
                                    <td className="dashboard-table-item dashboard-table-item__body">{moment(test.created_at).format('MMMM Do YYYY')}</td>
                                  </tr>
                                ))
                              }
                              </tbody>
                            </table>
                          </div>

                        </RestrictedComponent>
                      )}
                    </div>
                  )}/>
                </div>
                <div style={{ width: "35%"}}>
                  <Accordion
                    title={`Patient Notes (${patientNotes.length})`}
                    component={() => (
                      <RestrictedComponent
                        displayErrorMessage={true}
                        errorComponent={() => (
                          <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                        )}
                        condition={user.permissions.indexOf(PERMISSIONS.patient.patient_note.read_patient_note) > -1}>

                        <div className="dashboard-card">
                          <div style={{ height: "24rem", overflow:"scroll"}}>
                            {patientNotes.length > 0 && patientNotes.map((note, index) => (
                              <div key={note.id} className="dashboard-card" style={{padding: "1rem",borderBottom: "2px solid #f8f8f8"}}>
                                <p className="dashboard-card__body-text" style={{fontSize:"1.3rem", marginBottom:"1rem"}}>{note.created_by.name} wrote:</p>

                                <h2 style={{wordBreak: "break-word", marginBottom:"1rem"}} className="dashboard-card__body-text">
                                  {note.note}
                                </h2>
                                <p className="dashboard-card__body-text" style={{fontSize: "1rem", color:"#565656", marginBottom:"1rem"}}>{moment(note.created_at).calendar()}</p>
                              </div>
                            ))
                            }
                            {patientNotes.length === 0 && <div className="dashboard-card" style={{fontSize: "1.5rem", color: "grey", marginTop:"1rem"}}>Nothing to see here :)</div>}
                          </div>
                          <PatientNotesComponent
                            patient={patient}
                            user={user}
                            onPatientsNoteAdded={this.onPatientsNoteAdded}/>
                        </div>

                      </RestrictedComponent>
                    )}
                  />

                  <Accordion
                    title={`Encounter Comments (${encounterCommentsData.length})`}
                    component={() => (
                      <RestrictedComponent
                        displayErrorMessage={true}
                        errorComponent={() => (
                          <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                        )}
                        condition={user.permissions.indexOf(PERMISSIONS.encounter.encounter_comment.read_encounter_comment) > -1}>
                          <div className="dashboard-card">
                            <div style={{ height: "24rem", overflow:"scroll"}}>
                              {encounterCommentsData.length > 0 && encounterCommentsData.map((encounterComment, index) => (
                                <div key={encounterComment.id} className="dashboard-card" style={{padding: "1rem",borderBottom: "2px solid #f8f8f8"}}>
                                  <p className="dashboard-card__body-text" style={{fontSize:"1.3rem", marginBottom:"1rem"}}>{encounterComment.created_by.name} wrote:</p>

                                  <h2 style={{wordBreak: "break-word", marginBottom:"1rem"}} className="dashboard-card__body-text">
                                    {encounterComment.comment}
                                  </h2>
                                  <p className="dashboard-card__body-text" style={{fontSize: "1rem", color:"#565656", marginBottom:"1rem"}}>{moment(encounterComment.created_at).calendar()}</p>
                                </div>
                              ))
                              }
                              {encounterCommentsData.length === 0 && <div className="dashboard-card" style={{fontSize: "1.5rem", color: "grey", marginTop:"1rem"}}>Nothing to see here :)</div>}
                            </div>

                            <EncounterCommentForm
                              user={user}
                              patient={patient}
                              encounterId={encounter.id}
                              onEncounterCommentsAdded={this.onEncounterCommentsAdded}
                            />
                          </div>
                      </RestrictedComponent>
                    )}
                  />

                  <Accordion
                    title="Patient Medical History"
                    component={() => (
                      <RestrictedComponent
                        displayErrorMessage={true}
                        errorComponent={() => (
                          <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                        )}
                        condition={user.permissions.indexOf(PERMISSIONS.patient.patient_medical_history.create_patient_medical_history) > -1}>
                          <div className="dashboard-card">                            
                            <MedicalHistoryComponent
                              user={user}
                              patient={encounter.patient}
                              canEditMedicalHistory
                            />
                          </div>
                      </RestrictedComponent>
                    )}
                  />
                </div>
              </div>
            </React.Fragment>
          }
        </div>
      </PageBody>
    )
  }
}

export default withRouter(NewEncounterNoteContainer);
