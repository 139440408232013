import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router";
import { Route, Switch, withRouter } from "react-router-dom";

import BranchSelection from "../components/BranchSelection";
import PageNotFound from "../components/PageNotFound";
import PropsRoute from "../components/utils/PropsRoute";
import AppointmentsContainer from "../containers/AppointmentsContainer";
import OverviewContainer from "../containers/OverviewContainer";
import ClaimRoutes from "./ClaimRoutes";
import EncountersRoutes from "./EncountersRoutes";
import InvoicesRoutes from "./InvoicesRoutes";
import NPSRoutes from "./NPSRoutes";
import PatientsRoutes from "./PatientsRoutes";
import PaymentsRoutes from "./PaymentsRoutes";

const AppRoutes = ({ authenticated, user }) => (
  <>
   
      <Switch>
        <PropsRoute
          component={OverviewContainer}
          path="/overview"
          authenticated={authenticated}
          user={user}
          exact
        />
        <PropsRoute
      component={BranchSelection}
      path="/branch"
      authenticated={authenticated}
      user={user}
      exact
    />
        <PropsRoute
          component={AppointmentsContainer}
          path="/appointments"
          authenticated={authenticated}
          user={user}
          exact
        />
        <PropsRoute
          component={EncountersRoutes}
          path="/encounters/"
          authenticated={authenticated}
          user={user}
        />
        <PropsRoute
          component={PatientsRoutes}
          path="/patients/"
          authenticated={authenticated}
          user={user}
        />
        <PropsRoute
          component={InvoicesRoutes}
          path="/invoices/"
          authenticated={authenticated}
          user={user}
        />
        <PropsRoute
          component={PaymentsRoutes}
          path="/payments/"
          authenticated={authenticated}
          user={user}
        />
        <PropsRoute
          component={ClaimRoutes}
          path="/claims/"
          authenticated={authenticated}
          user={user}
        />
        <PropsRoute
          component={NPSRoutes}
          path="/nps-feedback/"
          authenticated={authenticated}
          user={user}
        />
           {/* <PropsRoute
          component={Teletherapy}
          path="/teletherapy"
          authenticated={authenticated}
          user={user}
          exact
        /> */}
        <Route path="/" exact render={(props) => <Redirect to="/branch" />} />
        <Route component={PageNotFound} />
      </Switch>
  </>
);

AppRoutes.propTypes = {
  authenticated: PropTypes.bool,
  user: PropTypes.object,
};

export default withRouter(AppRoutes);
