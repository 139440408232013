/**
 * Created by the.last.mayaki on 23/09/2017.
 */

export const emptyStore = {
    display:{
        showSideBar:false
    }

};


export default emptyStore
