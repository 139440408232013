import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NPSForm from "../components/forms/NPSForm";
import { ApiService, ENDPOINTS } from "../services/ApiService";
import { toastBanner } from "../services/utilityService";
import MainContent from "../components/boxes/MainContent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import LoadingScreen from "../components/LoadingScreen";

class NewNPSContainer extends React.Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading: false,
      error: null,
    };
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  onSubmit = (values) => {
    this.setLoading(true);
    this.service
      .post(ENDPOINTS.nps.dispatch, values)
      .then(() => {
        this.setLoading(false);
        toastBanner("The NPS form has been succesfully sent.", "success");
        setTimeout(() => {
          window.location.replace('/nps-feedback');
        },1000);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        });
        toastBanner(error.message || "An error occured!");
      });
  };



  onCancel = () => {
    const { history } = this.props;
    history.push(`/nps-feedback`);
  };

  renderLoading = () => (
    <MainContent>
      <PageBody>
        <LoadingScreen />
      </PageBody>
    </MainContent>
  );

  setError = (err) => {
    this.setState({
      error: err,
    });
  };

  render() {
    const { nps_feedBack, isLoading } = this.state;

    if (isLoading) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader title={"FeedBack System"} />
        <PageBody>
          <NPSForm
            onSubmit={this.onSubmit}
            nps_feedBack={nps_feedBack}
            onCancel={this.onCancel}
          />
        </PageBody>
      </MainContent>
    );
  }
}

export default withRouter(connect()(NewNPSContainer));
