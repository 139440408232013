import * as React from "react";
import { ApiService, ENDPOINTS } from "../services/ApiService";
import { connect } from "react-redux";
import { toastBanner } from "../services/utilityService";
import LoadingScreen from "./LoadingScreen";
import { saveSession } from "../actions/user";
let BranchSelection = (props) => {
  const [selectedBranch, setSelectedBranch] = React.useState(
    props.user && props.user.active_branch_id ? props.user.active_branch_id : 1
  );
  const [loading, setLoading] = React.useState(false);
  let { user } = props;
  const selectBranch = (index) => {
    setSelectedBranch(user.branches[index].id);
  };

  const changeActiveBranch = (branch) => {
    const data = { active_branch_id: branch };
    let { dispatch } = props;
    const service = new ApiService(user.credentials.accessToken);
    setLoading(true);
    service
      .put(ENDPOINTS.users.user(user.id), data)
      .then(() => {
        setLoading(false);
        saveSession(dispatch, { ...user, active_branch_id: branch });
        toastBanner("You have successfully changed your location!", "success");
        setTimeout(() => window.location.replace("/overview"), 2000);
      })
      .catch((error) => {
        setLoading(false);
        toastBanner(error && error.message ? error.message : "An error occurred!", "error");
      });
  };
  return (
    <div className="branch-modal__container">
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="branch-selection__container">
          <div className="branch-selcection__close-modal">
            <svg
              onClick={user && user.branches && user.branches.length > 0 ? () => (window.location.href = "/overview") : () => (window.location.href = "/logout") }
              width="50"
              height="50"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="35" height="35" fill="white" fillOpacity="0.01" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.1851 7.4L12.0011 10.585L8.81513 7.399C8.71987 7.30584 8.59176 7.25393 8.45851 7.25449C8.32527 7.25505 8.19761 7.30804 8.10313 7.402L7.40313 8.103C7.35521 8.14897 7.31701 8.20409 7.29079 8.2651C7.26457 8.3261 7.25087 8.39175 7.2505 8.45815C7.25012 8.52455 7.26309 8.59035 7.28862 8.65165C7.31415 8.71295 7.35173 8.7685 7.39913 8.815L10.5841 11.999L7.40013 15.185C7.3071 15.2804 7.25537 15.4086 7.25612 15.5418C7.25686 15.6751 7.31004 15.8027 7.40413 15.897L8.10413 16.597C8.31013 16.804 8.62013 16.797 8.81613 16.601L12.0021 13.416L15.1861 16.601C15.2815 16.694 15.4097 16.7458 15.543 16.745C15.6762 16.7443 15.8038 16.6911 15.8981 16.597L16.5991 15.897C16.647 15.851 16.6851 15.7958 16.7112 15.7347C16.7374 15.6737 16.751 15.608 16.7513 15.5416C16.7515 15.4752 16.7385 15.4095 16.7129 15.3482C16.6873 15.2869 16.6496 15.2314 16.6021 15.185L13.4161 11.999L16.6021 8.815C16.6953 8.71974 16.7472 8.59163 16.7466 8.45838C16.7461 8.32514 16.6931 8.19748 16.5991 8.103L15.8991 7.403C15.8522 7.35497 15.7962 7.31672 15.7344 7.29046C15.6727 7.2642 15.6063 7.25045 15.5391 7.25C15.4733 7.25054 15.4083 7.26407 15.3477 7.28981C15.2871 7.31555 15.2322 7.35299 15.1861 7.4H15.1851Z"
                fill="#42526E"
              />
            </svg>
          </div>

          <h2 className="branch-selection__header">
            Change RFC Dashboard Location
          </h2>
          <div className="branch-selection__items-container">
            {user && user.branches && user.branches.length > 0 ? (
              user.branches.map((branch, index) => (
                <div
                  role="button"
                  key={branch.id}
                  onKeyDown={() => selectBranch(branch, index)}
                  onClick={() => selectBranch(index)}
                  className={`${
                    branch.id === selectedBranch ? "current-branch" : ""
                  } branch-selection`}
                >
                  <div className="branch-selection-tag">
                    {branch.id === selectedBranch ? (
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            fillOpacity="0.01"
                          />
                          <path
                            d="M6.73488 12.322C6.55434 12.13 6.30529 12.017 6.04189 12.0076C5.77849 11.9983 5.52205 12.0933 5.32832 12.272C5.13459 12.4507 5.01924 12.6986 5.00736 12.9619C4.99549 13.2252 5.08803 13.4826 5.26488 13.678L8.87688 17.597C9.41388 18.123 10.2139 18.123 10.7109 17.627L11.0749 17.268C12.3894 15.9752 13.7024 14.6809 15.0139 13.385L15.0539 13.345C16.2796 12.1371 17.4989 10.9227 18.7119 9.70199C18.8932 9.51218 18.9926 9.25872 18.9886 8.99628C18.9847 8.73384 18.8777 8.48348 18.6908 8.2992C18.5039 8.11493 18.2521 8.01152 17.9896 8.01129C17.7271 8.01106 17.4751 8.11404 17.2879 8.29799C16.0805 9.51232 14.8672 10.7207 13.6479 11.923L13.6079 11.963C12.3512 13.2053 11.0928 14.4459 9.83288 15.685L6.73488 12.322Z"
                            fill="#42526E"
                          />
                        </svg>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="branch-header">
                    <p
                      className={`${
                        branch.id === selectedBranch
                          ? "selected-branch-name"
                          : "branch-name"
                      }`}
                    >
                      {branch.name}
                    </p>
                    <p className="branch-address">
                      {branch.address}
                    </p>
                    {branch.id === selectedBranch ? (
                      <p
                        className={`${
                          branch.id === selectedBranch ? "active-branch" : ""
                        }`}
                      >
                        (Selected)
                      </p>
                    ) : (
                      <p className="branch-select">
                        {branch.id === user.active_branch_id
                          ? "(Current)"
                          : "(Click to select)"}
                      </p>
                    )}
                  </div>
                  <div className="branch-state-text">
                    <p className="branch-location">{branch.state}</p>
                  </div>
                </div>             
              ))
            ) : (
              <p className="branch-selection__small-text">
                You have not been assigned to a branch, please reach out to the
                admin for further assitance.
              </p>
            )}
          </div>
          {user && user.branches && user.branches.length && (
            <div className="branch-selection__submit-buttons">
              <button
                className="dashboard__primary-btn"
                onClick={() => changeActiveBranch(selectedBranch)}
              >
                Save
              </button>
              <button
                className="dashboard__secondary-btn"
                onClick={() => (window.location.href = "/overview")}
              >
                Cancel
              </button>
            </div>
          )}   
        </div>
      )}
    </div>
  );
};
BranchSelection = connect()(BranchSelection);

export default BranchSelection;
