import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import PropsRoute from "../components/utils/PropsRoute";
import PaymentsContainer from "../containers/PaymentsContainer";
import NewPaymentContainer from "../containers/NewPaymentContainer";

class PaymentsRoutes extends React.Component {
  render () {
    const {user} = this.props;
    return (
      <Switch>
        <PropsRoute path='/payments/view/:paymentId' user={user} exact component={()=>(<h1>Payments / View Payment</h1>)}/>
        <PropsRoute path='/payments/new' user={user} exact component={NewPaymentContainer}/>
        <PropsRoute
          path='/payments'
          user={user}
          exact
          component={
            (props)=>(
              <PaymentsContainer/>
            )}
        />
      </Switch>
    )
  }
}

export default withRouter(PaymentsRoutes);