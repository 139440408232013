import React from 'react';
import queryString from 'querystring';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import {withRouter} from 'react-router-dom';
import PatientForm from "../components/forms/PatientForm";
import {ApiService, ENDPOINTS} from "../services/ApiService";
import { toastBanner } from "../services/utilityService";
import MainContent from "../components/boxes/MainContent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import LoadingScreen from "../components/LoadingScreen";

class NewPatientContainer extends React.Component {

  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      user:{},
      error: null
    }
  }

  componentDidMount(){
    const { location } = this.props;
    const pId = queryString.parse(location.search.substr(1)).p_id;
    if(pId){
      this.setLoading(true);
      this.service.get(`${ENDPOINTS.users.base}/${pId}`)
        .then(response => {
          this.setState({
            isLoading: false,
            user: response.data
          })
        })
        .catch((error) => {
          toastBanner(error.message || "An error occured!");
        });
    }
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  onSubmit = (values) => {
    this.setLoading(true);

    if(!values.policy_code){
      delete values.policy_id;
    }

    const { patient, location } = this.props;
    if(patient){
      //edit patient
      this.service.put(ENDPOINTS.patients.patient(patient.id), values)
        .then(() => {
          //using window.location.replace cause history.push retains old user after navigating away
          window.location.replace(`/patients/${patient.id}`);
        })
        .catch(error => {
          this.setState({
            isLoading: false,
            error: error.message
          });
          toastBanner(error.message || "An error occured!");
        });
    }else{
      //if no user id exists, first call /signup to get a user id, then call POST /patients
      //else call POST /patients immediately

      const pId = queryString.parse(location.search.substr(1)).p_id;

      if(pId || values.user_id){
        //user id exists, so call POST /patients
        this.registerPatient(values);
      }else{
        // no user id, so call signup first, then POST /patients after
        this.signUpUser(values);
      }
    }
  };

  // validatePatient= (patientData, cb) => {
  //   return this.service.post(ENDPOINTS.validate, {username: patientData.policy_id})
  // };

  signUpUser = (values) => {
    return this.service.post(ENDPOINTS.signup, {
      first_name: values.first_name,
      last_name: values.last_name,
      email_address: values.email_address,
      phone_number: values.phone_number
    })
      .then(response => {
        values.user_id = response.data.id;
        return this.registerPatient(values);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message
        })
        toastBanner(error.message || "An error occured!");
      });
  };

  registerPatient = (patientData) => {
    const { history, location, dispatch } = this.props;

    return this.service.post(ENDPOINTS.patients.base, patientData)
      .then(response => {
        dispatch(reset("newPatientForm"));
        const pId = queryString.parse(location.search.substr(1)).p_id;
        if(pId){
          history.push(`/overview?p_id=${response.data.user_id}`);
        }else{
          history.push(`/patients/${response.data.user_id}`);
        }
      })
        .catch(error => {
          this.setState({
            isLoading: false,
            error: error.message
          })
          toastBanner(error.message || "An error occured!");
        })
  };

  onCancel = () => {
    const { history } = this.props;

    history.push(`/patients`);
  }

  componentWillUnmount(){
    const { dispatch } = this.props;
    dispatch(reset("newPatientForm"));
  }

  renderLoading = () => (
    <MainContent>
      <PageBody>
        <LoadingScreen />
      </PageBody>
    </MainContent>
  );

  setError = (err) => {
    this.setState({
      error: err
    })
  };

  render(){
    const { patient } = this.props;
    const { user, isLoading } = this.state;

    if(isLoading) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader title={patient ? "Edit Patient" :"Add New Patient"}/>
        <PageBody>
          {/* {error &&
            <div className="page-info-banner appointments-banner error" >
              <p>{error}</p>
              <span onClick={() => this.setError()}><i className="fa fa-times"/></span>
            </div>
          } */}
          <PatientForm
            onSubmit={this.onSubmit}
            user={user}
            patient={patient}
            userToken={this.props.user.credentials.accessToken}
            onCancel={this.onCancel}/>
        </PageBody>
      </MainContent>
    );
  }
}

export default withRouter(connect()(NewPatientContainer))