import React, { Component } from 'react';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
// import LoadingScreen from '../components/LoadingScreen';
import CenteredContentBlock from '../components/boxes/CenteredContentBlock';

const payments = [
  {
    invoiceId: "INV114991",
    patient: "James Ibori",
    amount: 2500,
    date: "11/04/2019",
    paidBy: "Jane Doe",
    actions: "Print"
  },
  {
    invoiceId: "INV114992",
    patient: "Jane Doe",
    amount: 0,
    date: "11/04/2019",
    paidBy: "Jane Doe",
    actions: "Print"
  },
  {
    invoiceId: "INV114993",
    patient: "Van Doe",
    amount: 550,
    date: "11/04/2019",
    paidBy: "Jane Doe",
    actions: "Print"
  },
  {
    invoiceId: "INV114994",
    patient: "James Ibori",
    amount: 1100,
    date: "11/04/2019",
    paidBy: "Jane Doe",
    actions: "Print"
  },
]

class PaymentsContainer extends Component {
  render() {
    return (
      <MainContent>
        <PageHeader 
          title="Payments"
        />
        <PageBody>
            <div className="dashboard-btn-section search-section">
              <input className="dashboard-form__input" type="text" placeholder="&#xf002;  Search Payments..."/>
            </div>
            <div className="dashboard-card dashboard-table-wrap">
              <div className="dashboard-table-overflow">
                {
                  (payments.length === 0) 
                    ? 
                    <CenteredContentBlock>
                        <div style={{fontSize: "40px", color: "grey"}}>No result found</div>
                    </CenteredContentBlock> 
                    :
                    <table className="dashboard-table">
                      <thead>
                          <tr>
                              <th className="dashboard-table-item dashboard-table-item__header">Invoice Id</th>
                              <th className="dashboard-table-item dashboard-table-item__header">Patient</th>
                              <th className="dashboard-table-item dashboard-table-item__header">Amount</th>
                              <th className="dashboard-table-item dashboard-table-item__header">Date</th>
                              <th className="dashboard-table-item dashboard-table-item__header">Paid By</th>
                              <th className="dashboard-table-item dashboard-table-item__header">Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                      { 
                        payments.map((payment, index)=>(
                          <tr key={`${payment.invoiceId}`} >
                            <td className="dashboard-table-item dashboard-table-item__body">{payment.invoiceId}</td>
                            <td className="dashboard-table-item dashboard-table-item__body">{payment.patient}</td>
                            <td className="dashboard-table-item dashboard-table-item__body">{payment.amount}</td>
                            <td className="dashboard-table-item dashboard-table-item__body">{payment.date}</td>
                            <td className="dashboard-table-item dashboard-table-item__body">{payment.paidBy}</td>
                            <td className="dashboard-table-item dashboard-table-item__body">
                              <a href="/payments" className="dashboard-table-item__link">{payment.actions}</a>
                            </td>
                          </tr>
                        ))
                      }
                      </tbody>
                    </table> 
                }
              </div>
            </div>
        </PageBody>
      </MainContent>
    );
  }
}

export default PaymentsContainer;