import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import numberFormatter from 'number-formatter';
import moment from "moment";
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import LoadingScreen from "../LoadingScreen";
import { toastBanner, INVOICE_STATUS, PERMISSIONS } from "../../services/utilityService";
import PageBody from "../boxes/PageBody";
import RestrictedComponent from "../utils/RestrictedComponent";

class Invoices extends Component {

  constructor(props){
    super(props);
    this.ApiService = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      invoices: []
    }
  }

  componentDidMount(){
    const { patient } = this.props;
    this.setLoading(true);
    this.ApiService.get(ENDPOINTS.invoices.base, {patient_id: patient.id})
      .then((response) => {
        this.setState({
          isLoading: false,
          invoices: response.data
        })
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <PageBody>
      <LoadingScreen />
    </PageBody>
  );

  render() {
    const { isLoading, invoices } = this.state;
    const { history } = this.props;

    if(isLoading) return this.renderLoading();

    return (
      <div className="patient-profile">
        <div className="dashboard-card">
          <div className="dashboard-control" style={{justifyContent: "flex-end"}}>
              {/*<button */}
                {/*className="dashboard__secondary-btn"*/}
                {/*onClick={() => this.props.history.push(`/invoices/new`)}*/}
                {/*// ${this.props.match.params.patientId}*/}
              {/*>*/}
                {/*<i className="fas fa-plus" style={{marginRight: "4px"}}/>&nbsp;*/}
                {/*Add New Invoice*/}
              {/*</button>*/}
          </div>
          <RestrictedComponent
            displayErrorMessage={true}
            errorComponent={() => (
              <div className="dashboard-card">
                <div style={{position:"relative", height:"10rem"}}>
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                </div>
              </div>
            )}
            condition={this.props.user.permissions.indexOf(PERMISSIONS.invoice.read_invoice) > -1}>

            <div className="dashboard-table-overflow">
              {invoices.length > 0 ? (
                <table className="dashboard-table">
                  <thead>
                  <tr>
                    <th className="dashboard-table-item dashboard-table-item__header">Invoice #</th>
                    <th className="dashboard-table-item dashboard-table-item__header">Amount (&#8358;)</th>
                    <th className="dashboard-table-item dashboard-table-item__header">Date</th>
                    <th className="dashboard-table-item dashboard-table-item__header">Status</th>
                    <th className="dashboard-table-item dashboard-table-item__header">Issued By</th>
                  </tr>
                  </thead>
                  <tbody>
                  {invoices.map((invoice, index)=>(
                      <tr
                        key={`${invoice.id}-${index}`}
                        onClick={() => history.push(`/invoices/${invoice.id}`)}
                        style={{cursor:"pointer"}}>
                        <td className="dashboard-table-item dashboard-table-item__body">{invoice.id}</td>
                        <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter("#,###.00",invoice.amount)}</td>
                        <td className="dashboard-table-item dashboard-table-item__body">{moment(invoice.created_at).format('MMMM Do YYYY, h:mm a')}</td>
                        <td className="dashboard-table-item dashboard-table-item__body">{INVOICE_STATUS.getStatus(Number(invoice.payment_status))}</td>
                        <td className="dashboard-table-item dashboard-table-item__body">{invoice.created_by}</td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              ) : (
                <div style={{fontSize: "2rem", color: "grey"}}>No Invoices</div>
              )}
            </div>

          </RestrictedComponent>
        </div>
      </div>
    )
  }
}

export default withRouter(Invoices);