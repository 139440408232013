import React from 'react';
import queryString from 'querystring';
import LoginForm from '../components/forms/LoginForm';
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';
import {Link,withRouter} from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import {connect} from 'react-redux';
import * as userActions from '../actions/user';
import {getRFCWebsiteUrl} from "../services/utilityService";

class LoginContainer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            forgotPassword:false,
            referrer:queryString.parse(props.location.search.substr(1)).r,
            rfcLink: getRFCWebsiteUrl(),
        }
    }

    renderLoading(){
        return (
            <LoadingScreen/>
        )
    };

    toggleForm = (e) => {
      e.preventDefault();
      this.setState({
          forgotPassword:!this.state.forgotPassword
      });
    };


    onFormSubmit = (values,dispatch,props) => {
        const { forgotPassword } = this.state;
        if(forgotPassword){
            dispatch(userActions.resetPassword(values));
        }else{
            dispatch(userActions.loginUser(
                values.username, 
                values.password, 
                props.history
            ));
        }
    };


    renderLogin = () => {
        const {isLoading, error} = this.props;
        const {forgotPassword, rfcLink} = this.state;
        return(
            <FormHolder message={error.message} isLoading={isLoading}>
                <LoginForm onSubmit={this.onFormSubmit}/>
                <PageExtras rfcLink={rfcLink} isForgotPassword={forgotPassword} toggleForm={this.toggleForm}/>
            </FormHolder>
        )
    };

    renderForgotPassword =() => {
        const {isLoading, error} = this.props;
        const {forgotPassword} = this.state;
        return (
            <FormHolder message={error.message} isLoading={isLoading}>
                <ForgotPasswordForm onSubmit={this.onFormSubmit}/>
                <PageExtras isForgotPassword={forgotPassword} toggleForm={this.toggleForm}/>
            </FormHolder>
        )
    };

    renderPasswordResetSuccessfulMessage = () => {
        return (
            <FormHolder>
                <MessageHolder>
                    <h3 className="message-holder__heading">Password Reset Activated</h3>
                    <p>
                        A password reset link has been sent to your mail, please use this to verify and set your new password.
                        <br/>
                        For enquiries, don't hesitate to send a mail to <a href="mailto:hello@reliancefamilyclinics.com?Subject=Enrollee Enquiry [from web app]">hello@reliancefamilyclinics.com</a>
                        <br/>
                        Thanks for choosing RelianceFamilyClinics.
                    </p>
                </MessageHolder>
            </FormHolder>
        )
    };

    render() {
        const {isLoading, checked, authenticated, recoverySuccessful} = this.props;

        const {
            forgotPassword,
             referrer
            } = this.state;

        if (isLoading) return this.renderLoading();

        if (checked && authenticated) {
          window.location.replace(referrer || "/");
          return null;
        }
        if (recoverySuccessful) return this.renderPasswordResetSuccessfulMessage();

        if (forgotPassword) return this.renderForgotPassword();

        return this.renderLogin();
    }
}



const FormHolder = (props) => {
    return (
        <div className="container">
            <div className="login_form">
                <div style={{padding:"10px",backgroundColor:"red",color:"white",visibility:(!props.isLoading && props.message)?"visible":"hidden"}}>
                    {props.message}
                </div>
                <div className="form_wrapper">

                    {props.children}

                </div>
            </div>
        </div>
    )
};


const MessageHolder = (props) => {
    return (
        <div style={{padding:"10px",margin:"10px",backgroundColor:"white",color:"#111"}}>
            <div>
                {props.children}
            </div>
        </div>
    )
};


const PageExtras = (props) => {
    const {rfcLink, isForgotPassword, toggleForm} = props;
    return (
        <div className="mini_cta-container">
            <p className="register_cta" >Don't have an account yet ? <a href={`${rfcLink}/mental-health-form`} className="register-mini_cta">Register</a></p>
            <p>
                {props.isForgotPassword ? "" : "Forgot Password?"}
                <span className="mini_cta">
                    <Link to={'#'} onClick={toggleForm}>
                        {isForgotPassword ? "Back" : "Click Here" }
                    </Link>
                </span>
            </p>
        </div>
    )
};


function mapStateToProps(state){
    const { isLoading, error, recoverySuccessful } = state.user||{};
    const { checked, authenticated } = state.session || {};
    return {isLoading, error, checked, authenticated, recoverySuccessful};
}

export default withRouter(connect(mapStateToProps)(LoginContainer));