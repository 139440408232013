import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import PropsRoute from "../components/utils/PropsRoute";
import InvoicesContainer from "../containers/InvoicesContainer";
import InvoiceDetailComponent from "../components/InvoiceDetailComponent";

class InvoicesRoutes extends React.Component {
  render () {
    const {user} = this.props;
    return (
      <Switch>
        <PropsRoute path='/invoices/:invoiceId' user={user} exact component={InvoiceDetailComponent}/>
        <PropsRoute
          path='/invoices'
          user={user}
          exact
          component={InvoicesContainer}
        />
      </Switch>
    )
  }
}

export default withRouter(InvoicesRoutes)