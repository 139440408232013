import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import {
  countries,
  maritalStatuses,
  occupations,
  preMeds,
  states,
  titles,
} from "./constants";

// eslint-disable-next-line
String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const isDevelopment = () => {
  return (
    process.env.REACT_APP_ENV === "development" ||
    process.env.REACT_APP_ENV === "staging"
  );
};

export const isStaging = () => {
  return process.env.REACT_APP_ENV === "staging";
};

export const isLocal = () => {
  return process.env.REACT_APP_ENV === "local";
};

export const getAccountsDashboardUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case "local":
      return "http://localhost:3000";
    case "staging":
      return "https://accounts.testing.reliancehmo.com";
    case "development":
      return "https://accounts.sandbox.reliancehmo.com";
    case "daara":
      return "https://accounts.daara.dev.legacy.reliancehmo.com";
    default:
      return "https://accounts.reliancehmo.com";
  }
};

export const getRFCWebsiteUrl = () => {
  switch (window.location.hostname) {
    case "localhost":
      return "http://localhost:4000";
    case "dashboard.staging.reliancefamilyclinics.com":
      return "https://staging.reliancefamilyclinics.com";
    case "dashboard.develop.reliancefamilyclinics.com":
      return "https://develop.reliancefamilyclinics.com";
    default:
      return "https://reliancefamilyclinics.com";
  }
};

export const getAdminDashboardURL = () => {
  if(process.env.REACT_APP_ENV === "production") {
    return "https://admin.reliancehmo.com/enrollees";
  } else {
    return "https://admin.staging.reliancehmo.com/enrollees";
  }
}

export const toastBanner = (message, messageType = "error", position) => {
  const toastClassName = {
    success: "toastify-success",
    error: "toastify-error",
  };

  return messageType === "error"
    ? toast.error(message, {
        className: toastClassName.error,
        position: position ? position : "bottom-right",
      })
    : toast.success(message, {
        className: toastClassName.success,
        position: position ? position : "bottom-right",
      });
};

export const getAge = (birthDay) => {
  const today = new Date();
  const birthDate = new Date(birthDay);
  const age = today.getFullYear() - birthDate.getFullYear();
  return age;
};

export const getAppointmentMediumId = (appointmentMedium) => {
  switch (appointmentMedium) {
    case "Visit":
      return 1;
    case "Video":
      return 2;
    case "Call":
      return 3;
    default:
      return "";
  }
};

export const RELATIONSHIPS = {
  parent: 1,
  sibling: 2,
  child: 3,
  spouse: 4,
  guardian: 5,
  friend: 6,
  getRelationShip: (id) => {
    switch (id) {
      case 1:
        return "Parent";
      case 2:
        return "Sibling";
      case 3:
        return "Child";
      case 4:
        return "Spouse";
      case 5:
        return "Guardian";
      case 6:
        return "Friend";
      default:
        return "";
    }
  },
};

export const INVOICE_STATUS = {
  noPayment: 1,
  partPayment: 2,
  fullPayment: 3,
  getStatus: (statusId) => {
    switch (statusId) {
      case 1:
        return "No Payment";
      case 2:
        return "Part Payment";
      case 3:
        return "Full Payment";
      default:
        return "";
    }
  },
};

export const SOURCES = {
  web: 1,
  frontDesk: 2,
};

export const SPECIALISTS = [
  { id: 1, name: "Psychologist" },
  { id: 2, name: "Obstetrician / Gynaecologist" },
  { id: 3, name: "Cardiologist" },
  { id: 4, name: "Dermatologist" },
  { id: 5, name: "Physiotherapist" },
  { id: 6, name: "Nephrologist" },
  { id: 7, name: "Gastroenterologist" },
  { id: 8, name: "General Surgeon" },
  { id: 9, name: "Endocrinologist" },
  { id: 10, name: "Dermatologist" },
];

export const PAYMENT_MEDIUM = {
  cash: 1,
  card: 2,
  hmo: 3,
  getPaymentMedium: (id) => {
    switch (id) {
      case 1:
        return "Cash";
      case 2:
        return "Card";
      case 3:
        return "HMO";
      default:
        return "";
    }
  },
};

export const APPOINTMENT_TYPES = {
  appointment: 1,
  walkIn: 2,
  emergency: 3,
  getAppointmentType: (id) => {
    switch (id) {
      case 1:
        return "Regular Appointment";
      case 2:
        return "Walk-in Appointment";
      case 3:
        return "Emergency Appointment";
      default:
        return "";
    }
  },
};

export const TARIFF_TYPES = {
  procedures: 1,
  drugs: 2,
  diagnostics: 3,
  services: 4,
  getTariffType: (tId) => {
    switch (tId) {
      case 1:
        return "Procedure";
      case 2:
        return "Drug";
      case 3:
        return "Diagnostics";
      default:
        return "Unavailable";
    }
  },
};

export const STAGEDATA = {
  stages: {
    yetToArrive: 1,
    frontDesk: 2,
    triage: 3,
    physician: 4,
    pharmacist: 5,
    completed: 6,
  },
  status: {
    waiting: 1,
    active: 2,
    completed: 3,
  },
  appointmentTypes: {
    firstConsultation: 0,
    standardConsultation: 1,
    followUpConsultation: 2,
  },
  getStageName: (stageId) => {
    switch (Number(stageId)) {
      case 1:
        return "Yet to Arrive";
      case 2:
        return "Front Desk";
      case 3:
        return "Triage";
      case 4:
        return "Physician";
      case 5:
        return "Pharmacist";
      case 6:
        return "Finished";
      default:
        return "";
    }
  },
  getAppointmentType: (type) => {
    switch (type) {
      case 0:
        return "First Consultation";
      case 1:
        return "Standard Consultation";
      case 2:
        return "Follow-up Consultation";
      default:
        return "";
    }
  },
};

export const PAYMENT_STATUS = {
  pending: 1,
  approved: 2,
  declined: 3,
  failed: 4,
  paid: 5,
  getPaymentStatus: (pId) => {
    switch (pId) {
      case 1:
        return "Pending";
      case 2:
        return "Approved";
      case 3:
        return "Declined";
      case 4:
        return "Failed";
      case 5:
        return "Paid";
      default:
        return "Unavailable";
    }
  },
};

const RELIANCE_API_VERSION = process.env.REACT_APP_RELIANCE_API_VERSION;

export const BASE_URL_RELIANCE = `${process.env.REACT_APP_BASE_API_URL}${RELIANCE_API_VERSION}`;

export const DOMAIN_COOKIE_NAME =
  !isDevelopment() && !isLocal() && !isStaging()
    ? "rat"
    : "rat_" + process.env.REACT_APP_ENV;

export const getCookie = (_name = DOMAIN_COOKIE_NAME) => {
  // let name = _name + "=";
  // let ca = document.cookie.split(';');
  // for(let i = 0; i < ca.length; i++) {
  //   let c = ca[i];
  //   //clean spaces
  //   while (c.charAt(0) === ' ') {
  //     c = c.substring(1);
  //   }
  //   //get cookie value
  //   if (c.indexOf(name) === 0) {
  //     return c.substring(name.length, c.length);
  //   }
  // }
  // return "";
  return Cookies.get(_name);
};

export const printPage = () => {
  const content = document.getElementById("right");
  const printContainer = document.createElement("div");
  const root = document.getElementById("root");

  printContainer.classList.add("print-container");
  printContainer.innerHTML = content.innerHTML;

  document.body.prepend(printContainer);

  root.style.display = "none";

  window.print();

  printContainer.outerHTML = "";
  root.style.display = "block";
};

export default class UtilityService {
  static getCountries() {
    return Promise.resolve(countries);
    //return axios.get(`${BASE_URL_RELIANCE}/utility/countries`);
  }

  static getTitles() {
    return Promise.resolve(titles);
    //return axios.get(`${BASE_URL_RELIANCE}/utility/titles`);
  }

  static getOccupations() {
    return Promise.resolve(occupations);
    //return axios.get(`${BASE_URL_RELIANCE}/utility/occupations`);
  }

  static getPreMedicalConditions() {
    return Promise.resolve(preMeds);
    //return axios.get(`${BASE_URL_RELIANCE}/utility/pre_meds`);
  }

  static getStates() {
    return Promise.resolve(states);
    //return axios.get(`${BASE_URL_RELIANCE}/utility/states`);
  }

  static getMaritalStatuses() {
    return Promise.resolve(maritalStatuses);
    //return axios.get(`${BASE_URL_RELIANCE}/utility/marital_statuses`);
  }

  // getUtilities(){
  //     return axios.all([this.getCountries(), this.getOccupations(), this.getTitles(),
  //                     this.getPreMedicalConditions(), this.getStates(), this.getMaritalStatuses()])
  //         .then(axios.spread(function (countries, occupations, titles, preCons, states, mStatuses) {
  //             // Both requests are now complete
  //             return {
  //                 countries:countries.data.data,
  //                 occupations:occupations.data.data,
  //                 titles:titles.data.data,
  //                 preCons:preCons.data.data,
  //                 states:states.data.data,
  //                 mStatuses:mStatuses.data.data
  //             };
  //         }));
  // }

  static getUtilities() {
    return Promise.all([
      UtilityService.getOccupations(),
      UtilityService.getMaritalStatuses(),
    ]).then((responses) => {
      return {
        occupations: responses[0].data,
        mStatuses: responses[1].data,
      };
    });
  }

  static uploadFile(file, uploadType, accessToken) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", uploadType);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return axios
      .post(`${BASE_URL_RELIANCE}/utilities/files/upload`, formData, {
        headers,
      })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        return {
          message: e.response.data.message || "An Error Occurred",
          code: e.response.status,
          status: "error",
        };
      });
  }
}

export const ROLES = {
  front_desk_officer: "front_desk_officer",
  triage_officer: "triage_officer",
  super_admin: "super_admin",
  nurse: "nurse",
  doctor: "doctor",
  pharmacist: "pharmacist",
  getRoleName: (key) => {
    const string = key.replace(/_/g, " ");
    if (string.toProperCase) {
      return string.toProperCase();
    } else {
      return string;
    }
  },
};

export const PERMISSIONS = {
  patient: {
    create_patient: "create_patient",
    read_patient: "read_patient",
    update_patient: "update_patient",
    delete_patient: "delete_patient",
    patient_allergy: {
      create_patient_allergy: "create_patient_allergy",
      read_patient_allergy: "read_patient_allergy",
      update_patient_allergy: "update_patient_allergy",
      delete_patient_allergy: "delete_patient_allergy",
    },
    patient_note: {
      create_patient_note: "create_patient_note",
      read_patient_note: "read_patient_note",
      update_patient_note: "update_patient_note",
      delete_patient_note: "delete_patient_note",
    },
    patient_vital: {
      create_patient_vital: "create_patient_vital",
      read_patient_vital: "read_patient_vital",
      update_patient_vital: "update_patient_vital",
      delete_patient_vital: "delete_patient_vital",
    },
    patient_relation: {
      create_patient_relation: "create_patient_relation",
      read_patient_relation: "read_patient_relation",
      update_patient_relation: "update_patient_relation",
      delete_patient_relation: "delete_patient_relation",
    },
    patient_medical_history: {
      create_patient_medical_history: "create_patient_medical_history",
      read_patient_medical_history: "read_patient_medical_history",
      update_patient_medical_history: "update_patient_medical_history",
      delete_patient_medical_history: "delete_patient_medical_history",
    },
  },
  encounter: {
    create_encounter: "create_encounter",
    read_encounter: "read_encounter",
    update_encounter: "update_encounter",
    delete_encounter: "delete_encounter",
    encounter_comment: {
      create_encounter_comment: "create_encounter_comment",
      read_encounter_comment: "read_encounter_comment",
      update_encounter_comment: "update_encounter_comment",
      delete_encounter_comment: "delete_encounter_comment",
    },
    encounter_vital: {
      create_encounter_vital: "create_encounter_vital",
      read_encounter_vital: "read_encounter_vital",
      update_encounter_vital: "update_encounter_vital",
      delete_encounter_vital: "delete_encounter_vital",
    },
    encounter_test_result: {
      create_encounter_test_result: "create_encounter_test_result",
      read_encounter_test_result: "read_encounter_test_result",
      update_encounter_test_result: "update_encounter_test_result",
      delete_encounter_test_result: "delete_encounter_test_result",
    },
    encounter_note: {
      create_encounter_note: "create_encounter_note",
      read_encounter_note: "read_encounter_note",
      update_encounter_note: "update_encounter_note",
      delete_encounter_note: "delete_encounter_note",
    },
    encounter_test: {
      create_encounter_test: "create_encounter_test",
      read_encounter_test: "read_encounter_test",
      update_encounter_test: "update_encounter_test",
      delete_encounter_test: "delete_encounter_test",
    },
    encounter_diagnosis: {
      create_encounter_diagnosis: "create_encounter_diagnosis",
      read_encounter_diagnosis: "read_encounter_diagnosis",
      update_encounter_diagnosis: "update_encounter_diagnosis",
      delete_encounter_diagnosis: "delete_encounter_diagnosis",
    },
    encounter_procedure: {
      create_encounter_procedure: "create_encounter_procedure",
      read_encounter_procedure: "read_encounter_procedure",
      update_encounter_procedure: "update_encounter_procedure",
      delete_encounter_procedure: "delete_encounter_procedure",
    },
    encounter_pacode: {
      create_encounter_pacode: "create_encounter_pacode",
      read_encounter_pacode: "read_encounter_pacode",
      update_encounter_pacode: "update_encounter_pacode",
      delete_encounter_pacode: "delete_encounter_pacode",
    },
    encounter_claim: {
      create_encounter_claim: "create_encounter_claim",
      read_encounter_claim: "read_encounter_claim",
      update_encounter_claim: "update_encounter_claim",
      delete_encounter_claim: "delete_encounter_claim",
    },
  },
  prescription: {
    create_prescription: "create_prescription",
    read_prescription: "read_prescription",
    update_prescription: "update_prescription",
    delete_prescription: "delete_prescription",
  },
  invoice: {
    create_invoice: "create_invoice",
    read_invoice: "read_invoice",
    update_invoice: "update_invoice",
    delete_invoice: "delete_invoice",
  },
  payment: {
    create_payment: "create_payment",
    read_payment: "read_payment",
    update_payment: "update_payment",
    delete_payment: "delete_payment",
  },
  nps: {
    create_nps: "create_nps",
    read_nps: "read_nps",
    update_nps: "update_nps",
    delete_nps: "delete_nps",
  },
};
