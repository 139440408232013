import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';
import DateTime from 'react-datetime';
import SearchInput from "../inputs/SearchInput";
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import { toastBanner } from "../../services/utilityService";

import LoadingScreen from '../LoadingScreen';
import Modal from '../boxes/Modal';

//onClose, patient, userToken, onRelationAdded
const PatientRelationshipModal = (props) => (
  <Modal title="Search for Existing Patient/Add new Relation" onClose={props.onClose}>
    <AddPatientRelationForm {...props}/>
  </Modal>
);

class AddPatientRelationForm extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading: false,
      relation:null,
      patients: [],
      policies: [],
      filter: "",
      checked: false,
      selectedRelation: ""
    };
  }

  componentDidMount() {
    this.getPolicies();
  }

  getPolicies = () => {
    const { user, dispatch, patient } = this.props;

    this.service.get(ENDPOINTS.utilities.policies)
    .then(response => {
      this.setState({
        policies: [...response.data]
      });

      let policyData = null
      if((!_.isEmpty(user) && user.policies.length > 0)){
        policyData = user.policies[0];
        policyData.hidePolicyData = true;
      }else if(patient && patient.policies.length > 0){
        policyData = patient.policies[0];
        dispatch(change('addPatientRelationForm', 'policy_id', policyData.id));
        dispatch(change('addPatientRelationForm', 'policy_code', policyData.code));
      }

      this.setState({
        policyData
      });
    })
    .catch((error) => {
      this.setState({
        isLoading: false,
      });
      // toastBanner(error.message || "An error occurred!")
      setTimeout(() => { toastBanner(error.message || "An error occurred!") }, 3000);
    });
  };

  getPatients = (filter) => {
    this.setLoading(true)
    return this.service.get(ENDPOINTS.patients.base, { 
      q: filter})
      .then((response) => {
        this.setState({
          patients: response.data,
          filter,
          isLoading: false,
        });
        return response.data;
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        // toastBanner(error.message || "An error occurred!")
        setTimeout(() => { toastBanner(error.message || "An error occurred!") }, 3000);
      });
  };

  onDateSet = (date) => {
    const {dispatch} = this.props;
    dispatch(change('addPatientRelationForm', 'date_of_birth', moment(date).format("YYYY-MM-DD")));
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  onSubmit = (values) => {
    if(!values.create_patient_profile) {
      this.onSubmitRelation(values);
    }

    if(values.create_patient_profile) {
      this.signUpUser(values);      
    }
  };

  signUpUser = (values) => {
    return this.service.post(ENDPOINTS.signup, {
      first_name:values.first_name,
      last_name: values.last_name,
      email_address: values.email_address,
      phone_number: values.phone_number
    })
    .then(response => {
      values.user_id = response.data.id;
      return this.registerPatient(values);
    })
    .catch(error => {
      this.setState({
        isLoading: false
      });
      setTimeout(() => { toastBanner(error.message || "An error occurred!") }, 3000);
    });
  };

  registerPatient = (patientData) => {
    return this.service.post(ENDPOINTS.patients.base, patientData)
      .then(response => {
        this.onSubmitRelation({...patientData, rfc_id: response.data.rfc_id});
      })
      .catch(error => {
        this.setState({
          isLoading: false,
        });
        setTimeout(() => { toastBanner(error.message || "An error occurred!") }, 3000);
      });
  };

  onSubmitRelation = (values) => {
    const { patient, onRelationAdded } = this.props;
    this.setLoading(true);

    this.service.post(ENDPOINTS.patients.relations(patient.id), values)
      .then(response => {
        const relation = {
          ...values, 
          id: response.data.user_to_relation_id,
          relation_id: response.data.relation_id
        };
        this.setLoading(false);
        this.setState({
          relation
        });
        if(onRelationAdded)
          onRelationAdded(relation);
        setTimeout(() => { toastBanner(`${relation.first_name} has successfully been added as a relative`, "success") }, 3000);
      })
      .catch(error => {
        this.setState({
          isLoading: false
        });
        setTimeout(() => { toastBanner(error.message || "An error occurred!") }, 3000);
      });
  };

  handleSelect = (event) => {
    this.setState({
      selectedRelationValue: event.target.value
    })
  };

  linkAsRelation = (patientId) => {
    const { patients, selectedRelationValue } = this.state;
    const { patient, onRelationAdded } = this.props;
    this.setLoading(true);

    const selectedPatient = patients.filter((patient) => {
      return patient.id === patientId
    })

    const patientMetaData = {
      first_name: selectedPatient[0].first_name,
      last_name: selectedPatient[0].last_name,
      email_address: selectedPatient[0].email_address,
      phone_number: selectedPatient[0].phone_number,
      rfc_id: selectedPatient[0].rfc_id,
      relationship_id: selectedRelationValue
    }

    this.service.post(ENDPOINTS.patients.relations(patient.id), patientMetaData)
      .then((response) => {
        this.setLoading(false);
        const newRelation = {
          ...patientMetaData, 
          id: response.data.user_to_relation_id,
          relation_id: response.data.relation_id
        }

        onRelationAdded && onRelationAdded(newRelation)

        this.clearFilter();
        setTimeout(() => { toastBanner(
          `${patientMetaData.first_name} ${patientMetaData.last_name} has been successfully added as a relation`,
          "success"
        ) }, 3000);
      })
      .catch((error) => {
        this.setLoading(false);
        setTimeout(() => { toastBanner(error.message || "An error occured") }, 3000);
      });
  };

  filterPatients = (filter) => {
    this.getPatients(filter);
  };

  filteredPatientsList = (patients) => {
    const { selectedRelationValue } = this.state;

    return (
      patients.length > 0 ?
      <table className="dashboard-table">
        <thead>
          <tr>
            <th className="dashboard-table-item dashboard-table-item__header">First Name</th>
            <th className="dashboard-table-item dashboard-table-item__header">Last Name</th>
            <th className="dashboard-table-item dashboard-table-item__header">Email Address</th>
            <th className="dashboard-table-item dashboard-table-item__header">RFC ID</th>
            <th className="dashboard-table-item dashboard-table-item__header">Add Relation</th>
            <th className="dashboard-table-item dashboard-table-item__header">Link Relation</th>
          </tr>
        </thead>
        <tbody>
          {
            patients.map((patient) => (
              <tr key={patient.id}>
                <td className="dashboard-table-item dashboard-table-item__body">{patient.first_name}</td>
                <td className="dashboard-table-item dashboard-table-item__body">{patient.last_name}</td>
                <td className="dashboard-table-item dashboard-table-item__body">{patient.email_address}</td>
                <td className="dashboard-table-item dashboard-table-item__body">{patient.rfc_id ? patient.rfc_id : 'N/A'}</td>
                <td className="dashboard-table-item dashboard-table-item__body">
                  <select
                    className="dashboard__secondary-btn dashboard-form__select"
                    onChange={this.handleSelect}
                    value={selectedRelationValue}
                  >
                    <option>Select</option>
                    <option value="1">Parent</option>
                    <option value="2">Sibling</option>
                    <option value="3">Child</option>
                    <option value="4">Spouse</option>
                    <option value="5">Guardian</option>
                    <option value="6">Friend</option>
                  </select>
                </td>
                <td className="dashboard-table-item dashboard-table-item__body">
                  <button onClick={() => this.linkAsRelation(patient.id)} className="dashboard__primary-btn">Link</button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      :
      <React.Fragment>
        <div className="dashboard-card" style={{fontSize: "2rem", color: "grey", textAlign: 'center'}}>No Relation found</div>
        <div className="dashboard-card" style={{fontSize: "2rem", color: "grey", textAlign: 'center'}}>Add a new relation with the form below</div>
      </React.Fragment>
    )
  };

  clearFilter = () => {
    this.setState({
      filter: ""
    })
  };

  refresh = () => {
    const { reset } = this.props;
    if(reset)
      reset();
    this.setState({
      relation: null
    })
  };

  normalizePhone = (value) => {
    if (!value) {
      return value
    }
    return value.replace(/[^\d]/g, '');
  };

  render(){
    const {
      handleSubmit, 
      onClose, 
      pristine, 
      submitting, 
      createPatientProfile, 
      dateOfBirth} = this.props;

    const {isLoading, relation, policies, policyData, filter, patients } = this.state;

    if(isLoading) return (<div style={{position:"relative", height:"5rem"}}><LoadingScreen/></div>);

    return (
    <React.Fragment> 

      <div className="dashboard-card" style={{ marginBottom: "2rem"}}>
        <h2 className="dashboard-card__title-text">Search Existing Patient</h2>
        <SearchInput
          placeholder="&#xf002;  Enter a Patient's name or RFC ID and press enter"
          onSubmit={this.filterPatients}
          clearFilter={this.clearFilter}
          customStyle={{padding: "0"}}
        />
      { 
        filter.length > 0 && 
          this.filteredPatientsList(patients)
      }
      </div>

      <div className="dashboard-card">
        <h2 className="dashboard-card__title-text">Add Relation Form</h2>
        <form className="dashboard-form" onSubmit={handleSubmit(!relation ? this.onSubmit : this.refresh)}>

          <div className="dashboard-form-item-wrapper">
            <div className="dashboard-input-wrap profile-input-wrap">
              <span className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  First Name
                </label>
                <Field component="input" name="first_name" className="dashboard-form__input" type="text" placeholder="First Name" readOnly={!!relation}/>
              </span>

              <span className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  Last Name
                </label>
                <Field component="input" name="last_name" className="dashboard-form__input" type="text" placeholder="Last Name" readOnly={!!relation}/>
              </span>

              <span className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  Email Address
                </label>
                <Field component="input" name="email_address" className="dashboard-form__input" type="text" placeholder="Email Address" readOnly={!!relation}/>
              </span>

              {
                createPatientProfile &&
                  <React.Fragment>
                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Gender
                      </label>
                      <Field component="select" className="dashboard-form__input dashboard-form__select" name="gender">
                        <option>Please select a gender</option>
                        <option value="m">Male</option>
                        <option value="f">Female</option>
                      </Field>
                    </span>
                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Date of Birth
                      </label>
                      <Field component="input" type="hidden" name="date_of_birth" placeholder="Date of Birth" required/>
                      <DateTime
                        closeOnSelect
                        viewMode="years"
                        timeFormat={false}
                        dateFormat="YYYY-MM-DD"
                        inputProps={{required: true, placeholder: "YYYY-MM-DD", readOnly: true, className:"dashboard-form__input"}}
                        onChange={this.onDateSet}
                        isValidDate={(curr) => curr.year() <= DateTime.moment().year()}
                        value={dateOfBirth}/>
                      </span>
                      <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        htmlFor="">
                        Address
                      </label>
                      <Field
                        component="textarea"
                        className="dashboard-form__input"
                        name="address"
                        placeholder="Patient's Address"/>
                    </span>
                  </React.Fragment>
              }
            </div>

            <div className="dashboard-input-wrap profile-input-wrap">
              <span className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  Phone Number
                </label>
                <Field component="input" name="phone_number" 
                  className="dashboard-form__input" type="text" 
                  placeholder="Phone Number" readOnly={!!relation}
                  normalize={this.normalizePhone}/>
              </span>

              <span className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  Relationship
                </label>
                <Field component="select" className="dashboard-form__input dashboard-form__select" name="relationship_id">
                  <option>Select Relationship</option>
                  <option value="1">Parent</option>
                  <option value="2">Sibling</option>
                  <option value="3">Child</option>
                  <option value="4">Spouse</option>
                  <option value="5">Guardian</option>
                  <option value="6">Friend</option>
                </Field>
              </span>

              <div style={{ marginTop: "4.7rem"}}>
                <span className="dashboard-form-item">
                  <label htmlFor="" className="profile-item__label">Do you want to create a profile for this relation?</label>
                  <span className="dashboard-form__checklist-wrap" style={{ flexDirection: "row"}}>
                    <span>
                      <label htmlFor="yes" style={{marginRight: "1rem"}}>Yes</label>
                      <Field
                        normalize={value => value === 'true'} 
                        component="input"  
                        type="radio" 
                        name="create_patient_profile" 
                        className="" 
                        value={true}
                        id="yes"
                        onChange={() => this.setState({ checked: !this.state.checked })}
                        checked={this.state.checked === true}
                      />
                    </span>
                    <span>
                      <label htmlFor="no" style={{marginRight: "1rem"}}>No</label>
                      <Field 
                        normalize={value => value === 'true'} 
                        component="input"  
                        type="radio" 
                        name="create_patient_profile" 
                        className="" 
                        value={false} 
                        id="no" 
                        onChange={
                          () => this.setState({ checked: !this.state.checked })
                        }
                        checked={this.state.checked === false}
                      />
                    </span>
                  </span>
                </span>

                {
                  createPatientProfile &&
                    (!policyData || (policyData && !policyData.hidePolicyData)) &&
                      <React.Fragment>
                        <span className="dashboard-form-item">
                          <label
                            className="profile-item__label"
                            htmlFor="">
                            Insurer
                          </label>
                          <Field component="select" className="dashboard-form__input dashboard-form__select" name="policy_id">
                            <option>Please select an Insurer</option>
                            {policies
                              .filter(policy => policy.id !== 1)
                              .map(policy => (
                                <option key={policy.id} value={policy.id}>{policy.name}</option>
                              ))}
                          </Field>
                        </span>
            
                        <span className="dashboard-form-item">
                          <label className="profile-item__label" htmlFor="">
                            Policy Number
                          </label>
                          <Field component="input" className="dashboard-form__input" type="text" placeholder="Enter policy no." name="policy_code"/>
                        </span>
                      </React.Fragment>
                }
              </div>
            </div>
          </div>
          <div style={{display:"flex",flexDirection:"flex-start"}}>
            {relation ? (
              <button className="dashboard__secondary-btn dashboard-form__btn" type="submit">Add Another</button>
            ) : (
              <button className="dashboard__primary-btn dashboard-form__btn" type="submit" disabled={pristine || submitting}>Save</button>
            )}
            <button className="dashboard__secondary-btn dashboard-form__btn" type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </React.Fragment> 
    )
  }
}

AddPatientRelationForm = reduxForm({
  form:"addPatientRelationForm"
})(AddPatientRelationForm);

const selector = formValueSelector('addPatientRelationForm');
AddPatientRelationForm = connect(state => {
  // can select values individually
  const createPatientProfile = selector(state, 'create_patient_profile');
  const dateOfBirth = selector(state, 'date_of_birth');
  return {
    createPatientProfile,
    dateOfBirth
  }
})(AddPatientRelationForm);

export default PatientRelationshipModal;