import React from 'react';
import {reduxForm, Field, change} from 'redux-form';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import Modal from "../boxes/Modal";
import FileInput from "../inputs/FileInput";
import UtilityService, { toastBanner } from "../../services/utilityService";

export const UPLOAD_MODE = {
  patientUpload: "patient_upload",
  encounterUpload: "encounter_upload"
};

const ResultUploadModal = (props) => (
  <Modal
    title={`Upload Result for ${props.patient ? `${props.patient.first_name} ${props.patient.last_name}` : `Test # ${props.test.id}`} `}
    onClose={props.onClose}>
    <ResultUploadComponent {...props} />
  </Modal>
);

class ResultUploadComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isUploading:false,
      isLoading: false,
    };
  }

  onFileAdded = (file) => {
    const {dispatch, userToken} = this.props;
    this.setState({
      isUploading: true
    });
    UtilityService.uploadFile(file,"test_result", userToken)
      .then(response => {
        if(response.status === "error"){
          this.setState({
            isUploading: false,
          });
          toastBanner(response.message)
        }else{
          this.setState({
            isUploading: false,
          }, () => {
            dispatch(change("resultUploadForm","file_id", response.data.file_id));
            toastBanner(response.message, "success");
          });
        }
      })
      .catch(error => {
        toastBanner(error.message || "An erro occurred!");
      });
  };

  onSubmit = (values) => {
    const {encounterId, test, patient} = this.props;
    this.setState({
      isLoading:true
    });
    let requestUrl;
    if(test){
      requestUrl = ENDPOINTS.encounters.results(encounterId, test.id)
    }else if(patient){
      requestUrl = ENDPOINTS.patients.results(patient.id);
    }
    if(requestUrl){
      this.service.post(requestUrl, values)
        .then(response => {
          this.setState({
            isLoading: false,
          }, () => {
            setTimeout(() => {
              window.location.reload();
            }, 1000)
          });
          toastBanner("Successfully upload result", "success");
        })
        .catch(error => {
          this.setState({
            isLoading: false,
          });
          toastBanner(error.message || "An error occurred!");
        })
    }else{
      toastBanner("Test and patient not found");
    }
  };

  render(){
    const {isLoading, isUploading} = this.state;
    const {handleSubmit, onClose} = this.props;

    return (
      <form className="dashboard-form" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="dashboard-form-item-wrapper">
          <div className="dashboard-input-wrap profile-input-wrap">
            <span className="dashboard-form-item">
              <label
                className="profile-item__label"
                htmlFor="">
                Upload Result
              </label>
              <FileInput
                className="dashboard-form__input"
                name="file"
                buttonCaption={isUploading ? "Uploading..." : "Upload"}
                disabled={isUploading}
                onFileAdded={this.onFileAdded}/>
              {/*<Field component={FileInput} className="dashboard-form__input" type="text"  name="file" onChange={this.onFileAdded}/>*/}
            </span>

            <span className="dashboard-form-item">
              <label
                className="profile-item__label"
                htmlFor="">
                Description
              </label>
              <Field component="textarea" className="dashboard-form__input" type="text" placeholder="Enter summary" name="summary"/>
            </span>
            <Field component="input" type="hidden" name="file_id" />
            <div style={{display:"flex",flexDirection:"flex-start"}}>
              <button
                disabled={isLoading}
                className="dashboard__primary-btn dashboard-form__btn"
                type="submit">{isLoading ? "Saving..." : "Save"}</button>
              <button className="dashboard__secondary-btn dashboard-form__btn" type="button" onClick={onClose}>Cancel</button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

ResultUploadComponent = reduxForm({
  form: "resultUploadForm"
})(ResultUploadComponent);

export default ResultUploadModal;