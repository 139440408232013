import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, formValueSelector } from "redux-form";
import moment from "moment";
import DateTime from "react-datetime";
import "../forms/datepicker.css";
import { ApiService, ENDPOINTS } from "../../services/ApiService";
import LoadingScreen from "../LoadingScreen";
import Modal from "../boxes/Modal";
import {
  SOURCES,
  toastBanner,
  SPECIALISTS,
} from "../../services/utilityService";

const AppointmentModal = (props) => (
  <Modal title="Appointment Details" onClose={props.onClose}>
    <React.Fragment>
      <AppointmentPatientDetails
        patientId={props.patientId}
        userToken={props.userToken}
      />
      <br />
      <AppointmentForm {...props} />
    </React.Fragment>
  </Modal>
);

class AppointmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading: false,
      availableTimes: [],
      careCategories: [],
      mode: props.mode || AppointmentModal.MODES.edit,
      appointmentMediums: [],
    };
  }

  componentDidMount() {
    const { dispatch, selectedDate, selectedAppointment } = this.props;

    if (selectedDate) {
      dispatch(
        change(
          "appointmentForm",
          "date",
          moment(selectedDate).format("YYYY-MM-DD")
        )
      );
      dispatch(
        change("appointmentForm", "time", moment(selectedDate).format("HH:mm"))
      );
    }

    if (selectedAppointment) {
      //set selected appointment details (if available) in form
      dispatch(
        change(
          "appointmentForm",
          "appointment_medium_id",
          selectedAppointment.medium ? selectedAppointment?.medium?.id : "N/A"
        )
      );
      dispatch(
        change(
          "appointmentForm",
          "appointment_medium_name",
          selectedAppointment.medium ? selectedAppointment?.medium?.name : "N/A"
        )
      );

      if (selectedAppointment.care_category) {
        dispatch(
          change(
            "appointmentForm",
            "care_category_name",
            selectedAppointment?.care_category.name
          )
        );
        dispatch(
          change(
            "appointmentForm",
            "care_category_id",
            selectedAppointment?.care_category.id
          )
        );
      }
    }

    this.onDateSet(selectedDate);
    this.getAppointmentMediums();
    this.getCareCategories();
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  getAppointmentMediums = () => {
    const { isFollowUp } = this.props;

    this.service
      .get(ENDPOINTS.appointments.mediums)
      .then((response) => {
        this.setLoading(false);
        this.setState({
          appointmentMediums: isFollowUp
            ? response.data.filter((data) => data.id !== 2)
            : response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  displayAppointmentMedium = () => {
    const { selectedAppointment, dispatch } = this.props;

    if (selectedAppointment) {
      dispatch(
        change(
          "appointmentForm",
          "appointment_medium_id",
          selectedAppointment?.medium ? selectedAppointment.medium.name : null
        )
      );
    }
    this.getAppointmentMediums();
  };

  onSubmit = (values) => {
    const {
      patientId,
      onAppointmentBooked,
      selectedAppointment,
      type,
      isFollowUp,
    } = this.props;
    if (this.state.mode === AppointmentModal.MODES.view) {
      this.setState({
        mode: AppointmentModal.MODES.edit,
      });
    } else {
      let request,
        requestData,
        requestUrl = ENDPOINTS.appointments.base;
      //temp: if care category is "specialist consultation" i.e 10, and a reason is set, clear the reason
      if (values.care_category_id === 10 && values.care_specialist) {
        values.reason = `${values.care_specialist} consultation`;
      }

      requestData = { ...values };

      request = this.service.post;
      if (selectedAppointment) {
        if (isFollowUp) {
          //follow-up appointment
          requestData.encounter_id = Number(selectedAppointment.id);
          requestData.is_follow_up = true;
          requestData.patient_id = patientId;
        } else {
          // reschedule appointment
          requestUrl = ENDPOINTS.appointments.edit(selectedAppointment.id);
          request = this.service.put;
        }
      } else {
        requestData.patient_id = patientId;
      }
      requestData.source_id = SOURCES.frontDesk;
      requestData.encounter_type_id = type;

      this.setLoading(true);
      request(requestUrl, requestData)
        .then((response) => {
          if (response.data.paystack_url && !requestData.is_follow_up) {
            requestData.payment_required = true;
          }
          if (onAppointmentBooked) onAppointmentBooked(requestData);
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          toastBanner(error.message || "An error occured!");
        });
    }
  };

  onDateSet = (date) => {
    const { dispatch } = this.props;
    dispatch(
      change("appointmentForm", "date", moment(date).format("YYYY-MM-DD"))
    );
  };

  onCareCategorySelected = (careCategoryId) => {
    const { careCategories } = this.state;

    this.setState({
      selectedCareCategory: careCategories.filter(
        (c) => c.id === Number(careCategoryId)
      )[0],
    });

    this.getAppointmentMediums();
  };

  onAppointmentMediumSelected = (appointmentMedium) => {
    this.getAvailableTimes(appointmentMedium);
  };

  getAvailableTimes = (appointmentMedium) => {
    const { selectedCareCategory, mode, careCategories } = this.state;
    const { date, isFollowUp, selectedAppointment } = this.props;

    let editSelectedCareCategory = careCategories.filter(
      (c) => c.id === Number(selectedAppointment?.care_category.id)
    )[0];

    //set loading times
    this.setState({
      availableTimes: [{ name: "Loading...", value: null }],
    });

    // get available times
    this.service
      .get(ENDPOINTS.appointments.available, {
        date: moment(date).format("YYYY-MM-DD"),
        staff_type:
          mode === "edit"
            ? editSelectedCareCategory?.staff_type
            : selectedCareCategory?.staff_type,
        is_follow_up: isFollowUp && Number(appointmentMedium) === 3 ? 1 : 0,
      })
      .then((response) => {
        const times = response.data.map((time) => {
          const timeBits = time.split(":");
          const hour = Number(timeBits[0]);
          return {
            value: `${timeBits[0]}:${timeBits[1]}`,
            name: `${hour > 12 ? hour - 12 : hour} : ${timeBits[1]} ${
              hour < 12 ? "AM" : "PM"
            }`,
          };
        });

        this.setState({
          availableTimes: times,
        });
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  };

  reloadState = () => {
    setTimeout(() => {
      this.getAvailableTimes(this.props.appointmentMediumId);
    }, 100);
  };

  getCareCategories = () => {
    this.service
      .get(ENDPOINTS.appointments.careCategories)
      .then((response) => {
        this.setLoading(false);
        this.setState({
          careCategories: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      handleSubmit,
      onClose,
      date,
      selectedCareCategoryId,
      isFollowUp,
      appointmentMediumId,
      pristine,
    } = this.props;
    const {
      isLoading,
      availableTimes,
      mode,
      appointmentMediums,
      careCategories,
    } = this.state;

    if (isLoading)
      return (
        <div style={{ position: "relative", height: "5rem" }}>
          <LoadingScreen />
        </div>
      );

    return (
      <React.Fragment>
        <div className="dashboard-card">
          <h2
            className="dashboard-card__title-text"
            style={{ marginBottom: "0rem" }}
          >
            Appointment Details:
          </h2>
          <form
            className="dashboard-form"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            {mode === AppointmentModal.MODES.view ? (
              <React.Fragment>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ display: "width:50%", marginRight: "1rem" }}>
                    <span className="dashboard-form-item">
                      <div className="profile-item__label"> Date: </div>
                      <Field
                        component="select"
                        name="date"
                        className="dashboard-form__input dashboard-form__select"
                        disabled
                        readOnly
                      >
                        <option value={date}>{date}</option>
                      </Field>
                    </span>
                    <span className="dashboard-form-item">
                      <div className="profile-item__label">
                        {" "}
                        Care Category:{" "}
                      </div>
                      <Field
                        component="input"
                        name="care_category_name"
                        className="dashboard-form__input"
                        readOnly
                      />
                    </span>
                  </div>
                  <div style={{ display: "width:50%" }}>
                    <span className="dashboard-form-item">
                      <div className="profile-item__label"> Time: </div>
                      <Field
                        component="input"
                        className="dashboard-form__input"
                        name="time"
                        readOnly
                      />
                    </span>
                    <span className="dashboard-form-item">
                      <div className="profile-item__label"> Medium: </div>
                      <Field
                        component="input"
                        name="appointment_medium_name"
                        className="dashboard-form__input"
                        readOnly
                      />
                    </span>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div style={{ display: "width:50%", marginRight: "1rem" }}>
                    <span className="dashboard-form-item">
                      <label className="profile-item__label" labelfor="date">
                        {" "}
                        Date:{" "}
                      </label>
                      <DateTime
                        closeOnSelect
                        viewMode="days"
                        timeFormat={false}
                        dateFormat="YYYY-MM-DD"
                        inputProps={{
                          required: true,
                          placeholder: "YYYY-MM-DD",
                          className: "dashboard-form__input",
                        }}
                        onChange={this.onDateSet}
                        isValidDate={(curr) =>
                          curr.dayOfYear() >= DateTime.moment().dayOfYear()
                        }
                        value={date}
                      />
                    </span>
                    <span className="dashboard-form-item">
                      <label className="profile-item__label" labelfor="time">
                        {" "}
                        Care Category:{" "}
                      </label>
                      <Field
                        component="select"
                        className="dashboard-form__input dashboard-form__select2"
                        name="care_category_id"
                        onChange={(e) =>
                          this.onCareCategorySelected(e.target.value)
                        }
                      >
                        <option>Please select a Care category</option>
                        {careCategories.map((care) => (
                          <option key={care.id} value={care.id}>
                            {care.name}
                          </option>
                        ))}
                      </Field>
                    </span>
                    {Number(selectedCareCategoryId) &&
                    Number(selectedCareCategoryId) === 10 ? ( //show specialist dropdown if Specialist consultation is selected
                      <span className="dashboard-form-item">
                        <label className="profile-item__label" labelfor="time">
                          {" "}
                          Select a Specialist:{" "}
                        </label>
                        <Field
                          component="select"
                          className="dashboard-form__input dashboard-form__select2"
                          name="care_specialist"
                        >
                          <option>Please select a Specialist</option>
                          {SPECIALISTS.map((s) => (
                            <option key={s.id} value={s.name}>
                              {s.name}
                            </option>
                          ))}
                        </Field>
                      </span>
                    ) : null}
                  </div>

                  <div style={{ display: "width:50%" }}>
                    <span className="dashboard-form-item">
                      <label
                        className="profile-item__label"
                        labelfor="appointment_medium"
                      >
                        {" "}
                        Medium:{" "}
                      </label>
                      <Field
                        component="select"
                        name="appointment_medium_id"
                        className="dashboard-form__input dashboard-form__select2"
                        onChange={(e) =>
                          this.onAppointmentMediumSelected(e.target.value)
                        }
                      >
                        {mode === AppointmentModal.MODES.create ? (
                          <option value="">
                            {" "}
                            {!selectedCareCategoryId
                              ? "Please select a care category first"
                              : "Please select a medium"}
                          </option>
                        ) : null}

                        {appointmentMediums.map((appointmentMedium) => (
                          <option
                            key={appointmentMedium.id}
                            value={appointmentMedium.id}
                          >
                            {appointmentMedium.name}
                          </option>
                        ))}
                      </Field>
                    </span>

                    {
                      <span className="dashboard-form-item">
                        <label className="profile-item__label" labelfor="time">
                          {" "}
                          Time:{" "}
                        </label>
                        <Field
                          component="select"
                          className="dashboard-form__input dashboard-form__select2"
                          name="time"
                          readOnly={!appointmentMediumId}
                        >
                          <option>
                            Please select a{" "}
                            {!appointmentMediumId
                              ? "appointment medium first"
                              : "time"}
                          </option>
                          {availableTimes.map((time) => (
                            <option key={time.value} value={time.value}>
                              {time.name}
                            </option>
                          ))}
                        </Field>
                      </span>
                    }
                  </div>
                </div>
                <span className="dashboard-form-item">
                  <label className="profile-item__label" htmlFor="">
                    {isFollowUp
                      ? "Reason for follow-up:"
                      : "Reason for booking the appointment:"}
                  </label>
                  <Field
                    component="input"
                    className="dashboard-form__input"
                    name="reason"
                  />
                </span>
              </React.Fragment>
            )}

            <div style={{ display: "flex", flexDirection: "flex-start" }}>
              {mode === AppointmentModal.MODES.view ? (
                <button
                  className="dashboard__primary-btn dashboard-form__btn"
                  type="submit"
                  onClick={this.reloadState}
                >
                  Edit Appointment
                </button>
              ) : (
                <button
                  className="dashboard__primary-btn dashboard-form__btn"
                  type="submit"
                >
                  Book Appointment
                </button>
              )}
              <button
                className="dashboard__secondary-btn dashboard-form__btn"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

AppointmentModal.MODES = {
  view: "view",
  edit: "edit",
  create: "create",
};

AppointmentForm = reduxForm({
  form: "appointmentForm",
})(AppointmentForm);

const selector = formValueSelector("appointmentForm");
AppointmentForm = connect((state) => {
  // can select values individually
  const date = selector(state, "date");
  const selectedCareCategoryId = selector(state, "care_category_id");
  const appointmentMediumId = selector(state, "appointment_medium_id");

  return {
    date,
    selectedCareCategoryId,
    appointmentMediumId,
  };
})(
  reduxForm({ enableReinitialize: true, form: "appointmentForm" })(
    AppointmentForm
  )
);

class AppointmentPatientDetails extends React.Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      patientInfo: null,
    };
  }

  componentDidMount() {
    const { patientId } = this.props;
    this.service
      .get(ENDPOINTS.users.user(patientId))
      .then((response) => {
        this.setState({
          patientInfo: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { patientInfo } = this.state;

    return (
      <div className="dashboard-card">
        <h2
          className="dashboard-card__title-text"
          style={{ marginBottom: "0rem" }}
        >
          Patient Information
        </h2>
        {!patientInfo ? (
          <div style={{ position: "relative", height: "10rem" }}>
            <LoadingScreen />
          </div>
        ) : (
          <React.Fragment>
            <div className="dashboard-form-item">
              <p className="dashboard-card__body-text">
                <i className="fa fa-user" style={{ marginRight: "1rem" }} />
                {`${patientInfo.first_name} ${patientInfo.last_name}`}
              </p>
            </div>
            <div style={{ margin: "0" }} className="dashboard-form-item">
              <p className="dashboard-card__body-text">
                <i className="fa fa-phone" style={{ marginRight: "1rem" }} />{" "}
                {patientInfo.phone_number}
              </p>
            </div>
            <div style={{ margin: "0" }} className="dashboard-form-item">
              <p className="dashboard-card__body-text">
                <i className="fa fa-envelope" style={{ marginRight: "1rem" }} />{" "}
                {patientInfo.email_address}
              </p>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default AppointmentModal;
