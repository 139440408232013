import React from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field, FieldArray, formValueSelector} from 'redux-form';
import FilterableSelect from "../inputs/FilterableSelect";

const CreateTestListForm = ({handleSubmit, tests, onTestsAdded, onSaveDiagnosticsAdded}) => {
  return (
    <form className="dashboard-form" onSubmit={handleSubmit}>
      {
        (!onSaveDiagnosticsAdded && onTestsAdded) &&
          <span className="dashboard-form-item">
            <label
              className="profile-item__label"
              htmlFor="">
              Create Invoice?
            </label>
            <Field
              component="select"
              name="create_invoice"
              className="dashboard-form__input dashboard-form__select"
              normalize={value => value === 'true'}>
              <option>Please select</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Field>
          </span>
      }

      <span className="dashboard-form-item" style={{margin:"2rem 0"}}>
        <FieldArray name="tests" component={renderTestFields} props={{tests}}/>
      </span>

      {
        (!onSaveDiagnosticsAdded && onTestsAdded) &&
          <span className="dashboard-form-item">
            <label className="profile-item__label" htmlFor="">
              Comment
            </label>
            <Field
              component="textarea"
              name="comment"
              className="dashboard-form__input"
              type="text"
              placeholder="Enter comment..."/>
          </span>
      }

      <div style={{display:"flex",flexDirection:"flex-start"}}>
        <button className="dashboard__primary-btn dashboard-form__btn">Save Tests</button>
      </div>
    </form>
  )
};

let TestEntryField = ({tests, test, index, onRemove, selectedTest}) => (
  <li>
    <div style={{display:"flex", alignItems:"center", margin:"2rem 0"}}>
      <span className="dashboard-form-item" style={{width:"auto"}}>
        <label className="profile-item__label" htmlFor="">
          Test #{index+1}
        </label>
        <Field
          name={`${test}.test`}
          className="filterable-select search-section embedded"
          component={FilterableSelect}
          props={
            {
              items: tests || [],
              placeholder:"Enter test name...",
              filterBy:["name"],
            }
          }/>
      </span>
      {selectedTest &&
      <React.Fragment>
        <span className="dashboard-form-item" style={{width:"auto"}}>
          <label className="profile-item__label" htmlFor="">
            Unit Price
          </label>
          <div
            style={{width:"auto"}}
            className="dashboard-form__input">
            {selectedTest.price || "0.00"}
          </div>
        </span>
        <span className="dashboard-form-item" style={{marginLeft: "1rem", width:"auto"}}>
          <label className="profile-item__label" htmlFor=""> Delete </label>
          <button className="dashboard__secondary-btn" onClick={onRemove}>
            <label className="profile-item__label" htmlFor="">
              <i className="fas fa-trash"/>
            </label>
          </button>
        </span>
      </React.Fragment>
      }
    </div>
  </li>
);

const selector = formValueSelector('createTestListForm');

TestEntryField = connect(
  (state, props) => ({
    selectedTest:  selector(state, `${props.test}.test`)
  })
)(TestEntryField);

const renderTestFields = ({fields, tests}) => (
  <ul>
    {fields.map((test, index) => (
      <TestEntryField
        key={index}
        test={test}
        index={index}
        tests={tests}
        onRemove={() => fields.remove(index)}/>
    ))}
    <li>
      <button
        className="dashboard__secondary-btn"
        type="button"
        onClick={() => fields.push({})}>
        {`${fields.length > 0 ? "Add Another Test" : "Click to add a Test"} `}
      </button>
    </li>
  </ul>
);

export default reduxForm({
  form: "createTestListForm"
})(CreateTestListForm);
