import { sessionService } from "redux-react-session";

import userService from "../services/userService";
import { toastBanner } from "../services/utilityService";
import { USER_ACTION_TYPES } from "./actionTypes";

export const login = () => {
  return {
    type: USER_ACTION_TYPES.LOGIN,
  };
};

export const loginSuccessful = (user, ref) => {
  return {
    type: USER_ACTION_TYPES.LOGIN_SUCCESS,
    data: { user: user, ref: ref },
  };
};

export const loginFailed = (error) => {
  return {
    type: USER_ACTION_TYPES.LOGIN_FAIL,
    data: {
      code: error.code,
      message: error.message,
    },
  };
};

export const forgotPassword = () => {
  return {
    type: USER_ACTION_TYPES.RESET_PASSWORD,
  };
};

export const recoverPasswordSuccessful = () => {
  return {
    type: USER_ACTION_TYPES.RESET_PASSWORD_SUCCESS,
  };
};

export const recoverPasswordFailed = (message) => {
  return {
    type: USER_ACTION_TYPES.RESET_PASSWORD_FAIL,
    data: message,
  };
};

export const changePassword = () => {
  return {
    type: USER_ACTION_TYPES.CHANGE_PASSWORD,
  };
};

export const changePasswordSuccessful = () => {
  return {
    type: USER_ACTION_TYPES.CHANGE_PASSWORD_SUCCESS,
  };
};

export const changePasswordFailed = (message) => {
  return {
    type: USER_ACTION_TYPES.CHANGE_PASSWORD_FAIL,
    data: message,
  };
};

//////////////////// THUNKS  //////////////////////////////

export const loginUser = (username, password, history) => {
  //try getting fcmToken if permission already granted
  return (dispatch) => {
    dispatch(login());
    userService.login(username, password).then(
      (user) => {
        saveSession(dispatch, user, history);
      },
      (err) => {
        dispatch(loginFailed(err));
      }
    );
  };
};

export const resetPassword = (values) => {
  //try getting fcmToken if permission already granted
  return (dispatch) => {
    dispatch(forgotPassword());
    userService.resetPassword(values).then(
      () => {
        dispatch(recoverPasswordSuccessful());
      },
      (err) => {
        const { data, statusText } = err.response || {
          status: -1,
          statusText: "Unknown Error",
          data: {},
        };
        dispatch(
          recoverPasswordFailed({ message: data.message || statusText })
        );
      }
    );
  };
};

export const setNewPassword = (values) => {
  return (dispatch) => {
    dispatch(changePassword());
    userService.setAccessToken(values.access_token);
    userService.changePassword(values).then(
      () => {
        dispatch(changePasswordSuccessful());
      },
      (err) => {
        const { data, statusText } = err.response || {
          status: -1,
          statusText: "Unknown Error",
          data: {},
        };
        dispatch(changePasswordFailed({ message: data.message || statusText }));
      }
    );
  };
};


export const saveSession = (dispatch, user, referrer) => {
  sessionService.saveSession(user.credentials.accessToken)
    .then(() => {
      sessionService.saveUser(user)
        .then(() => {
          dispatch(loginSuccessful(user, referrer));
        })
    });
};

export const logoutUser = (from) => {
  const destUrl = `/login`;
  return () => {
    sessionService.loadUser().then((user) => {
      userService.terminateAccessToken(user.credentials.accessToken)
      .then(() => {
        sessionService.deleteUser()
        .then(() => {
          sessionService.deleteSession()
            .then(() => {
             //auth is dummy route, it actually goes to /dashboard/
              window.location.replace(destUrl);
            });
        })
      })
      .catch((err) => {
        toastBanner("An error occured! please try again.", "error")
        //refers back to the previous url
        setTimeout(() => window.location.replace(document.referrer), 2000)
      });
    }).catch(userLoadErr => window.location.replace(destUrl));
};

}
export const clearUserProfile = () => {
  return {
    type: USER_ACTION_TYPES.CLEAR_PROFILE,
  };
};

export const updateLocalUser = (user) => {
  return sessionService.saveUser(user);
};
