import React from 'react';
import { connect } from 'react-redux';
import queryString from 'querystring';
import * as stageActions from '../actions/stages';
import {ROLES, STAGEDATA} from "../services/utilityService";
import { ApiService } from '../services/ApiService';
import MainContent from '../components/boxes/MainContent';
import WaitListComponent from "../components/WaitlistComponent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import LoadingScreen from "../components/LoadingScreen";
import StageDetailsModal from "../components/modals/StageDetailsModal";
import {UpdateStageComponentModal} from "../components/UpdateStageComponent";

const REFRESH_INTERVAL = 15000;

class OverviewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      stages:[],
      selectedStage:null,
      openStageDetails:false,
      showNextStageModal: false,
      selectedUser:null,
      selectedEncounter:null,
      activeBranch: ""
    }
  }

  componentDidMount() {
    const { dispatch, user } = this.props;
    const { location } = this.props;
    const pId = queryString.parse(location.search.substr(1)).p_id;
    const eId = queryString.parse(location.search.substr(1)).e_id;
    const findActiveBranch = user && user.branches ? user.branches.filter(branch => branch.id === user.active_branch_id) : null;
    this.setState({
      selectedUser: pId,
      selectedEncounter: eId,
      activeBranch: findActiveBranch ? findActiveBranch[0].name : ""
    });
    dispatch(stageActions.loadStages(user.credentials.accessToken, (pId !== null || eId !== null)));
    this.longPoll();
  }

  longPoll = (interval=REFRESH_INTERVAL) => {
    const { dispatch, user } = this.props;
    this.poll = setInterval(()=>{
      dispatch(stageActions.loadStages(user.credentials.accessToken));
    },interval);
  };

  componentDidUpdate(){
    const { selectedUser, selectedEncounter } = this.state;
    if(selectedUser && this.props.stages.length > 0){
      this.openUserStage(selectedUser);
    }else if(selectedEncounter && this.props.stages.length > 0){
      //show update stage modal
      this.updateStage(selectedEncounter);
    }
  }

  componentWillUnmount(){
    if(this.poll)
      clearInterval(this.poll);
  }

  openUserStage = (userId) => {
    const { stages } = this.props;
    const stage = stages.filter(stage => stage.patient.id === Number(userId))[0];
    if(stage){
      this.setState({
        selectedStage: stage,
        openStageDetails: true,
        selectedUser: null
      })
    }
  };

  updateStage = (encounterId) => {
    const stage = this.props.stages.filter(stage => stage.encounter_id === Number(encounterId))[0];
    if(stage){
      this.setState({
        selectedStage: stage,
        showNextStageModal: true,
        selectedEncounter: null
      })
    }
  };

  renderLoading = () => {
    return (
      <div style={{display:"flex", height: "5rem", width:"100%", justifyContent:"center", alignItems:"center", position:"relative", marginTop:"2rem"}}>
        <LoadingScreen />
      </div>
    )
  };

  toggleStageDetails = (stage) => {
    this.setState({
      selectedStage: stage,
      openStageDetails:!this.state.openStageDetails
    })
  };

  toggleNextStageModal = (stage) => {
    this.setState({
      selectedStage: stage,
      showNextStageModal: !this.state.showNextStageModal
    })
  };

  onStageUpdated = (newStage) => {
    const { dispatch, user, history } = this.props;
    const { selectedStage } = this.state;
    dispatch(stageActions.loadStages(user.credentials.accessToken));
    if(newStage.redirectUrl){
      history.push(newStage.redirectUrl);
    }else if(newStage.showNextStageModal){
      this.toggleStageDetails(selectedStage);
      this.toggleNextStageModal(newStage.stage);
    }
  };

  render() {
    const { openStageDetails, selectedStage, showNextStageModal, activeBranch } = this.state;
    const { isLoading, stages, user } = this.props;

    return (
      <MainContent>
        <PageHeader title={`Overview - ${activeBranch}`} />
        <PageBody>
          {openStageDetails &&
            <StageDetailsModal
              onClose={this.toggleStageDetails}
              stage={selectedStage}
              onStageUpdated={this.onStageUpdated}/>
          }
          {showNextStageModal &&
            <UpdateStageComponentModal
              onClose={this.toggleNextStageModal}
              userToken={user.credentials.accessToken}
              encounterId={selectedStage.encounter.id}
              currentStageId={selectedStage.current_stage_id}
              statusId={STAGEDATA.status.completed}/>
          }
          {stages &&
            <div style={{display:"flex", flexDirection:"column"}}>
              <div className="overview-wrapper">
                <WaitListComponent
                  stageList={stages.filter(stage => stage.current_stage_id === STAGEDATA.stages.yetToArrive)}
                  stageName={"Yet to arrive"}
                  onClickStage={this.toggleStageDetails}/>
                <WaitListComponent
                  stageList={stages.filter(stage => stage.current_stage_id === STAGEDATA.stages.frontDesk)}
                  stageName={ROLES.getRoleName(ROLES.front_desk_officer)}
                  onClickStage={this.toggleStageDetails}
                />
                <WaitListComponent
                  stageList={stages.filter(stage => stage.current_stage_id === STAGEDATA.stages.triage)}
                  stageName={ROLES.getRoleName(ROLES.triage_officer)}
                  onClickStage={this.toggleStageDetails}
                />
                <WaitListComponent
                  stageList={stages.filter(stage => stage.current_stage_id === STAGEDATA.stages.physician)}
                  stageName={"Physician"}
                  onClickStage={this.toggleStageDetails}
                />
                <WaitListComponent
                  stageList={stages.filter(stage => stage.current_stage_id === STAGEDATA.stages.pharmacist)}
                  stageName={ROLES.getRoleName(ROLES.pharmacist)}
                  onClickStage={this.toggleStageDetails}
                />

              </div>
              {isLoading && this.renderLoading()}
            </div>
          }
        </PageBody>
      </MainContent>
    )
  }

}

const mapStateToProps = (state) => {
  const { isLoading, stages, error } = state.stages || {};
  return { isLoading, stages, error }
};

export default connect(mapStateToProps)(OverviewContainer);