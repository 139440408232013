import React from 'react';
import TestForm from '../forms/TestForm';
// import axios from 'axios';
// import FilterableSelect from "../inputs/FilterableSelect";

class TestComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      status: null
    }
  };


  onSubmit = (values) => {
    // axios.post(`https://api.staging.reliancehmo.com/v1/enrollees/file`, JSONtoFormData(values), {
    //   headers: {
    //     "Content-Type": "multipart/form-data"
    //   }
    // }).then(response => {
    //   this.setState({
    //     status: {
    //       message: "Successful",
    //       data: response
    //     }
    //   })
    // }, e => {
    //   this.setState({
    //     status: {
    //       message: "error",
    //       data: e
    //     }
    //   })
    // })
  };


  render() {
    const {status} = this.state;

    if (status)
      return (
        <div className="container">
          <h1>{status.message}</h1>
        </div>
      );
    return (
      <div>
        <TestForm onSubmit={this.onSubmit}/>
      </div>
    )

    // return (
    //   <div className="container">
    //     <FilterableSelect/>
    //   </div>
    // )

  }

}

export default TestComponent

// function JSONtoFormData(json) {
//   const formData = new FormData();
//   for (let key in json) {
//     console.log("CREATE FORM DATA KEY|VALUE:", key, json[key]);
//     formData.append(key, json[key]);
//   }
//   return formData;
// }