import React, { Component } from 'react';
import queryString from 'querystring';
import numberFormatter from 'number-formatter';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import ProcedureModal from "../components/modals/ProcedureModal";
import MedicationModal from "../components/modals/MedicationModal";
import TestListModal from "../components/modals/TestListModal";
import ServicesModal from "../components/modals/ServicesModal";
import { ApiService, ENDPOINTS } from "../services/ApiService";
import { toastBanner } from "../services/utilityService";
import { PulseLoader } from 'react-spinners';

class NewInvoiceContainer extends Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    const { location } = props;
    const policyId = queryString.parse(location.search.substr(1)).policyId;
    this.state = {
      patient: {},
      policyId: policyId,
      invoiceItems: [],
      isSubmitting: false,
      showProcedureModal: false,
      showPrescriptionModal: false,
      showDiagnosticsModal: false,
      showServicesModal: false
    }
  }

  componentDidMount() {
    this.getPatient();
  }

  getTotalAmount() {
    const { invoiceItems } = this.state;
    const totalInvoiceCost = invoiceItems.reduce((accumulator, currInvoiceItem) => {
      return Number(accumulator) + (Number(currInvoiceItem.price) * Number(currInvoiceItem.quantity))
    }, 0)

    return totalInvoiceCost;
  }

  getPatient() {
    const { match : { params } } = this.props;

    this.service.get(ENDPOINTS.patients.patient(params.patientId))
      .then(response => {
        this.setState({
          patient: response.data
        })
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  }

  toggleShowProcedureModal = () => {
    this.setState({
      showProcedureModal: !this.state.showProcedureModal
    })
  };

  toggleShowPrescriptionModal = () => {
    this.setState({
      showPrescriptionModal: !this.state.showPrescriptionModal
    })
  };

  toggleShowDiagnosticsModal = () => {
    this.setState({
      showDiagnosticsModal: !this.state.showDiagnosticsModal
    })
  };

  toggleShowServicesModal = () => {
    this.setState({
      showServicesModal: !this.state.showServicesModal
    })
  };

  onSaveProcedureAdded = (procedure) => {
    this.setState({
      showProcedureModal: false,
      invoiceItems: [...this.state.invoiceItems, ...procedure.procedures]
    })
  };

  onSavePrescriptionAdded = (prescriptions) => {
    this.setState({
      showPrescriptionModal: false,
      invoiceItems: [...this.state.invoiceItems, ...prescriptions]
    })
  };

  onSaveDiagnosticsAdded = (diagnosticsAdded) => {
    this.setState({
      showDiagnosticsModal: false,
      invoiceItems: [...this.state.invoiceItems, ...diagnosticsAdded]
    });
  };

  onSaveServiceAdded = (serviceAdded) => {
    this.setState({
      showServicesModal: false,
      invoiceItems: [...this.state.invoiceItems, ...serviceAdded.services]
    });
  };

  onSaveInvoice() {
    const { match : { params }, history } = this.props;
    const { invoiceItems } = this.state;

    const items = invoiceItems.map((item) => ({
      id: item.id,
      quantity: item.quantity
    }))

    this.setState({
      isSubmitting: true
    })

    this.service.post(ENDPOINTS.encounters.invoices(params.encounterId), { items: items })
      .then(response => {
        this.setState({
          isSubmitting: false
        })
        history.push(`/payments/new?i_id=${response.data.invoice_id}`)
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  }

  render() {
    const { 
      patient,
      invoiceItems, 
      isSubmitting,
      showProcedureModal, 
      showPrescriptionModal, 
      showDiagnosticsModal,
      showServicesModal } = this.state;
    const { user } = this.props;
    const { match : { params } } = this.props;

    return (
      <MainContent>
        <PageHeader title="Add New Invoice"/>
        <PageBody>
          {
            showProcedureModal &&
              <ProcedureModal
                patient={patient}
                encounterId={params.encounterId}
                onClose={this.toggleShowProcedureModal}
                userToken={user.credentials.accessToken}
                onSaveProcedureAdded={this.onSaveProcedureAdded}
              />
          }
          {
            showPrescriptionModal &&
              <MedicationModal
                patient={patient}
                encounterId={params.encounterId}
                userToken={user.credentials.accessToken}
                onSavePrescriptionAdded={this.onSavePrescriptionAdded}
                onClose={this.toggleShowPrescriptionModal}
            />
          }
          {
            showDiagnosticsModal &&
              <TestListModal
                patient={patient}
                encounterId={params.encounterId}
                onClose={this.toggleShowDiagnosticsModal}
                userToken={user.credentials.accessToken}
                onSaveDiagnosticsAdded={this.onSaveDiagnosticsAdded}
              />
          }
          {
            showServicesModal &&
              <ServicesModal
                patient={patient}
                encounterId={params.encounterId}
                onClose={this.toggleShowServicesModal}
                userToken={user.credentials.accessToken}
                onSaveServiceAdded={this.onSaveServiceAdded}
              />
          }

          <div className="dashboard-card dashboard-invoice-btn-section">
            <button 
              className="dashboard__secondary-btn"
              onClick={this.toggleShowProcedureModal}
            >
              Add Procedures
            </button>
            <button 
              className="dashboard__secondary-btn"
              onClick={this.toggleShowPrescriptionModal}
            >
              Add Prescriptions
            </button>
            <button 
              className="dashboard__secondary-btn"
              onClick={this.toggleShowDiagnosticsModal}
            >
              Add Test(s)
            </button>
            <button 
              className="dashboard__secondary-btn"
              onClick={this.toggleShowServicesModal}
            >
              Add Services
            </button>
          </div>

          <div className="dashboard-card">
            <h3 className="dashboard-card__body-text">Invoice Items</h3>
            {
              invoiceItems.length === 0 ? 

              <div style={{fontSize: "2rem", color: "grey"}}>No Invoice Item</div>
              :
              <React.Fragment>
              <div className="dashboard-table-overflow">
                <table className="dashboard-table">
                  <thead>
                  <tr>
                    <th className="dashboard-table-item dashboard-table-item__header">Item #</th>
                    <th className="dashboard-table-item dashboard-table-item__header">Name</th>
                    <th className="dashboard-table-item dashboard-table-item__header">Unit Price (&#8358;)</th>
                    <th className="dashboard-table-item dashboard-table-item__header">Quantity</th>
                  </tr>
                  </thead>
                  <tbody>
                  {invoiceItems.map((invoice, index)=>(
                    <tr key={`${invoice.id}-${index}`}>
                      <td className="dashboard-table-item dashboard-table-item__body">{invoice.id}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{invoice.name}</td> 
                      <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter("#,###.00",invoice.price)}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{invoice.quantity}</td>
                    </tr>
                  ))
                  }
                  </tbody>
                </table>
              </div>
              <div style={{display:"flex", justifyContent:"flex-end", flexDirection:"column", marginTop:"2rem"}}>
                <p className="dashboard-card__body-text">Total Amount</p>
                <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem", fontSize:"2.5rem"}}>&#8358;{numberFormatter("#,###.00", this.getTotalAmount())}</h2>
              </div>
              </React.Fragment>
            }

            <button 
              onClick={() => { this.onSaveInvoice()}} 
              className="dashboard__primary-btn dashboard-form__btn"
              {...(invoiceItems.length === 0 ? {disabled: true} : {})}

            >
              {isSubmitting ? <PulseLoader color={'white'} size={7}/> : `Save Invoice`}
            </button>
          </div>
        </PageBody>
      </MainContent>
    );
  }
}

export default NewInvoiceContainer;