import React from "react";
import { reduxForm, Field } from "redux-form";
import { ApiService, ENDPOINTS } from "../../services/ApiService";
import {
  getAdminDashboardURL,
  toastBanner,
} from "../../services/utilityService";
import LoadingScreen from "../LoadingScreen";
import Modal from "../boxes/Modal";

//onClose, patient, userToken, onPaCodeAdded
const PaCodeModal = (props) => (
  <Modal title="Add PA Code" onClose={props.onClose}>
    <AddPaCodeForm {...props} />
  </Modal>
);

class AddPaCodeForm extends React.Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading: false,
      pacode: null,
    };
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  onSubmit = (values) => {
    let { pacode } = this.state;
    const { encounterId, onPaCodeAdded } = this.props;

    this.setLoading(true);
    this.service
      .post(ENDPOINTS.encounters.paCodes(encounterId), {
        pacodes: [{ ...values }],
      })
      .then((response) => {
        pacode = { ...values, id: response.data.user_id };
        this.setLoading(false);
        this.setState({
          pacode,
        });
        toastBanner(
          `${pacode.pacode} has successfully been added to the encounter`,
          "success"
        );
        if (onPaCodeAdded) onPaCodeAdded(pacode);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        toastBanner(error.message || "An error occured!");
      });
  };

  refresh = () => {
    const { reset } = this.props;
    if (reset) reset();
    this.setState({
      pacode: null,
    });
  };

  render() {
    const { handleSubmit, onClose, patient } = this.props;
    const { isLoading, pacode } = this.state;

    if (isLoading)
      return (
        <div style={{ position: "relative", height: "5rem" }}>
          <LoadingScreen />
        </div>
      );

    return (
      <form
        className="dashboard-form"
        onSubmit={handleSubmit(!pacode ? this.onSubmit : this.refresh)}
      >
        <div className="dashboard-form-item-wrapper">
          <div className="dashboard-input-wrap profile-input-wrap">
            <span className="dashboard-form-item">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label className="profile-item__label" htmlFor="">
                  PA Code
                </label>

                {patient.policies.some(
                  (policy) => policy.policy === "RelianceHMO"
                ) ? (
                  <button
                    className="dashboard__primary-btn dashboard-view__enrollee-profile-btn"
                    onClick={() => {
                      // Find the "RelianceHMO" policy in the array
                      const relianceHMOPolicy = patient.policies.find(
                        (policy) => policy.policy === "RelianceHMO"
                      );

                      // If found, open the dashboard URL with the code
                      if (relianceHMOPolicy) {
                        window.open(
                          `${getAdminDashboardURL()}?search=${
                            relianceHMOPolicy.code
                          }`,
                          "_blank"
                        );
                      } else {
                        // Handle the case when "RelianceHMO" policy is not found
                      }
                    }}
                  >
                    View enrollee profile
                  </button>
                ) : null}
              </div>
              <Field
                component="input"
                name="pacode"
                className="dashboard-form__input"
                type="text"
                placeholder="PA Code"
                readOnly={!!pacode}
              />
            </span>

            <span className="dashboard-form-item">
              <label className="profile-item__label" htmlFor="">
                Description
              </label>
              <Field
                component="textarea"
                name="description"
                className="dashboard-form__input"
                type="text"
                placeholder="Description"
                readOnly={!!pacode}
              />
            </span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "flex-start" }}>
          {pacode ? (
            <button
              className="dashboard__secondary-btn dashboard-form__btn"
              type="submit"
            >
              Add Another
            </button>
          ) : (
            <button
              className="dashboard__primary-btn dashboard-form__btn"
              type="submit"
              disabled={!!pacode}
            >
              Save
            </button>
          )}
          <button
            className="dashboard__secondary-btn dashboard-form__btn"
            type="button"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    );
  }
}

AddPaCodeForm = reduxForm({
  form: "addPaCodeForm",
})(AddPaCodeForm);

export default PaCodeModal;
