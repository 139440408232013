import React from 'react';
import { Route, Redirect, withRouter, NavLink } from 'react-router-dom';
import {ApiService, ENDPOINTS} from "../services/ApiService";
import { getAge, toastBanner } from "../services/utilityService";
import MainContent from "../components/boxes/MainContent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import PatientDetailRoutes from "../routes/PatientDetailRoutes";
import LoadingScreen from "../components/LoadingScreen";
import PageNotFound from "../components/PageNotFound";


const items = (patientId) => [
  {
    path:`/patients/${patientId}/basic`,
    title:"Basic"
  },
  {
    path:`/patients/${patientId}/medical`,
    title:"Medical"
  },
  {
    path:`/patients/${patientId}/vitals`,
    title:"Vitals"
  },
  {
    path:`/patients/${patientId}/appointments`,
    title:"Appointments"
  },
  {
    path:`/patients/${patientId}/encounter-notes`,
    title:"Encounters"
  },
  {
    path:`/patients/${patientId}/invoices`,
    title:"Invoices"
  },
  // {
  //   path:`/patients/${patientId}/payments`,
  //   title:"Payments"
  // },
  {
    path:`/patients/${patientId}/files`,
    title:"Files"
  }

];

class PatientDetailContainer extends React.Component {

  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      patient: null,
      error: null
    }
  }

  componentDidMount(){
    const { match : { params  } } = this.props;
    if(params.patientId){
      this.service.get(ENDPOINTS.patients.patient(params.patientId))
        .then(response => {
          this.setState({
            patient: response.data
          })
        })
        .catch(error => {
          this.setState({
            error: {
              message: error.message,
              code: error.code
            }
          })
          toastBanner(error.message || "An error occured!");
        });
    }
  }

  renderLoading = () => (
    <MainContent>
      <PageBody>
        <LoadingScreen/>
      </PageBody>
    </MainContent>
  );

  render(){
    const { match : { params: patientId }, user }  = this.props;
    const { patient, error } = this.state;

    if(!patientId) {
      return (<Redirect to='/patients'/>)
    }

    if(error && error.code === 404) {
      return (<Route component={PageNotFound}/>)
    }

    if(!patient) return this.renderLoading();
    return (
      <MainContent>
        
        <PageHeader title={`${patient.first_name} ${patient.last_name} (${getAge(patient.date_of_birth)}/${patient.gender}) / ${getPageTitle()}`}/>
        <PageBody>
          <NavBar patient={patient}/>
          <PatientDetailRoutes user={user} patient={patient}/>
        </PageBody>
      </MainContent>
    )
  }
}

const NavBar = ({patient}) => {
  return (
    <div className="dashboard-btn-section dashboard-tab" style={{flexWrap: "wrap"}}>
      {
        items(patient.id).map((item, i) => (
          <NavLink 
            to={item.path}
            activeClassName="dashboard-tab__button-active"
            key={i}
            className="dashboard-tab__button"
          >
            {item.title}
          </NavLink>
        ))
      }
    </div>
  )
};

const getPageTitle = () => {
  const paths = window.location.pathname.substr(1).split('/');
  if(paths.length === 3){
    return paths[2];
  }else if(paths.length === 4){
    return `${paths[2]} / ${paths[3]}`
  }else{
    return '';
  }
}

export default withRouter(PatientDetailContainer);