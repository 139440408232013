import React, { Component } from 'react';
import moment from "moment";
import {Link, withRouter} from 'react-router-dom';
import { toastBanner, PERMISSIONS, RELATIONSHIPS} from "../../services/utilityService";
import PatientRelationshipModal from "../modals/PatientRelationshipModal";
import Accordion from "../boxes/Accordion";
import PatientNotesComponent from "../PatientNotesComponent";
import RestrictedComponent from "../utils/RestrictedComponent";
import { getAge } from '../../services/utilityService';
import AddInsurerModal from "../modals/AddInsurerModal";
import DecisionModal from "../modals/DecisionModal";
import {ApiService, ENDPOINTS} from "../../services/ApiService";

class BasicInfo extends Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      relations: props.patient.relations,
      insurers: props.patient.policies,
      showAddRelationModal: false,
      showAddInsurerModal: false,
      showRemovePolicyDecisionModal: false,
      showCreatePatientDecisionModal: false,
      selectedPolicy:null,
      patientNotes: []
    }
  }

  componentDidMount() {
    this.displayNotes();
  }

  toggleAddRelationModal = () => {
    this.setState({
      showAddRelationModal: !this.state.showAddRelationModal
    });
  };

  toggleRemovePolicyDecisionModal = (selectedPolicy) => {
    this.setState({
      showRemovePolicyDecisionModal: !this.state.showRemovePolicyDecisionModal,
      selectedPolicy
    });
  };

  toggleAddInsurerModal = () => {
    this.setState({
      showAddInsurerModal: !this.state.showAddInsurerModal
    });
  };

  toggleCreatePatientDecisonModal = (relation) => {
    this.setState({
      showCreatePatientDecisionModal: !this.state.showCreatePatientDecisionModal,
      relation
    });
  }

  onRelationAdded = (relation) => {
    const { relations } = this.state;

    let updateRelations = relations;
    updateRelations.unshift(relation);

    this.setState({
      relations: updateRelations
    });
  };

  onInsurerAdded = (policy) => {
    this.toggleAddInsurerModal();
    this.setState({
      insurers: [...this.state.insurers, policy]
    });
  };

  scheduleAppointment = (type) => {
    const { history, patient } = this.props;
    history.push(`/appointments?p_id=${patient.id}&t=${type}`);
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    });
  };

  removePolicy = () => {
    const {patient} = this.props;
    const {selectedPolicy, insurers} = this.state;

    this.setLoading(true);
    this.toggleRemovePolicyDecisionModal();
    this.service.delete(ENDPOINTS.patients.insurers.insurer(patient.id, selectedPolicy.id))
      .then(() => {
        const newInsurerList = insurers.filter((insurer) => (
          insurer.id !== selectedPolicy.id
        ));

        this.setState({
          insurers: newInsurerList,
          toggleRemovePolicyDecisionModal: false 
        })
      })
      .catch(error => {
        this.setLoading(false);
        toastBanner(error.message || "An error occured!");
      });
  };

  displayNotes = () => {
    const { patient } = this.props;
    this.setLoading(true);
    this.service.get(ENDPOINTS.patients.notes(patient.id)).then((response) => {
      this.setState({
        isLoading:false,
        patientNotes: response.data
      })
    }).catch((error) => {
      this.setLoading(false);
      toastBanner(error.message || "An error occured!");
    });
  };

  onPatientsNoteAdded = (patientNote) => {
    const { patientNotes } = this.state;

    let updatedPatientNotes = patientNotes;
    updatedPatientNotes.unshift(patientNote);

    this.setState({
      patientNotes: updatedPatientNotes
    });
  }

  createAsNewPatient = () => {
    const { history } = this.props;
    const { relation } = this.state;

    history.push(`/patients/new?p_id=${relation.relation_id}`);
  }

  render() {
    const { patient, user } = this.props;
    const { showAddRelationModal, showAddInsurerModal, showCreatePatientDecisionModal, showRemovePolicyDecisionModal, relations, insurers, patientNotes } = this.state;
    
    return (
      <React.Fragment>
        {showAddRelationModal &&
          <PatientRelationshipModal
            onClose={this.toggleAddRelationModal}
            patient={patient}
            userToken={user.credentials.accessToken}
            onRelationAdded={this.onRelationAdded}/>
        }

        {showAddInsurerModal &&
          <AddInsurerModal
            onClose={this.toggleAddInsurerModal}
            patient={patient}
            userToken={user.credentials.accessToken}
            onInsurerAdded={this.onInsurerAdded}/>
        }

        {showCreatePatientDecisionModal &&
          <DecisionModal
            onClose={this.toggleCreatePatientDecisonModal}
            title="Add Relation as patient"
            open={showCreatePatientDecisionModal}
            content=""
            onYes={this.createAsNewPatient}
          />
        }

        <DecisionModal
          onClose={this.toggleRemovePolicyDecisionModal}
          title="Remove Policy"
          open={showRemovePolicyDecisionModal}
          content=""
          onYes={this.removePolicy}
        />
        <div style={{display:"flex", justifyContent:"flex-end"}}>
          <RestrictedComponent
            displayErrorMessage={false}
            condition={user.permissions.indexOf(PERMISSIONS.patient.update_patient) > -1}>
            <button className="dashboard__secondary-btn" style={{alignSelf:"flex-end", marginBottom:"1rem"}}>
              <Link to={`/patients/${patient.id}/edit`}>Edit Patient Info</Link>
            </button>
          </RestrictedComponent>
          <select
            className="dashboard__secondary-btn dashboard-form__select"
            style={{alignSelf:"flex-end", marginBottom:"1rem"}}
            onChange={(e) => this.scheduleAppointment(e.target.value)}>
            {/*<Link to={`/appointments?p_id=${patient.id}`}>Book Appointment</Link>*/}
            <option>Book Appointment</option>
            <option value="2">Walk-in Appointment</option>
            <option value="4">No Consultation</option>
          </select>
        </div>

        <div className="basic-info">
          <div style={{ width: "50%", marginRight: "1rem"}}>
            <div className="dashboard-card" style={{marginBottom: "2rem"}}>
              <h2 className="dashboard-card__title-text">General Information</h2>
                <div className="dashboard-table-overflow">
                  <table className="dashboard-table">
                    <tbody>
                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">RFC ID</th>
                        <td className="dashboard-table-item dashboard-table-item__body">{patient.rfc_id}</td>
                      </tr>
                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">First Name</th>
                        <td className="dashboard-table-item dashboard-table-item__body">{patient.first_name}</td>
                      </tr>

                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">Last Name</th>
                        <td className="dashboard-table-item dashboard-table-item__body">{patient.last_name}</td>
                      </tr>

                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">Date of Birth</th>
                        <td className="dashboard-table-item dashboard-table-item__body">{patient.date_of_birth}</td>
                      </tr>

                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">Age</th>
                        <td className="dashboard-table-item dashboard-table-item__body">{getAge(patient.date_of_birth)}</td>
                      </tr>

                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">Sex</th>
                        <td className="dashboard-table-item dashboard-table-item__body">{patient.gender === 'f' ? "Female": "Male"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>

            <div className="dashboard-card" style={{marginBottom: "2rem"}}>
              <h2 className="dashboard-card__title-text">Contact Information</h2>
                <div className="dashboard-table-overflow">
                  <table className="dashboard-table">
                    <tbody>
                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">Phone No.</th>
                        <td className="dashboard-table-item dashboard-table-item__body">{patient.phone_number}</td>
                      </tr>

                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">Email Address</th>
                        <td className="dashboard-table-item dashboard-table-item__body">{patient.email_address}</td>
                      </tr>

                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">Address</th>
                        <td className="dashboard-table-item dashboard-table-item__body">{patient.address}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>

            <div
              className={`dashboard-card`}
              style={{marginBottom:"2rem"}}>
              <h2 className="dashboard-card__title-text" style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                Insurance Information
                <button
                  style={{cursor:"pointer"}}
                  onClick={this.toggleAddInsurerModal}
                  className="dashboard__secondary-btn">
                  <i className="fa fa-plus-square-o"/>
                  Add Insurer
                </button>
              </h2>
              {insurers.length > 0 ? (
                <table className="dashboard-table">
                  <tbody>
                  <tr>
                    <th className="dashboard-table-item dashboard-table-item__header">Insurer</th>
                    <td className="dashboard-table-item dashboard-table-item__header">Policy Code</td>
                    <td className="dashboard-table-item dashboard-table-item__header"> </td>
                  </tr>
                  {insurers.map((i) => (
                    <tr key={i.code}>
                      <th className="dashboard-table-item dashboard-table-item__body">{i.policy}</th>
                      <td className="dashboard-table-item dashboard-table-item__body">{i.code}</td>
                      <td
                        onClick={() => this.toggleRemovePolicyDecisionModal(i)}
                        className="dashboard-table-item dashboard-table-item__body"
                        style={{cursor:"pointer"}}>
                        <i className="fa fa-times"/>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              ) : (
                <div style={{fontSize: "2rem", color: "grey"}}>No Policies</div>
              )}
            </div>

            <div className="dashboard-card">
              <h2 className="dashboard-card__title-text" style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                Relations
                <button
                  style={{cursor:"pointer"}}
                  onClick={this.toggleAddRelationModal}
                  className="dashboard__secondary-btn">
                  <i className="fa fa-plus-square-o"/>
                  Add Relation
                </button>
              </h2>
              {relations.length > 0 ? (
                <div className="dashboard-table-overflow">
                  <table className="dashboard-table">
                    <thead>
                    <tr>
                      <th className="dashboard-table-item dashboard-table-item__header">Name</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Phone Number</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Email Address</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Relationship</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      patient.relations.map((relation, index) => (
                        <tr key={`${relation.id}-${index}`} >
                          <td className="dashboard-table-item dashboard-table-item__body">
                            {
                              <p 
                                onClick={
                                  !relation.rfc_id ? 
                                    () => this.toggleCreatePatientDecisonModal(relation)
                                    :
                                    () => window.location.href = (`/patients/${relation.relation_id}/basic`)
                                } 
                                className="button_link"
                                style={{cursor: "pointer"}}>
                                  {`${relation.first_name} ${relation.last_name}`}
                              </p>  
                            }
                          </td>
                          <td className="dashboard-table-item dashboard-table-item__body">{relation.phone_number}</td>
                          <td className="dashboard-table-item dashboard-table-item__body">{relation.email_address}</td>
                          <td className="dashboard-table-item dashboard-table-item__body">{RELATIONSHIPS.getRelationShip(Number(relation.relationship_id))}</td>
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              ) :  (
                <div style={{fontSize: "2rem", color: "grey"}}>No Relations</div>
              )}
            </div>
          </div>

          <div style={{ width: "50%" }}>
            <Accordion
              title={`Patient Notes (${patientNotes.length})`}
              component={() => (
                <RestrictedComponent
                  displayErrorMessage={true}
                  errorComponent={() => (
                    <div className="dashboard-card">
                      <h2 className="dashboard-card__title-text">Patient Notes</h2>
                      <div style={{position:"relative", height:"10rem"}}>
                        <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                      </div>
                    </div>
                  )}
                  condition={user.permissions.indexOf(PERMISSIONS.patient.patient_note.read_patient_note) > -1}>
                  <div className="dashboard-card">
                    <div style={{ height: "24rem", overflow:"scroll"}}>
                      {patientNotes.length > 0 && patientNotes.map((note, index) => (
                        <div key={note.id} className="dashboard-card" style={{padding: "1rem",borderBottom: "2px solid #f8f8f8"}}>
                          <p className="dashboard-card__body-text" style={{fontSize:"1.3rem", marginBottom:"1rem"}}>{note.created_by.name} wrote:</p>

                          <h2 style={{wordBreak: "break-word", marginBottom:"1rem"}} className="dashboard-card__body-text">
                            {note.note}
                          </h2>
                          <p className="dashboard-card__body-text" style={{fontSize: "1rem", color:"#565656", marginBottom:"1rem"}}>{moment(note.created_at).calendar()}</p>
                        </div>
                      ))
                      }
                      {patientNotes.length === 0 && <div className="dashboard-card" style={{fontSize: "1.5rem", color: "grey", marginTop:"1rem"}}>Nothing to see here :)</div>}
                    </div>
                    <PatientNotesComponent
                      patient={patient}
                      user={user}
                      onPatientsNoteAdded={this.onPatientsNoteAdded}/>
                  </div>
                </RestrictedComponent>
              )}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(BasicInfo);