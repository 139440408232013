import {createTypes, async} from 'redux-action-types';
import * as ACTION_CATEGORIES from './actionCategories';


export const USER_ACTION_TYPES = createTypes(ACTION_CATEGORIES.USER + '_',
  async('LOGIN'),
  async('CHANGE_PASSWORD'),
  async('RESET_PASSWORD'),
  async('REGISTER'),
  async('GET_USER'),
  async('GET_PROFILE'),
  async('GET_BENEFITS'),
  'HAS_SETUP_PLANNER',
  'CLEAR_PROFILE'
);

export const STAGES_ACTION_TYPES = createTypes(ACTION_CATEGORIES.STAGES + '_',
  'INVALIDATE',
  async('GET_STAGES'),
  async('UPDATE_STAGE'),
);


export const DISPLAY_ACTION_TYPES = createTypes(ACTION_CATEGORIES.DISPLAY + '_',
  'TOGGLE_SIDEBAR',
  'HIDE_SIDEBAR'
);