import React from 'react';

class SearchInput extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      query:""
    }
  }

  onFilter = (e) => {
    const {query} = this.state;
    const {onSubmit} = this.props;
    if(e.key === 'Enter' && onSubmit){
      onSubmit(query);
    }
  };

  handleChange = (query) => {
    if(query.length === 0){
      this.clearFilter();
    }

    this.setState({
      query
    });
  };

  clearFilter = () => {
    const {clearFilter} = this.props;
    if(clearFilter){
      clearFilter();
      this.setState({
        query:""
      })
    }
  };

  render(){
    const {query} = this.state;
    const {placeholder, customStyle} = this.props;
    return (
      <div className="dashboard-btn-section search-section" style={customStyle}>
        <input
          className="dashboard-form__input"
          type="text"
          placeholder={placeholder}
          value={query}
          onChange={(e) => this.handleChange(e.target.value)}
          onKeyDown={this.onFilter}/>
        {query.length > 0 &&
          <span className="clear" onClick={this.clearFilter}>
            <i className="fa fa-times"/>
          </span>
        }
      </div>
    )
  }
}

export default SearchInput;
