import React from 'react';
import { connect } from 'react-redux';
import { arrayPush } from 'redux-form';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import LoadingScreen from '../LoadingScreen';
import Modal from '../boxes/Modal';
import AddServiceForm from "../forms/AddServiceForm";
import {TARIFF_TYPES} from "../../services/utilityService";

const ServicesModal = (props) => (
  <Modal title="Add Service" onClose={props.onClose}>
    <React.Fragment>
      <ServicesComponent {...props} />
    </React.Fragment>
  </Modal>
);

class ServicesComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading:false,
      servicesList: [],
      policyId: null,
    }
  }

  componentDidMount(){
    const { patient } = this.props;

    if(patient.policies.length === 0){
      this.setPolicyId("1");
    }
  }

  getServicesList = (policyId) => {
    const { dispatch } = this.props; 

    this.setLoading(true);
    this.service.get(ENDPOINTS.utilities.tariffs, {policy_id: policyId, type_id: TARIFF_TYPES.services})
      .then(response => {
        this.setState({
          isLoading: false,
          servicesList: response.data
        }, () => dispatch(arrayPush('addServiceForm', 'services', {quantity:1})))
      })
  };

  setPolicyId = (policyId) => {
    this.setState({
      policyId
    }, () => {
      this.getServicesList(policyId);
    })
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );

  onSaveService = (values) => {
    const { encounterId, onServiceAdded, onSaveServiceAdded } = this.props;

    const service = {
      create_invoice: values.create_invoice || false,
      services: values.services.map(m => ({
        id: m.service.id,
        summary: m.summary,
        name: m.service.name,
        price: m.service.price,
        quantity: m.quantity
      }))
    };

    this.setLoading(true);

    if(onSaveServiceAdded){
      onSaveServiceAdded({...service})
    }

    if(onServiceAdded){
      this.service.post(ENDPOINTS.encounters.services(encounterId), service)
        .then(response => {
          if(onServiceAdded)
          onServiceAdded({...service, id: response.data.id});
        });
    }      
  };

  render(){
    const {patient, onSaveServiceAdded, onServiceAdded} = this.props;
    const {policyId, servicesList, isLoading} = this.state;
    if(!patient || isLoading) return this.renderLoading();
    return (
      <div className="dashboard-form">
        {policyId ? (
          <AddServiceForm
            services={servicesList}
            onSubmit={this.onSaveService}
            onSaveServiceAdded={onSaveServiceAdded}
            onServiceAdded={onServiceAdded}
            />
        ) : (
          <div className="dashboard-form-item-wrapper">
            <div className="dashboard-input-wrap profile-input-wrap">
              <span className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  Select Patient Policy
                </label>
                <select
                  name="create_invoice"
                  className="dashboard-form__input dashboard-form__select"
                  onChange={(e) => this.setPolicyId(e.target.value)}>
                  <option>Select Policy</option>
                  <React.Fragment>
                    <option value="1">Walk In</option>
                    {patient.policies
                      .map(policy => (
                      <option key={policy.policy_id} value={policy.policy_id}>{policy.policy}</option>
                    ))}
                  </React.Fragment>
                </select>
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }
}

ServicesComponent = connect()(ServicesComponent);

export default ServicesModal;