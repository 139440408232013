import {STAGES_ACTION_TYPES} from "../actions/actionTypes";
import Immutable from 'seamless-immutable';

const initialState = Immutable({isLoading: false, stages: [], meta: {}, error: null});
export default function stagesReducer(state = initialState, action) {
  switch (action.type) {
    case STAGES_ACTION_TYPES.INVALIDATE:
      return state.merge({
        stages:[],
        error:null
      });
    case STAGES_ACTION_TYPES.GET_STAGES:
    case STAGES_ACTION_TYPES.UPDATE_STAGE:
      return state.merge({
        isLoading: true,
        error: null
      });
    case STAGES_ACTION_TYPES.GET_STAGES_SUCCESS:
      return state.merge({
        isLoading: false,
        stages: action.data
      });
    case STAGES_ACTION_TYPES.GET_STAGES_FAIL:
    case STAGES_ACTION_TYPES.UPDATE_STAGE_FAIL:
      return state.merge({
        isLoading: false,
        error: action.data
      });
    default:
      return state;
  }
}