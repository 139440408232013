import React, { Component } from 'react';
import queryString from 'querystring';
import moment from 'moment';
import numberFormatter from 'number-formatter';
import {ApiService, ENDPOINTS} from "../services/ApiService";
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import LoadingScreen from "../components/LoadingScreen";
import { toastBanner, INVOICE_STATUS, PERMISSIONS} from "../services/utilityService";
import NewPaymentForm from "../components/forms/NewPaymentForm";
import RestrictedComponent from "../components/utils/RestrictedComponent";

class NewPaymentContainer extends Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      invoice:null,
    };
  }

  componentDidMount(){
    const { location } = this.props;
    const iId = queryString.parse(location.search.substr(1)).i_id;
    if(iId){
      this.setLoading(true);
      this.service.get(ENDPOINTS.invoices.invoice(iId))
        .then(response => {
          this.setState({
            isLoading: false,
            invoice: response.data
          });
        })
        .catch((error) => {
          toastBanner(error.message || "An error occured!");
        })
    }
  }

  getTotalAmount() {
    const { invoice } = this.state;
    const totalInvoiceCost = invoice.items.reduce((accumulator, currInvoiceItem) => {
      return Number(accumulator) + (Number(currInvoiceItem.unit_price) * Number(currInvoiceItem.quantity))
    }, 0)

    return totalInvoiceCost;
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <MainContent>
      <PageBody>
        <LoadingScreen/>
      </PageBody>
    </MainContent>
  );

  onSubmit = (values) => {
    this.setLoading(true);
    this.service.post(ENDPOINTS.payments.base, values)
      .then(() => {
        this.setState({
          isLoading: false
        });
        toastBanner("Payment has successfully been logged", "success");
      })
      .catch(error => {
        this.setLoading(false);
        toastBanner(error.message || "An error occurred!");
      })
  };

  render() {
    const { isLoading, invoice } = this.state;

    if (isLoading || !invoice) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader title={`Payment for Invoice #${invoice.id}`}/>
        <PageBody>
          <div className="dashboard-card" style={{marginBottom: "2rem"}}>
            <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem"}}>Invoice for {invoice.patient.first_name} {invoice.patient.last_name}</h2>
            <p className="dashboard-card__body-text">Created {moment(invoice.created_at).format('MMMM Do YYYY, h:mm a')}</p>
            <br/>
            <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem"}}>Status</h2>
            <p className="dashboard-card__body-text">{INVOICE_STATUS.getStatus(Number(invoice.payment_status))}</p>
          </div>

          <div className="dashboard-card">
            <h2 className="dashboard-card__title-text" style={{marginBottom: "2rem"}}>Invoice Items</h2>
            <div className="dashboard-table-overflow">
              <table className="dashboard-table">
                <thead>
                <tr>
                  <th className="dashboard-table-item dashboard-table-item__header">Item #</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Name</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Unit Price (&#8358;)</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Quantity</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Total Price (&#8358;)</th>
                </tr>
                </thead>
                <tbody>
                {
                  invoice.items.map((item, index)=>(
                    <tr key={`${item.id}-${index}`} >
                      <td className="dashboard-table-item dashboard-table-item__body">{index+1}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{item.name}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter("#,###.00",item.unit_price)}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{item.quantity}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter("#,###.00",item.total_price)}</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
            <br/>
            <div style={{display:"flex", justifyContent:"flex-end", flexDirection:"column", marginTop:"2rem"}}>
              <p className="dashboard-card__body-text">Total Amount</p>
              <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem", fontSize:"2.5rem"}}>&#8358;{numberFormatter("#,###.00", this.getTotalAmount())}</h2>
            </div>
          </div>

          <div className="dashboard-card">
            <RestrictedComponent
              displayErrorMessage={true}
              errorComponent={() => (
                <p className="dashboard-card__body-text">You don't have permission to view this.</p>
              )}
              condition={this.props.user.permissions.indexOf(PERMISSIONS.payment.create_payment) > -1}>

              <NewPaymentForm
                onSubmit={this.onSubmit}
                patient={invoice.patient}
                invoiceId={invoice.id}
                amount={invoice.amount}/>
            </RestrictedComponent>
          </div>
        </PageBody>
      </MainContent>
    );
  }
}

export default NewPaymentContainer;