import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import numberFormatter from 'number-formatter';
import moment from 'moment';
import {ApiService, ENDPOINTS} from "../services/ApiService";
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import LoadingScreen from "../components/LoadingScreen";
import {PAYMENT_STATUS, TARIFF_TYPES} from "../services/utilityService";
import { toastBanner } from "../services/utilityService";

class ClaimDetailComponent extends Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      claim:null
    }
  }

  componentDidMount(){
    const { match: {params} } = this.props;
    const cId = params.claimId;
    if(cId){
      this.setLoading(true);
      this.service.get(ENDPOINTS.claims.claim(cId))
        .then(response => {
          this.setState({
            isLoading: false,
            claim: response.data
          })
        })
        .catch((error) => {
          toastBanner(error.message)
          this.setLoading(false);
        });
    }
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <MainContent>
      <PageBody>
        <LoadingScreen/>
      </PageBody>
    </MainContent>
  );

  updateClaimStatus = (newStatus) => {
    const { claim } = this.state;
    this.setLoading(true);
    this.service.put(ENDPOINTS.claims.claim(claim.id),{status_id:newStatus})
      .then(() => {
        let newClaim = claim;
        newClaim.status = newStatus;

        this.setState({
          isLoading:false,
          claim: newClaim
        });

        toastBanner("Claim status was successfully updated", "success");
      })
      .catch(error => {
        toastBanner(error.message);

        this.setState({
          isLoading:false
        })
      })
  };

  render() {
    const { isLoading, claim } = this.state;

    if (isLoading || !claim) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader title={`Claim ${claim.code}`}/>
        <PageBody>
          <div className="dashboard-card" style={{marginBottom: "2rem"}}>
            <h1 className="dashboard-card__title-text">
              {`Claim for Encounter #${claim.encounter_id || 0}`}
              <span
                className="no-print"
                style={{fontSize:"1rem", marginLeft:"1rem", pointer:"cursor"}} >
                <Link to={`/encounters/${claim.encounter_id}`} style={{color:"#808080"}}>
                  <i className="fa fa-external-link-alt" style={{color:"#808080"}}/>&nbsp;View encounter
                </Link>
              </span>
            </h1>
            <p className="dashboard-card__body-text" style={{marginBottom:"1rem"}}>
              {`Encounter Date: ${moment(claim.encounter_date).format("dddd, MMMM Do YYYY")}`}
            </p>

            <div className="profile-item__label" style={{marginBottom:"1rem"}}>Status:</div>
            <p className="dashboard-card__body-text">{PAYMENT_STATUS.getPaymentStatus(Number(claim.status))}</p>
          </div>

          <div className="dashboard-card">
            <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem"}}>Details:</h2>
            <table className="dashboard-table">
              <tbody>
              <tr>
                <th className="dashboard-table-item dashboard-table-item__header">Created By:</th>
                <td className="dashboard-table-item dashboard-table-item__body">{claim.created_by.name}</td>
              </tr>
              <tr>
                <th className="dashboard-table-item dashboard-table-item__header">Patient's Insurer: </th>
                <td className="dashboard-table-item dashboard-table-item__body">
                  {claim.insurer.name} [{claim.insurer.policy_code}]
                  <span
                    className="no-print"
                    style={{fontSize:"1rem", marginLeft:"1rem", pointer:"cursor"}} >
                    <Link to={`/patients/${claim.patient.id}`} style={{color:"#808080"}}>
                      <i className="fa fa-external-link-alt" style={{color:"#808080"}}/>&nbsp;View Patient
                    </Link>
                  </span>
                </td>
              </tr>
              <tr>
                <th className="dashboard-table-item dashboard-table-item__header">Diagnosis:</th>
                <td className="dashboard-table-item dashboard-table-item__body">{claim.diagnosis}</td>
              </tr>
              <tr>
                <th className="dashboard-table-item dashboard-table-item__header">PA Codes Issued:</th>
                <td className="dashboard-table-item dashboard-table-item__body">
                  {claim.pacodes.length > 0 ? claim.pacodes.map(c => c.pacode).join(",") : "None"}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className="dashboard-card">
            <h2 className="dashboard-card__title-text" style={{marginBottom: "2rem"}}>Claim Items</h2>
            <div className="dashboard-table-overflow">
              <table className="dashboard-table">
                <thead>
                <tr>
                  <th className="dashboard-table-item dashboard-table-item__header">#</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Name</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Quantity</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Price (&#8358;)</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Type</th>
                </tr>
                </thead>
                <tbody>
                {claim.invoices
                    .reduce((a,c) => a.concat(c.items),[])
                    .map((item, index)=>(
                    <tr key={`${item.id}-${index}`} >
                      <td className="dashboard-table-item dashboard-table-item__body">{index+1}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{item.name}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{item.quantity}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter("#,###.00",item.amount)}</td>
                      <td className="dashboard-table-item dashboard-table-item__body">{TARIFF_TYPES.getTariffType(Number(item.type))}</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
            <br/>
            <div style={{display:"flex", justifyContent:"flex-end", flexDirection:"column", marginTop:"2rem"}}>
              <p className="dashboard-card__body-text">Total Amount</p>
              <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem", fontSize:"2.5rem"}}>&#8358;{numberFormatter("#,###.00",claim.price)}</h2>
            </div>
          </div>

          <div className="dashboard-card">
            <select
              className="dashboard__secondary-btn dashboard-form__select"
              style={{alignSelf:"flex-end", marginBottom:"1rem"}}
              onChange={(e) => this.updateClaimStatus(e.target.value)}>
              {/*<Link to={`/appointments?p_id=${patient.id}`}>Book Appointment</Link>*/}
              <option>Update Claim Status</option>
              <option value="2">Approved</option>
              <option value="3">Declined</option>
              <option value="5">Paid</option>
            </select>
          </div>

        </PageBody>
      </MainContent>
    );
  }
}

export default withRouter(ClaimDetailComponent);