import React from 'react';
import {reduxForm, Field} from 'redux-form';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import LoadingScreen from '../LoadingScreen';
import  { toastBanner } from "../../services/utilityService";

class AddAllergyForm extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading: false
    }
  }

  onSubmit = (values) => {
    const { onAllergyCreated } = this.props;
    this.setLoading(true);
    this.service.post(ENDPOINTS.utilities.allergies, values)
      .then(response => {
        this.setLoading(false);
        if(onAllergyCreated){
          onAllergyCreated({...values, id: response.data.allergy_id});
        }
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );


  render(){
    const { isLoading } = this.state;
    const {onClose, handleSubmit} = this.props;

    if(isLoading) return this.renderLoading();

    return (
      <form className="dashboard-form" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="dashboard-form-item-wrapper">
          <div className="dashboard-input-wrap profile-input-wrap">
              <span className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  Allergen
                </label>
                <Field component="input" className="dashboard-form__input" type="text" placeholder="Enter allergen" name="name"/>
              </span>

            <span className="dashboard-form-item">
              <label
                className="profile-item__label"
                htmlFor="">
                Reaction
              </label>
              <Field component="input" className="dashboard-form__input" type="text" placeholder="Enter reaction" name="reaction"/>
            </span>

            {/*<span className="dashboard-form-item">*/}
              {/*<label*/}
                {/*className="profile-item__label"*/}
                {/*htmlFor="">*/}
                {/*Comment*/}
              {/*</label>*/}
              {/*<textarea className="dashboard-form__input" type="text" placeholder="Enter comment..."/>*/}
            {/*</span>*/}
            <div style={{display:"flex",flexDirection:"flex-start"}}>
              <button className="dashboard__primary-btn dashboard-form__btn" type="submit">Save</button>
              <button className="dashboard__secondary-btn dashboard-form__btn" type="button" onClick={onClose}>Cancel</button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

AddAllergyForm = reduxForm({
  form:"addAllergyForm",
  destroyOnUnmount: true
})(AddAllergyForm);

export default AddAllergyForm
