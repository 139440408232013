import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import numberFormatter from 'number-formatter';
import moment from 'moment';
import {ApiService, ENDPOINTS} from "../services/ApiService";
import { toastBanner, INVOICE_STATUS, PAYMENT_MEDIUM, printPage } from "../services/utilityService";
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import LoadingScreen from "../components/LoadingScreen";
import LOGO from '../img/logo.svg';
import {withUser} from "./utils/AuthHOC";
import DecisionModal from "./modals/DecisionModal";

class InvoiceDetailComponent extends Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading:false,
      invoice:null,
      showDeleteInvoiceItemModal: false,
      showDeleteInvoiceModal: false
    }
  }

  componentDidMount(){
    const { match: {params} } = this.props;
    const iId = params.invoiceId;
    if(iId){
      this.setLoading(true);
      this.service.get(ENDPOINTS.invoices.invoice(iId))
        .then(response => {
          this.setState({
            isLoading: false,
            invoice: response.data
          })
        })
        .catch((error) => {
          toastBanner(error.message || "An error occured!")
        });
    }
  }

  getTotalAmount() {
    const { invoice } = this.state;
    return invoice.items.reduce((accumulator, currInvoiceItem) => {
      return Number(accumulator) + (Number(currInvoiceItem.unit_price) * Number(currInvoiceItem.quantity))
    }, 0);
  }

  getAmountPaid() {
    const { invoice } = this.state;
    return invoice.payments.reduce((accumulator, currPaymentItem) => {
      return (accumulator) +Number(currPaymentItem.amount)
    }, 0);
  }

  deleteInvoice = () => {
    const { invoice } = this.state;

    this.setLoading(true);
    this.setState({
      showDeleteInvoiceModal: false,
    })

    this.service.delete(ENDPOINTS.invoices.invoice(invoice.id))
      .then((response) => {
        this.setLoading(false);
        toastBanner(`Invoice  ${invoice.id} for ${invoice.patient.first_name} ${invoice.patient.last_name} has been deleted!`, "success");
        setTimeout(() => window.location.href = `/invoices`, 3000);
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!")
        // setTimeout(() => this.setNotification(), 3000)
      })
  };

  deleteInvoiceItem = () => {
    const { invoice, selectedInvoiceItem } = this.state;

    this.service.delete(ENDPOINTS.invoices.invoiceItem(invoice.id, selectedInvoiceItem.id))
      .then((response) => {
        const newInvoiceItems = invoice.items.filter((item) => (
          item.id !== selectedInvoiceItem.id
        ));

        this.setState({
          invoice: {...invoice, items: newInvoiceItems},
          showDeleteInvoiceItemModal: false        
        });
        toastBanner("Invoice item deleted successfully", "success");
      })
      .catch((error) => {
        toastBanner(error.message || "An error occurred!");
      });
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  toggleDeleteInvoiceCheck = () => {
    this.setState({
      showDeleteInvoiceModal: !this.state.showDeleteInvoiceModal,
    });
  }

  toggleDeleteInvoiceItemCheck = (selectedInvoiceItem) => {
    this.setState({
      showDeleteInvoiceItemModal: !this.state.showDeleteInvoiceItemModal,
      selectedInvoiceItem
    });
  };

  renderLoading = () => (
    <MainContent>
      <PageBody>
        <LoadingScreen/>
      </PageBody>
    </MainContent>
  );

  render() {
    const { isLoading, invoice, showDeleteInvoiceModal, showDeleteInvoiceItemModal } = this.state;

    if (isLoading || !invoice) return this.renderLoading();

    return (
      <MainContent>
        <PageHeader title={`Invoice #${invoice.id}`}/>
        <PageBody>
          {
            showDeleteInvoiceModal &&
              <DecisionModal
                title="Delete Invoice" 
                onClose={this.toggleDeleteInvoiceCheck}
                open={showDeleteInvoiceModal}
                content=""
                onYes={this.deleteInvoice}
                />
          }
          {
            showDeleteInvoiceItemModal &&
              <DecisionModal
                title="Delete Invoice Item" 
                onClose={this.toggleDeleteInvoiceItemCheck}
                open={showDeleteInvoiceItemModal}
                content=""
                onYes={this.deleteInvoiceItem}
                />
          }

          <div className="print-header print-only">
            <img src={LOGO} className="logo" alt="Reliance Family Clinics"/>
            <div className="contact-details">
              <p><i className="fas fa-phone"/><span>017001584</span></p>
              <p><i className="fas fa-clock"/><span>Opening Hours: 8am - 8pm</span></p>
              <p><i className="fas fa-map-marker"/><span>12, Ayodeji Otegbola Street, Gbagada, Lagos</span></p>
            </div>
          </div>

          <div className="dashboard-card" style={{marginBottom: "2rem"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <h1 className="dashboard-card__title-text">
                {`Invoice for Encounter #${invoice.encounter.id}`}
                <Link to={`/encounters/${invoice.encounter.id}`} className="dashboard__secondary-btn-link no-print">
                  <i className="fa fa-external-link-alt" />&nbsp;View encounter
                </Link>
              </h1>
              { 
                Number(invoice.payment_status) !== INVOICE_STATUS.fullPayment &&
                <button onClick={this.toggleDeleteInvoiceCheck} className="dashboard__primary-btn-red">Delete Invoice</button>
              }
            </div>
            <p className="dashboard-card__body-text" style={{marginBottom:"1rem"}}>
              {`Appointment time: ${moment(invoice.encounter.appointment_time).format("dddd, MMMM Do YYYY")}`}
              </p>

            <div className="profile-item__label" style={{marginBottom:"1rem"}}>Description:</div>
            <p className="dashboard-card__body-text">{invoice.description || "Not available"}</p>
          </div>

          <div style={{display:"flex",justifyContent:"flex-start",marginBottom:"2rem"}}>
            <div className="dashboard-card" style={{flexGrow:"1"}}>
              <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem"}}>Invoice Details:</h2>
              <table className="dashboard-table">
                <tbody>
                  <tr>
                    <th className="dashboard-table-item dashboard-table-item__header">Issue Date:</th>
                    <td className="dashboard-table-item dashboard-table-item__body">{moment(invoice.created_at).format('MMMM Do YYYY, h:mm a')}</td>
                  </tr>
                  <tr>
                    <th className="dashboard-table-item dashboard-table-item__header">Issued to: </th>
                    <td className="dashboard-table-item dashboard-table-item__body">{invoice.issued_to}</td>
                  </tr>
                  <tr>
                    <th className="dashboard-table-item dashboard-table-item__header">Status:</th>
                    <td className="dashboard-table-item dashboard-table-item__body">{INVOICE_STATUS.getStatus(Number(invoice.payment_status))}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="dashboard-card" style={{marginLeft:"2rem", flexGrow:"1"}}>
              <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem"}}>Patient Information:</h2>
              <InvoicePatientDetails patientId={invoice.patient.id}/>
            </div>
          </div>

          <div className="dashboard-card">
            <h2 className="dashboard-card__title-text" style={{marginBottom: "2rem"}}>Invoice Items</h2>
            {
              invoice.items.length > 0 ?
                <div className="dashboard-table-overflow">
                  <table className="dashboard-table">
                    <thead>
                    <tr>
                      <th className="dashboard-table-item dashboard-table-item__header">#</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Name</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Unit Price (&#8358;)</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Quantity</th>
                      <th className="dashboard-table-item dashboard-table-item__header">Total Price (&#8358;)</th>
                      { 
                        Number(invoice.payment_status) !== INVOICE_STATUS.fullPayment &&
                          <th className="dashboard-table-item dashboard-table-item__header">Action</th>
                      }
                    </tr>
                    </thead>
                    <tbody>
                    {
                      invoice.items.map((item, index)=>(
                        <tr key={`${item.id}-${index}`} >
                          <td className="dashboard-table-item dashboard-table-item__body">{item.id}</td>
                          <td className="dashboard-table-item dashboard-table-item__body">{item.name}</td>
                          <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter("#,###.00",item.unit_price)}</td>
                          <td className="dashboard-table-item dashboard-table-item__body">{item.quantity}</td>
                          <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter("#,###.00",item.total_price)}</td>
                          {
                            Number(invoice.payment_status) !== INVOICE_STATUS.fullPayment &&
                              <td className="dashboard-table-item dashboard-table-item__body">
                                <button 
                                  className="dashboard__primary-btn-red" 
                                  onClick={() => this.toggleDeleteInvoiceItemCheck(item)}
                                >
                                  <i className="fa fa-trash" style={{color: "white"}}/>
                                </button>
                              </td>
                          }
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
                :
                <div className="dashboard-card" style={{fontSize: "2rem", color: "grey"}}>No Invoice Item found</div>
            }
           
            <br/>

            <div style={{display:"flex", justifyContent:"flex-end", flexDirection:"column", marginTop:"2rem"}}>
              <p className="dashboard-card__body-text">Total Amount</p>
              <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem", fontSize:"2.5rem"}}>&#8358;{numberFormatter("#,###.00", this.getTotalAmount())}</h2>
            </div>
          </div>
          <br/>

          <div className="dashboard-card">
          <h2 className="dashboard-card__title-text" style={{marginBottom: "2rem"}}>Payment Info</h2>
            {invoice.payments.length > 0 ?
              <div className="dashboard-table-overflow">
                <table className="dashboard-table">
                  <thead>
                  <tr>
                    <th className="dashboard-table-item dashboard-table-item__header">Amount &#8358;)</th>
                    <th className="dashboard-table-item dashboard-table-item__header">Payment Medium</th>
                    <th className="dashboard-table-item dashboard-table-item__header">Paid by</th>
                    <th className="dashboard-table-item dashboard-table-item__header">Created at</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    invoice.payments.map((payment, index)=>(
                      <tr key={`${index}_${payment.created_at}`}>
                        <td className="dashboard-table-item dashboard-table-item__body">{numberFormatter("#,###.00", payment.amount)}</td>
                        <td className="dashboard-table-item dashboard-table-item__body">{PAYMENT_MEDIUM.getPaymentMedium(Number(payment.payment_medium))}</td>
                        <td className="dashboard-table-item dashboard-table-item__body">{payment.paid_by}</td>
                        <td className="dashboard-table-item dashboard-table-item__body">{moment(payment.created_at).format("dddd, MMMM Do YYYY")}</td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
              :
              <div className="dashboard-card" style={{fontSize: "2rem", color: "grey"}}>No Payment Item found</div>
            }

            <br/>

            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "4rem"}}>
              <div style={{display:"flex", justifyContent:"flex-end", flexDirection:"column"}}>
                <p className="dashboard-card__body-text">Amount Paid</p>
                <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem", fontSize:"2.5rem"}}>&#8358;{numberFormatter("#,###.00", this.getAmountPaid())}</h2>
              </div>

              <div style={{display:"flex", justifyContent:"flex-end", flexDirection:"column", marginLeft: "6rem"}}>
                <p className="dashboard-card__body-text">Amount Left</p>
                <h2 className="dashboard-card__title-text" style={{marginBottom: "1rem", fontSize:"2.5rem", color: "#AB0404"}}>&#8358;{numberFormatter("#,###.00", invoice.balance ? invoice.balance: '0.0')}</h2>
              </div>
            </div>

            <br/>
            <br/>

            <div className="print-signature print-only">
              <div className="print-signature__column">
                <p className="line">_______________________________</p>
                <p>Patient's signature & date</p>
              </div>
              <div className="print-signature__column">
                <p className="line">_______________________________</p>
                <p>Admin signature & date</p>
              </div>
            </div>

          </div>

          <div className="dashboard-card">
            <div style={{display:"flex",flexDirection:"flex-start"}}>
              <button
                type="submit"
                className="dashboard__primary-btn dashboard-form__btn"
                disabled={Number(invoice.payment_status) === INVOICE_STATUS.fullPayment}>
                {Number(invoice.payment_status) === INVOICE_STATUS.fullPayment ? (
                  <>Add Payment</>
                ) : (
                  <Link to={`/payments/new?i_id=${invoice.id}`}>Add Payment</Link>
                )
                }
              </button>
              <button
                className="dashboard__secondary-btn dashboard-form__btn"
                onClick={printPage}>Print</button>
            </div>
          </div>
        </PageBody>
      </MainContent>
    );
  }
}

class InvoicePatientDetails extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading: false,
      patient: null
    }
  }

  componentDidMount(){
    const { patientId } = this.props;
    this.setLoading(true);
    if(patientId){
      this.service.get(ENDPOINTS.patients.patient(patientId))
        .then(response => {
          this.setState({
            isLoading: false,
            patient: response.data
          })
        })
        .catch((error) => {
          toastBanner(error.message || "An error occured!");
        });
    }
  }

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"10rem"}}>
      <LoadingScreen/>
    </div>
  );

  render(){
    const { patient, isLoading } = this.state;

    if(isLoading) return this.renderLoading();

    if(!patient) return (<div> </div>);

    return (
      <table className="dashboard-table">
        <tbody>
          <tr>
            <th className="dashboard-table-item dashboard-table-item__header">RFC ID:</th>
            <td className="dashboard-table-item dashboard-table-item__body">{patient.rfc_id}</td>
          </tr>
          <tr>
            <th className="dashboard-table-item dashboard-table-item__header">Name</th>
            <td className="dashboard-table-item dashboard-table-item__body">{patient.first_name} {patient.last_name}</td>
          </tr>
          <tr>
            <th className="dashboard-table-item dashboard-table-item__header">Phone Number:</th>
            <td className="dashboard-table-item dashboard-table-item__body">{patient.phone_number || "Not Available"}</td>
          </tr>
        </tbody>
      </table>
    )
  }
}

InvoicePatientDetails = withUser(InvoicePatientDetails);

export default withRouter(InvoiceDetailComponent);