import React from 'react';
import {reduxForm, Field, change} from 'redux-form';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import Modal from "../boxes/Modal";
import { toastBanner } from "../../services/utilityService";


const EditResultUploadModal = (props) => (
  <Modal
    title={`Edit Result for ${props.patient ? `${props.patient.first_name} ${props.patient.last_name}` : `Test # ${props.test.id}`} `}
    onClose={props.onClose}>
    <EditResultUploadComponent {...props} />
  </Modal>
);

class EditResultUploadComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading: false,
    };
  }


  componentDidMount() {
    const { result, dispatch } = this.props;
    dispatch(change("resultUploadForm","summary", result.summary));
    
  }


  onSubmit = (values) => {
    const {result, patient} = this.props;
    this.setState({
      isLoading:true
    });
    let requestUrl = `${ENDPOINTS.patients.results(patient.id)}/${result.id}`;
    
    if(requestUrl){
      this.service.patch(requestUrl, values)
        .then(response => {
          this.setState({
            isLoading: false,
          });
          toastBanner("You have successfully updated this result", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000)
        })
        .catch(error => {
          this.setState({
            isLoading: false,
          });
          toastBanner(error.message || "An error occurred!");
        })
    }else{
      toastBanner("Result not found");
    }
  };

  render(){
    const {isLoading} = this.state;
    const {handleSubmit, onClose, result} = this.props;

    return (
      <form className="dashboard-form" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="dashboard-form-item-wrapper">
          <div className="dashboard-input-wrap profile-input-wrap">
            <span className="dashboard-form-item">
              <h1 className="dashboard-card__title-text">{`Result ${result.id}`}</h1>
            </span>

            <span className="dashboard-form-item">
              <label
                className="profile-item__label"
                htmlFor="">
                Description
              </label>
              <Field component="textarea" className="dashboard-form__input" type="text" placeholder="Enter summary" name="summary"/>
            </span>
            <div style={{display:"flex",flexDirection:"flex-start"}}>
              <button
                disabled={isLoading}
                className="dashboard__primary-btn dashboard-form__btn"
                type="submit">{isLoading ? "Saving..." : "Save"}</button>
              <button className="dashboard__secondary-btn dashboard-form__btn" type="button" onClick={onClose}>Cancel</button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

EditResultUploadComponent = reduxForm({
  form: "resultUploadForm"
})(EditResultUploadComponent);

export default EditResultUploadModal;