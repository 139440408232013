import React from 'react';
import { connect } from 'react-redux';
import { arrayPush } from 'redux-form';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import LoadingScreen from '../LoadingScreen';
import Modal from '../boxes/Modal';
import CreatePrescriptionForm from "../forms/CreatePrescriptionForm";
import {TARIFF_TYPES, toastBanner} from "../../services/utilityService";

const CreatePrescriptionModal = (props) => (
  <Modal title="Add Prescription" onClose={props.onClose}>
    <React.Fragment>
      <CreatePrescriptionComponent {...props} />
    </React.Fragment>
  </Modal>
);

class CreatePrescriptionComponent extends React.Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      isLoading:false,
      drugList:[],
      policyId: null,
      prescription:null,
      // prescription:{
      //   create_invoice:true,
      //   medications:[],
      //   comment:""
      // }
    }
  }

  componentDidMount(){
    const { patient } = this.props;
    
    if(patient.policies.length === 0){
      this.setPolicyId("1");
    }
  }

  getDrugList = (policyId) => {
    const { dispatch } = this.props; 
    
    this.setLoading(true);
    this.service.get(ENDPOINTS.utilities.tariffs, {policy_id: policyId, type_id: TARIFF_TYPES.drugs})
      .then(response => {
        this.setState({
          isLoading: false,
          drugList: response.data
        }, () => dispatch(arrayPush('createPrescriptionForm', 'medications', {quantity:1})))
      })
      .catch(error => {
        this.setLoading(false);
        toastBanner(error.message || "An error occured!");
      });
  };

  setPolicyId = (policyId) => {
    this.setState({
      policyId
    }, () => {
      this.getDrugList(policyId);
    })
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  renderLoading = () => (
    <div style={{position:"relative", height:"5rem"}}>
      <LoadingScreen />
    </div>
  );

  onSavePrescription = (values) => {
    const {encounterId, onPrescriptionAdded, onSavePrescriptionAdded} = this.props;
    
    const prescription = {
      comment: values.comment || "",
      create_invoice: values.create_invoice || false,
      price: values.medications.reduce((a,c) =>  {
        return a + (Number(c.drug.price) * c.quantity)
      }, 0),
      medications: values.medications.map(m => ({
        id: m.drug.id,
        name: m.drug.name,
        unit_price: m.drug.price,
        quantity: m.quantity,
        total_price: Number(m.drug.price) * Number(m.quantity)
      }))
    };

    this.setLoading(true);

    if(onSavePrescriptionAdded){
      onSavePrescriptionAdded(prescription["medications"])
    }

    if(onPrescriptionAdded){
      this.service.post(ENDPOINTS.encounters.prescriptions.base(encounterId), prescription)
      .then(response => {
        if(onPrescriptionAdded)
          onPrescriptionAdded({...prescription, id: response.data.prescription_id});
          this.setLoading();
          toastBanner("Prescription Added", "success")
      })
      .catch((error) => {
        toastBanner(error.message || "An error occured!");
      });
    }
  };

  render(){
    const { patient, onPrescriptionAdded, onSavePrescriptionAdded } = this.props;
    const { policyId, drugList, isLoading } = this.state;

    if(!patient || isLoading) return this.renderLoading();

    return (
      <div className="dashboard-form">
        {policyId ? (
          <CreatePrescriptionForm
            drugs={drugList}
            onSubmit={this.onSavePrescription}
            onPrescriptionAdded={onPrescriptionAdded}
            onSavePrescriptionAdded={onSavePrescriptionAdded}/>
        ) : (
          <div className="dashboard-form-item-wrapper">
            <div className="dashboard-input-wrap profile-input-wrap">
              <span className="dashboard-form-item">
                <label
                  className="profile-item__label"
                  htmlFor="">
                  Select Patient Policy
                </label>
                <select
                  name="create_invoice"
                  className="dashboard-form__input dashboard-form__select"
                  onChange={(e) => this.setPolicyId(e.target.value)}>
                  <option>Select Policy</option>
                  <React.Fragment>
                    <option value="1">Walk In</option>
                    {patient.policies
                      .map(policy => (
                      <option key={policy.policy_id} value={policy.policy_id}>{policy.policy}</option>
                    ))}
                  </React.Fragment>
                </select>
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }
}

CreatePrescriptionComponent = connect()(CreatePrescriptionComponent);


export default CreatePrescriptionModal;