import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import DataTable from "../components/boxes/DataTable";
import { ENDPOINTS } from '../services/ApiService';
import { PERMISSIONS } from "../services/utilityService";
import { encountersColumnData } from "../services/tableColumnData";
import RestrictedComponent from "../components/utils/RestrictedComponent";

class EncounterContainer extends Component {
  showTableDetail = (id) => {
    return this.props.history.push(`/encounters/${id}`)
  }

  render() {
    const { user } = this.props;

    return (
      <MainContent>
        <PageHeader 
          title="Encounter" />
        <PageBody>
          <RestrictedComponent
            displayErrorMessage={true}
            errorComponent={() => (
              <div className="dashboard-card">
                <div style={{position:"relative", height:"10rem"}}>
                  <p className="dashboard-card__body-text">You don't have permission to view this.</p>
                </div>
              </div>
            )}
            condition={this.props.user.permissions.indexOf(PERMISSIONS.encounter.read_encounter) > -1}>

            <div className="dashboard-card dashboard-table-wrap">
              <DataTable
                dataSource={{
                  url:`${ENDPOINTS.encounters.base}`,
                  accessToken: user.credentials.accessToken
                }}
                tableColumnData={encountersColumnData}
                showTableDetail={this.showTableDetail}
              />
            </div>
          </RestrictedComponent>
        </PageBody>
      </MainContent>
    );
  }
}

export default withRouter(EncounterContainer);